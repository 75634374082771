import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Stack,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { createStyledIndicatorButton } from "../../../common/StyledComponents/SideBarComponents";
import { useDispatch } from "react-redux";
import {
  AccountLevelArkchatPermission,
  InviteType,
} from "../../../utils/constant";
import {
  billingFormData,
  getAllSubscribedUsers,
  getBillingAddress,
  updateSubscription,
} from "../../../appStore/actions/Subscription/action";
import { groupExternalMember } from "../../../utils/externalMemberGroping";
import { useTranslation } from "react-i18next";
import no_member_icon from "../../../assets/images/chats/no_member_icon.svg";
import { showToastMsg } from "../../../appStore/actions/global/action";
import { logWrapper } from "../../../utils/helper";
import useAppSelectors from "../../../customHooks/useAppSelectors";

const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    height: "100%",
    position: "relative",
  },
  header: {
    backgroundColor: "#F9F8F8",
    color: "#0000",
    padding: "7px 8px",
    mb: "1rem",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "4px",
    borderRadius: "2px",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
  internalExternalButton: {
    background: "white",
    border: "1px solid #183243",
    color: "#183243",
    borderRadius: "2px",
    fontSize: "0.75rem",
    textTransform: "none",
    "&:hover": {
      background: "white",
    },
  },

  activeButton: {
    "&:hover": {
      background: "#183243",
    },
    background: "#183243",
    borderRadius: "2px",
    color: "white",
    fontSize: "0.75rem",
    textTransform: "none",
    border: "1px solid #183243",
  },
  activeToggelButton: {
    backgroundColor: "#183243",
    color: "#fff",
    padding: "2px",
    fontSize: "0.75rem",
    borderRadius: "3px",
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
    textTransform: "none",
    borderColor: "#183243",
    ":hover": {
      backgroundColor: "#183243",
      color: "#fff",
      borderRadius: "3px",
      borderColor: "#183243",
    },
  },
  inactiveToggelButton: {
    backgroundColor: "#fff",
    color: "#183243",
    borderColor: "#183243",
    padding: "2px",
    fontSize: "0.75rem",
    borderRadius: "3px",
    borderTopLeftRadius: "0px",
    borderBottomLeftRadius: "0px",
    textTransform: "none",
    ":hover": {
      backgroundColor: "#fff",
      color: "#183243",
      borderRadius: "3px",
      borderColor: "#183243",
    },
  },
};
const ToggelManageMemberDrawer = ({
  setToggelManageMemberDrawerOpen,
  setBillingFormDrawerOpen,
  drawerType = "buy",
}) => {
  const NameBadge = createStyledIndicatorButton("#E3E1E1");
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const [activeButton, setActiveButton] = useState("internal");
  const [selectedInternalMemberData, setSelectedInternalMemberData] = useState([
    // { userId: string, planType: number }
  ]);
  const [selectedExternalMemberData, setSelectedExternalMemberData] = useState({
    // { companyName: [{ userId: string, planType: number }] }
  });
  const [externalMemberData, setExternalMemberData] = useState([]);
  const {
    currentUserData,
    accountLevelPeople,
    selectedPlanData,
    billingData,
    selectedManageSubscriptionData,
    allSubscribedUsers,
  } = useAppSelectors();
  const [availableYearlyLicenses, setAvailableYearlyLicenses] = useState(
    allSubscribedUsers?.tally?.balance || 0
  );

  const dispatch = useDispatch();

  const handleClickMemberTypeSwitch = (type) => {
    setActiveButton(type);
  };

  // Determine default plan type
  const getDefaultPlanType = (selectedPlanData) => {
    return selectedPlanData?.planType
      ? selectedPlanData?.planType
      : selectedPlanData?.interval === "month"
      ? 1 // Monthly
      : 2; // Yearly
  };

  const handleSelectInternalMember = (
    event,
    internalUserData,
    planType = null
  ) => {
    if (event.target.checked || event.target.innerText === appContent?.add) {
      const defaultPlanType = getDefaultPlanType(
        selectedManageSubscriptionData
      );

      // Determine the plan type
      const finalPlanType = planType ? planType : defaultPlanType;

      // Check if trying to add a yearly license
      if (finalPlanType === 2 && availableYearlyLicenses <= 0) {
        dispatch(showToastMsg("No more yearly licenses available"));
        return;
      }

      const newMemberData = {
        user_id: internalUserData?.userId,
        plan_type: finalPlanType,
      };

      // Update available yearly licenses if adding a yearly license
      const updatedAvailableLicenses =
        finalPlanType === 2
          ? availableYearlyLicenses - 1
          : availableYearlyLicenses;

      setAvailableYearlyLicenses(updatedAvailableLicenses);

      setSelectedInternalMemberData([
        ...selectedInternalMemberData,
        newMemberData,
      ]);
    } else {
      // When removing a member, check if it was a yearly license and adjust available licenses
      const removedMember = selectedInternalMemberData.find(
        (member) => member.user_id === internalUserData?.userId
      );

      const updateInternUserData = selectedInternalMemberData?.filter(
        (member) => member.user_id !== internalUserData?.userId
      );

      // Restore yearly license if the removed member was on a yearly plan
      const updatedAvailableLicenses =
        removedMember?.plan_type === 2
          ? availableYearlyLicenses + 1
          : availableYearlyLicenses;

      setAvailableYearlyLicenses(updatedAvailableLicenses);
      setSelectedInternalMemberData(updateInternUserData);
    }
  };

  const handleSelectExternalPerson = (event, selectedPerson, companyName) => {
    if (event.target.checked) {
      // Default plan type when selecting
      const defaultPlanType = getDefaultPlanType(
        selectedManageSubscriptionData
      );

      const newMemberData = {
        user_id: selectedPerson?.userId,
        plan_type: defaultPlanType,
      };

      if (selectedExternalMemberData[companyName]?.length > 0) {
        const updatedCompanyMembers = [
          ...selectedExternalMemberData[companyName],
          newMemberData,
        ];
        setSelectedExternalMemberData({
          ...selectedExternalMemberData,
          [companyName]: updatedCompanyMembers,
        });
      } else {
        setSelectedExternalMemberData({
          ...selectedExternalMemberData,
          [companyName]: [newMemberData],
        });
      }
    } else {
      // Remove the user when unchecked
      const updatedCompanyMembers = selectedExternalMemberData[
        companyName
      ]?.filter((member) => member.user_id !== selectedPerson?.userId);

      setSelectedExternalMemberData({
        ...selectedExternalMemberData,
        [companyName]: updatedCompanyMembers || [],
      });
    }
  };

  // Modify toggle functions to check yearly license availability
  const toggleInternalMemberPlanType = (user_id) => {
    const currentMember = selectedInternalMemberData.find(
      (member) => member.user_id === user_id
    );
    // Check if switching to yearly and no licenses available
    if (currentMember.plan_type === 1 && availableYearlyLicenses <= 0) {
      dispatch(showToastMsg("No more yearly licenses available"));
      return;
    }

    const updatedInternalMemberData = selectedInternalMemberData.map((member) =>
      member.user_id === user_id
        ? {
            ...member,
            plan_type: member.plan_type === 1 ? 2 : 1,
          }
        : member
    );

    // Update available yearly licenses
    const updatedAvailableLicenses =
      currentMember.plan_type === 1
        ? availableYearlyLicenses - 1
        : availableYearlyLicenses + 1;

    setAvailableYearlyLicenses(updatedAvailableLicenses);
    setSelectedInternalMemberData(updatedInternalMemberData);
  };

  const toggleExternalMemberPlanType = (companyName, user_id) => {
    const updatedExternalMemberData = { ...selectedExternalMemberData };

    const currentMember = updatedExternalMemberData[companyName].find(
      (member) => member.user_id === user_id
    );

    // Check if switching to yearly and no licenses available
    if (currentMember.plan_type === 1 && availableYearlyLicenses <= 0) {
      dispatch(showToastMsg("No more yearly licenses available"));
      return;
    }

    const companyMembers = updatedExternalMemberData[companyName].map(
      (member) =>
        member.user_id === user_id
          ? {
              ...member,
              plan_type: member.plan_type === 1 ? 2 : 1,
            }
          : member
    );

    // Update available yearly licenses
    const updatedAvailableLicenses =
      currentMember.plan_type === 1
        ? availableYearlyLicenses - 1
        : availableYearlyLicenses + 1;

    setAvailableYearlyLicenses(updatedAvailableLicenses);

    updatedExternalMemberData[companyName] = companyMembers;
    setSelectedExternalMemberData(updatedExternalMemberData);
  };

  // const handleSelectInternalMember = (
  //   event,
  //   internalUserData,
  //   planType = null
  // ) => {
  //   if (event.target.checked || event.target.innerText === appContent?.add) {
  //     const defaultPlanType = getDefaultPlanType(
  //       selectedManageSubscriptionData
  //     );

  //     const newMemberData = {
  //       user_id: internalUserData?.userId,
  //       plan_type: planType ? planType : defaultPlanType,
  //     };

  //     setSelectedInternalMemberData([
  //       ...selectedInternalMemberData,
  //       newMemberData,
  //     ]);
  //   } else {
  //     const updateInternUserData = selectedInternalMemberData?.filter(
  //       (member) => member.user_id !== internalUserData?.userId
  //     );
  //     setSelectedInternalMemberData(updateInternUserData);
  //   }
  // };

  // Toggle plan type for external members

  // const toggleExternalMemberPlanType = (companyName, user_id) => {
  //   const updatedExternalMemberData = { ...selectedExternalMemberData };

  //   const companyMembers = updatedExternalMemberData[companyName].map(
  //     (member) =>
  //       member.user_id === user_id
  //         ? { ...member, plan_type: member.plan_type === 1 ? 2 : 1 }
  //         : member
  //   );

  //   updatedExternalMemberData[companyName] = companyMembers;

  //   setSelectedExternalMemberData(updatedExternalMemberData);
  // };

  // // Toggle plan type for internal members
  // const toggleInternalMemberPlanType = (user_id) => {
  //   const updatedInternalMemberData = selectedInternalMemberData.map((member) =>
  //     member.user_id === user_id
  //       ? { ...member, plan_type: member.plan_type === 1 ? 2 : 1 }
  //       : member
  //   );

  //   setSelectedInternalMemberData(updatedInternalMemberData);
  // };

  useEffect(() => {
    if (accountLevelPeople && allSubscribedUsers?.members) {
      // Modify internal user selection
      const selectedInternalUser = accountLevelPeople?.internalMember
        ?.filter((item) =>
          allSubscribedUsers.members.some(
            (member) => member.userId === item?.userId
          )
        )
        ?.map((item) => {
          const memberData = allSubscribedUsers.members.find(
            (member) => member.userId === item?.userId
          );
          return {
            user_id: item?.userId,
            plan_type: memberData.planType,
          };
        });

      setSelectedInternalMemberData([
        ...selectedInternalMemberData,
        ...selectedInternalUser,
      ]);

      // Modify external user selection
      if (accountLevelPeople?.externalMember?.length > 0) {
        const groupedExternalData = groupExternalMember(
          accountLevelPeople?.externalMember,
          false,
          appContent?.individual
        );

        const selectedExternalUser = accountLevelPeople?.externalMember?.filter(
          (item) =>
            allSubscribedUsers.members.some(
              (member) => member.userId === item?.userId
            )
        );

        if (selectedExternalUser?.length > 0) {
          const preSelectedExternalMember = groupExternalMember(
            selectedExternalUser,
            false,
            appContent?.individual
          );

          let externalMember = {};
          preSelectedExternalMember?.forEach((company) => {
            let userIds = Object.values(company)[0]?.map((item) => {
              const memberData = allSubscribedUsers.members.find(
                (member) => member.userId === item?.userId
              );
              return {
                user_id: item?.userId,
                plan_type: memberData.planType,
              };
            });
            externalMember = {
              ...externalMember,
              [Object.keys(company)[0]]: userIds,
            };
          });

          setSelectedExternalMemberData(externalMember);
        }
        setExternalMemberData(groupedExternalData);
      }
    }
    setAvailableYearlyLicenses(allSubscribedUsers?.tally?.balance || 0);
    dispatch(getBillingAddress());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountLevelPeople, allSubscribedUsers]);

  // Handle save/next
  const handleClickNext = () => {
    let usersArr = [];

    // Collect internal members
    usersArr = selectedInternalMemberData.map((member) => ({
      user_id: member.user_id,
      plan_type: member.plan_type,
    }));

    // Collect external members
    for (let key in selectedExternalMemberData) {
      usersArr = [
        ...usersArr,
        ...selectedExternalMemberData[key].map((member) => ({
          user_id: member.user_id,
          plan_type: member.plan_type,
        })),
      ];
    }
    if (drawerType === "buy") {
      const reqBody = {
        ...billingData,
        users: usersArr,
        price: selectedPlanData?.price,
        plan_id: selectedPlanData?.prodId,
        currency: selectedPlanData?.currency,
        gateway: selectedPlanData?.gateway,
      };

      dispatch(billingFormData(reqBody));

      setBillingFormDrawerOpen(true);
    } else {
      const reqBody = {
        users: usersArr,
      };
      dispatch(updateSubscription(reqBody)).then((res) => {
        dispatch(showToastMsg(res?.message || "Something went wrong !"));
        dispatch(getAllSubscribedUsers());
        setToggelManageMemberDrawerOpen(false);
      });
    }
  };
  return (
    <Box sx={classes.drawerBody} overflow="hidden">
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={classes.header}
      >
        <Typography sx={classes.iconDiv}>
          <CloseIcon
            sx={classes.iconn}
            onClick={() => setToggelManageMemberDrawerOpen(false)}
          />
        </Typography>
        <Typography color={"black"}>Add/remove contacts</Typography>
      </Stack>

      <Box width={"95%"} ml={"auto"}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          mt={"0.4rem"}
          mb={"0.4rem"}
          // border={"1px solid"}
        >
          <Stack
            direction="row"
            justifyContent="left"
            alignItems="center"
            spacing={1}
          >
            <Button
              sx={
                activeButton === "internal"
                  ? classes.activeButton
                  : classes.internalExternalButton
              }
              variant="contained"
              onClick={() => handleClickMemberTypeSwitch("internal")}
            >
              {appContent?.internalContacts}
            </Button>
            <Button
              sx={
                activeButton === "external"
                  ? classes.activeButton
                  : classes.internalExternalButton
              }
              variant="contained"
              onClick={() => handleClickMemberTypeSwitch("external")}
            >
              {appContent?.externalContacts}
            </Button>
          </Stack>
        </Stack>
        <Box width={"80%"} mt={"0.8rem"}>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography variant="caption">
              Yearly subscription licences :
            </Typography>
            <Typography>{allSubscribedUsers?.tally?.total}</Typography>
          </Box>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography variant="caption">Utilised:</Typography>
            <Typography>
              {allSubscribedUsers?.tally?.total - availableYearlyLicenses}
            </Typography>
          </Box>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography variant="caption">Balance:</Typography>
            <Typography>{availableYearlyLicenses}</Typography>
          </Box>
        </Box>
        <Box
          width={"90%"}
          overflow={"scroll"}
          height={"calc(100vh - 16rem)"}
          sx={{
            "&::-webkit-scrollbar": {
              width: "1px",
            },
            "&::-webkit-scrollbar-track": {
              background: "#F9F8F8",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "grey",
            },
            "@media (max-width: 1194px)": {
              height: "calc(100vh - 20rem)",
            },
          }}
        >
          {activeButton === "internal" && (
            <Box>
              {accountLevelPeople?.internalMember?.map((item) => (
                <Stack
                  width={"100%"}
                  mb={"0.87rem"}
                  padding={"0px 4px 0px 2px"}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  key={item?.userId + "internalInvitegrpmember"}
                >
                  <Box width={"62%"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 30,
                              color: "#183243",
                            },
                          }}
                          checked={selectedInternalMemberData.some(
                            (member) => member.user_id === item?.userId
                          )}
                          onChange={(event) =>
                            handleSelectInternalMember(event, item)
                          }
                          disabled={
                            (item?.inviteType ===
                              InviteType?.internalOwnerInviteType &&
                              (item?.permission ===
                                AccountLevelArkchatPermission?.ADMIN ||
                                currentUserData?.accountOwner)) ||
                            (item?.inviteType === InviteType?.internal &&
                              item?.permission ===
                                AccountLevelArkchatPermission?.ADMIN)
                          }
                          icon={
                            <NameBadge
                              sx={{ color: "#183243" }}
                              size="small"
                              variant="contained"
                            >
                              <Typography
                                color={"#183243"}
                                fontSize={"0.75rem"}
                              >
                                {item?.userInfo?.firstName &&
                                  item?.userInfo?.firstName[0]}
                              </Typography>
                            </NameBadge>
                          }
                        />
                      }
                      label={
                        <Box>
                          <Typography
                            title={`${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`}
                          >
                            {`${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`
                              .length > 18
                              ? `${`${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`.slice(
                                  0,
                                  18
                                )}...`
                              : `${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`}
                            <>
                              {item?.inviteType ===
                                InviteType?.internalOwnerInviteType &&
                                (item?.permission ===
                                  AccountLevelArkchatPermission?.ADMIN ||
                                  currentUserData?.accountOwner) &&
                                ", Owner"}
                              {item?.inviteType === InviteType?.internal &&
                                item?.permission ===
                                  AccountLevelArkchatPermission?.ADMIN &&
                                ", Admin"}
                            </>
                          </Typography>
                          {/* <Typography variant="caption">Admin</Typography> */}
                        </Box>
                      }
                    />
                  </Box>
                  <Box
                    width={"35%"}
                    display={"flex"}
                    alignItems={"center"}
                    gap={"0.5rem"}
                  >
                    {/*cross*/}
                    <Box display={"flex"}>
                      <Button
                        variant="outlined"
                        sx={{
                          ...(selectedInternalMemberData.find(
                            (m) => m.user_id === item?.userId
                          )?.plan_type === 2
                            ? classes.activeToggelButton
                            : classes.inactiveToggelButton),
                        }}
                        onClick={() =>
                          selectedInternalMemberData.some(
                            (m) => m.user_id === item?.userId
                          )
                            ? toggleInternalMemberPlanType(item?.userId)
                            : handleSelectInternalMember(
                                { target: { checked: true } },
                                item,
                                2
                              )
                        }
                      >
                        Yearly
                      </Button>
                      <Button
                        variant="outlined"
                        sx={{
                          ...(selectedInternalMemberData.find(
                            (m) => m.user_id === item?.userId
                          )?.plan_type === 1
                            ? classes.activeToggelButton
                            : classes.inactiveToggelButton),
                        }}
                        onClick={() =>
                          selectedInternalMemberData.some(
                            (m) => m.user_id === item?.userId
                          )
                            ? toggleInternalMemberPlanType(item?.userId)
                            : handleSelectInternalMember(
                                { target: { checked: true } },
                                item,
                                1
                              )
                        }
                      >
                        Monthly
                      </Button>
                    </Box>
                  </Box>
                </Stack>
              ))}
            </Box>
          )}

          {activeButton === "external" && (
            <Box>
              {externalMemberData.map((company, index) => (
                <Box mb={"0.87rem"} padding={"0px 4px 0px 2px"}>
                  <FormControlLabel
                    sx={{
                      ".MuiFormControlLabel-label": {
                        fontWeight: 500,
                        color: "#000000",
                      },
                    }}
                    control={
                      <Checkbox
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 30,
                            color: "#183243",
                          },
                        }}
                        checked={
                          Object.values(company)[0]?.length ===
                          selectedExternalMemberData[Object.keys(company)[0]]
                            ?.length
                        }
                        onChange={(event) => event.preventDefault}
                        icon={
                          <NameBadge
                            sx={{ color: "#183243" }}
                            size="small"
                            variant="contained"
                          >
                            <Typography
                              color={"#183243"}
                              fontSize={"0.75rem"}
                              fontWeight={600}
                            >
                              {Object.keys(company)[0] &&
                                Object.keys(company)[0][0]}
                            </Typography>
                          </NameBadge>
                        }
                      />
                    }
                    label={
                      <Typography
                        variant="h6"
                        fontSize={"1rem"}
                        fontWeight={600}
                      >
                        {Object.keys(company)[0]}
                      </Typography>
                    }
                  />
                  {Object.values(company)[0]?.map((item, i) => (
                    <Stack
                      mb={"0.4rem"}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={1}
                      key={item?.userId + "externalInvitegrpmember"}
                    >
                      <Box width={"62%"} padding={"0rem 0rem 0rem 1.2rem"}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: 25,
                                  color: "#183243",
                                },
                              }}
                              checked={
                                selectedExternalMemberData[
                                  Object.keys(company)[0]
                                ]?.length > 0 &&
                                selectedExternalMemberData[
                                  Object.keys(company)[0]
                                ]?.some(
                                  (member) => member.user_id === item?.userId
                                )
                              }
                              onChange={(event) =>
                                handleSelectExternalPerson(
                                  event,
                                  item,
                                  Object.keys(company)[0]
                                )
                              }
                              icon={
                                <NameBadge
                                  sx={{
                                    color: "#183243",
                                    width: "1.55rem",
                                    height: "1.55rem",
                                  }}
                                  size="small"
                                  variant="contained"
                                >
                                  <Typography
                                    color={"#183243"}
                                    fontSize={"0.75rem"}
                                  >
                                    {item?.userInfo?.firstName &&
                                      item?.userInfo?.firstName[0]}
                                  </Typography>
                                </NameBadge>
                              }
                            />
                          }
                          label={
                            <Box>
                              <Typography
                                title={`${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`}
                              >
                                {`${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`
                                  .length > 18
                                  ? `${`${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`.slice(
                                      0,
                                      18
                                    )}...`
                                  : `${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`}
                              </Typography>
                              {/* <Typography variant="caption">Admin</Typography> */}
                            </Box>
                          }
                        />
                      </Box>

                      <Box
                        width={"35%"}
                        display={"flex"}
                        alignItems={"center"}
                        gap={"0.5rem"}
                      >
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          border={"1px solid none"}
                          bgcolor={item?.membershipType ? "#E1E1E1" : "#fff"}
                          color={"#183243"}
                          borderRadius={"1rem"}
                          padding={"2px 6px"}
                          maxWidth={"4rem"}
                          height={"1.4rem"}
                          overflow={"hidden"}
                          textOverflow={"ellipsis"}
                          whiteSpace={"nowrap"}
                        >
                          <Typography
                            width={"4rem"}
                            fontSize={"0.7rem"}
                            title={item?.membershipType}
                            variant="caption"
                            sx={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item?.membershipType}
                          </Typography>
                        </Box>
                        <Box display={"flex"}>
                          <Button
                            variant="outlined"
                            sx={{
                              ...(selectedExternalMemberData[
                                Object.keys(company)[0]
                              ]?.find((m) => m.user_id === item?.userId)
                                ?.plan_type === 2
                                ? classes.activeToggelButton
                                : classes.inactiveToggelButton),
                            }}
                            onClick={() =>
                              selectedExternalMemberData[
                                Object.keys(company)[0]
                              ]?.some((m) => m.user_id === item?.userId) &&
                              toggleExternalMemberPlanType(
                                Object.keys(company)[0],
                                item?.userId
                              )
                            }
                            // disabled={
                            //   !selectedExternalMemberData[
                            //     Object.keys(company)[0]
                            //   ]?.some((m) => m.user_id === item?.userId)
                            // }
                          >
                            Yearly
                          </Button>
                          <Button
                            variant="outlined"
                            sx={{
                              ...(selectedExternalMemberData[
                                Object.keys(company)[0]
                              ]?.find((m) => m.user_id === item?.userId)
                                ?.plan_type === 1
                                ? classes.activeToggelButton
                                : classes.inactiveToggelButton),
                            }}
                            onClick={() =>
                              selectedExternalMemberData[
                                Object.keys(company)[0]
                              ]?.some((m) => m.user_id === item?.userId) &&
                              toggleExternalMemberPlanType(
                                Object.keys(company)[0],
                                item?.userId
                              )
                            }
                            // disabled={
                            //   !selectedExternalMemberData[
                            //     Object.keys(company)[0]
                            //   ]?.some((m) => m.user_id === item?.userId)
                            // }
                          >
                            Monthly
                          </Button>
                        </Box>
                      </Box>
                    </Stack>
                  ))}
                </Box>
              ))}

              {accountLevelPeople?.externalMember?.length === 0 &&
                activeButton == "external" && (
                  <Stack mt={"4rem"} alignItems="center">
                    <img src={no_member_icon} alt="no_member" />
                    <Typography
                      fontSize={14}
                      width={"20rem"}
                      marginTop={"0.93rem"}
                    >
                      You don’t have any External team members. Invite your
                      vendors, partners and customers to collaborate with you.
                    </Typography>
                  </Stack>
                )}
            </Box>
          )}
        </Box>

        <Box
          position={"absolute"}
          bottom={"1rem"}
          width={"100%"}
          margin={"auto"}
          textAlign={"center"}
          mt={"2rem"}
        >
          <Button
            onClick={handleClickNext}
            sx={{
              backgroundColor: "#DB0011",
              color: "#fff",
              padding: "0.4rem 2.4rem",
              textTransform: "none",
              borderRadius: "0.15rem",
              ":hover": {
                backgroundColor: "#DB0011",
                color: "#fff",
              },
            }}
            variant="contained"
          >
            {appContent?.save}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ToggelManageMemberDrawer;
