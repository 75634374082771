import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import no_member_icon from "../../../assets/images/chats/no_member_icon.svg";
import { useSelector } from "react-redux";
import {
  AccountLevelArkchatPermission,
  GroupLevelArkchatPermission,
  InviteType,
} from "../../../utils/constant";
import { useDispatch } from "react-redux";
import { requestAddmemberInGroup } from "../../../appStore/actions/ArkchatGroup/action";
import { getInviteUrl } from "../../../appStore/actions/subscriber/action";
import { handleCopyClick } from "../../../utils/copytoClipboard";
import InputDialogComponent from "../../../components/InputDialog";
import { createStyledIndicatorButton } from "../../../common/StyledComponents/SideBarComponents";
import AlertPopUp from "../../../common/AlertPopUp";
import { groupExternalMember } from "../../../utils/externalMemberGroping";
import { useTranslation } from "react-i18next";
import { showToastMsg } from "../../../appStore/actions/global/action";

const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%",
  },
  header: {
    backgroundColor: "#F9F8F8",
    color: "#0000",
    padding: "7px 8px",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "4px",
    borderRadius: "2px",
  },

  internalExternalButton: {
    background: "white",
    border: "1px solid #183243",
    color: "#183243",
    borderRadius: "0px",
    fontSize: "0.75rem",
    textTransform: "none",
    "&:hover": {
      background: "white",
    },
  },

  activeButton: {
    "&:hover": {
      background: "#183243",
    },
    background: "#183243",
    borderRadius: "0px",
    color: "white",
    fontSize: "0.75rem",
    textTransform: "none",
    border: "1px solid #183243",
  },

  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
  radiogp: {
    display: "block",
  },
  groupMember: {
    backgroundColor: "#F3F3F3",
    padding: "20px 10px",
  },

  // add group member
  addgmember: {
    transform: "translate(0, 70%)",
    textAlign: "center",
  },
  // add group member
};

const InviteGroupMember = ({ handleCloseAddPeopleDrawer }) => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const NameBadge = createStyledIndicatorButton("#E3E1E1");
  const [activeButton, setActiveButton] = useState("internal");
  const userData = useSelector((state) => state?.profile);
  const groupmemberdetails = useSelector(
    (state) => state?.arkchatgroup?.groupmember
  );
  const selectedGroup = useSelector(
    (state) => state?.appStateData?.selectedGroupData
  );
  const dispatch = useDispatch();
  const [groupmember, setGroupMember] = useState([]);
  const [externalGroupMember, setExternalGroupMember] = useState({});
  const [groupMemeberPermission, setGroupMemeberPermission] = useState([]);
  const [externalMemberData, setExternalMemberData] = useState([]);
  const [isownershipright, setownershipright] = useState({
    isOpen: false,
    reqBody: null,
    isChecked: false,
    key: "",
  });
  const [isPermissionModalOpen, setPermissionModal] = useState(false);
  const [alertContent, setAlertContent] = useState(appContent?.addMember);
  useEffect(() => {
    if (groupmemberdetails) {
      const combinedArray = [
        ...groupmemberdetails?.internalMember,
        ...groupmemberdetails?.externalMember,
      ];
      if (groupmemberdetails?.externalMember?.length > 0) {
        const groupedExternalData = groupExternalMember(
          groupmemberdetails?.externalMember,
          false,  // isRecipientList 
          appContent?.individual
        );

        setExternalMemberData(groupedExternalData);
        let extgrpMemberObj = {};
        groupedExternalData.forEach((company, index) => {
          const companyValues = Object.values(company)[0];
          const filteredItems = companyValues?.filter(
            (item) =>
              item?.permissionType !== GroupLevelArkchatPermission.REMOVE
          );
          const userIds = filteredItems?.map((item) => item?.userId);
          const companyName = Object.keys(company)[0];
          const companyUserId = { [companyName]: userIds };
          extgrpMemberObj = { ...extgrpMemberObj, ...companyUserId };
        });
        setExternalGroupMember({ ...extgrpMemberObj });
      }
      const membersid = combinedArray
        ?.filter(
          (item) => item?.permissionType != GroupLevelArkchatPermission.REMOVE
        )
        .map((item) => item?.userId);

      const permissionUserId = combinedArray
        ?.filter(
          (item) =>
            item?.permissionType == GroupLevelArkchatPermission?.ADMIN ||
            item?.permissionType == GroupLevelArkchatPermission?.MANAGER
        )
        .map((item) => item?.userId);
      setGroupMemeberPermission(permissionUserId);
      setGroupMember(membersid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupmemberdetails]);

  const handleCopyClickCallback = (type) => {
    if (type === "error") {
      dispatch(showToastMsg(appContent?.wrongError));
    } else {
      dispatch(showToastMsg(appContent?.copiedClipboard));
    }
  };

  const handleOpenMenu = async (event) => {
    let activePermission = userData?.profileData?.data?.activePermission;
    let isManagerData = groupmemberdetails?.internalMember?.find(
      (data) => data?.userId === userData?.profileData?.data?.id
    );
    if (
      activePermission == GroupLevelArkchatPermission.ADMIN ||
      activePermission == GroupLevelArkchatPermission.MANAGER ||
      isManagerData?.permissionType === GroupLevelArkchatPermission.MANAGER
    ) {
      if (activeButton === "internal") {
        const groupId = selectedGroup?.id;
        const res = await dispatch(getInviteUrl(1, groupId));
        if (res?.status) {
          handleCopyClick(res?.data?.inviteMessage, handleCopyClickCallback);
        }
      } else {
        const groupId = selectedGroup?.id;
        const res = await dispatch(getInviteUrl(2, groupId));
        if (res?.status) {
          handleCopyClick(res?.data?.inviteMessage, handleCopyClickCallback);
        }
      }
    } else {
      setPermissionModal(true);
    }
  };

  const handleClickExternal = () => {
    if (selectedGroup?.isExternal) {
      setAlertContent(appContent?.externalGroupAlert);
      setPermissionModal(true);
    } else {
      setActiveButton("external");
    }
  };

  const handleClickInternal = () => {
    setActiveButton("internal");
  };

  const handleAddRemoveInternalMemberFromGroup = async (
    selectedPerson,
    permission_type
  ) => {
    const reqBody = {
      group_id: selectedGroup?.id,
      members: [
        {
          user_id: selectedPerson?.userId,
          target_subscriber_id: selectedGroup?.isExternal
            ? userData?.profileData?.data?.activeSubscriber?.id
            : selectedPerson?.targetSubscriberId,
        },
      ],
      is_external: selectedGroup?.isExternal,
      permission_type,
      subscriber_id: userData?.profileData?.data?.activeSubscriber?.id,
    };
    const response = await dispatch(requestAddmemberInGroup(reqBody));
  };

  // const handleChangeInternalMemberGrpPermission = async (
  //   event,
  //   selectedPerson
  // ) => {
  //   let activePermission = userData?.profileData?.data?.activePermission;
  //   let isManagerData = groupmemberdetails?.internalMember?.find(
  //     (data) => data?.userId === userData?.profileData?.data?.id
  //   );
  //   if (
  //     activePermission == GroupLevelArkchatPermission.ADMIN ||
  //     activePermission == GroupLevelArkchatPermission.MANAGER ||
  //     isManagerData?.permissionType === GroupLevelArkchatPermission.MANAGER
  //   ) {
  //     if (event.target.checked) {
  //       const updatedSelectedPerson = [...groupmember, selectedPerson?.userId];
  //       const updatedSelectedPersonPermission = [
  //         ...groupMemeberPermission,
  //         selectedPerson?.userId,
  //       ];
  //       setGroupMember(updatedSelectedPerson);
  //       setGroupMemeberPermission(updatedSelectedPersonPermission);
  //       handleAddRemoveInternalMemberFromGroup(
  //         selectedPerson,
  //         GroupLevelArkchatPermission?.MANAGER
  //       );
  //     } else {
  //       const updatedSelectedPersonPermission = groupMemeberPermission?.filter(
  //         (item) => item !== selectedPerson?.userId
  //       );
  //       setGroupMemeberPermission(updatedSelectedPersonPermission);
  //       handleAddRemoveInternalMemberFromGroup(
  //         selectedPerson,
  //         GroupLevelArkchatPermission?.USER
  //       );
  //     }
  //   } else {
  //     setPermissionModal(true);
  //   }
  // };

  const handleSelectPerson = async (event, selectedPerson) => {
    let activePermission = userData?.profileData?.data?.activePermission;
    let isManagerData = groupmemberdetails?.internalMember?.find(
      (data) => data?.userId === userData?.profileData?.data?.id
    );
    if (
      activePermission == GroupLevelArkchatPermission.ADMIN ||
      activePermission == GroupLevelArkchatPermission.MANAGER ||
      isManagerData?.permissionType === GroupLevelArkchatPermission.MANAGER
    ) {
      if (event.target.checked) {
        const updatedSelectedPerson = [...groupmember, selectedPerson?.userId];
        setGroupMember(updatedSelectedPerson);
        handleAddRemoveInternalMemberFromGroup(
          selectedPerson,
          GroupLevelArkchatPermission.USER
        );
      } else {
        const updatedSelectedPerson = groupmember?.filter(
          (item) => item !== selectedPerson?.userId
        );
        const updatedSelectedPersonPermission = groupMemeberPermission?.filter(
          (item) => item !== selectedPerson?.userId
        );
        setGroupMemeberPermission(updatedSelectedPersonPermission);
        setGroupMember(updatedSelectedPerson);
        handleAddRemoveInternalMemberFromGroup(
          selectedPerson,
          GroupLevelArkchatPermission.REMOVE
        );
      }
    } //4=Manager of group)
    else {
      setPermissionModal(true);
    }
  };
  const handleAddRemoveExternalMemberFromGroup = async (
    selectedPerson,
    permission_type,
    isSingleSelect
  ) => {
    if (isSingleSelect) {
      const reqBody = {
        group_id: selectedGroup?.id,
        members: [
          {
            user_id: selectedPerson?.userId,
            target_subscriber_id: selectedPerson?.targetSubscriberId,
          },
        ],
        is_external: true,
        permission_type,
        subscriber_id: userData?.profileData?.data?.activeSubscriber?.id,
      };
      const response = await dispatch(requestAddmemberInGroup(reqBody));
    } else {
      const selectedUserData = selectedPerson?.map((user) => ({
        user_id: user?.userId,
        target_subscriber_id: user?.targetSubscriberId,
      }));
      const reqBody = {
        group_id: selectedGroup?.id,
        members: selectedUserData,
        is_external: true,
        permission_type,
        subscriber_id: userData?.profileData?.data?.activeSubscriber?.id,
      };
      const response = await dispatch(requestAddmemberInGroup(reqBody));
    }
  };

  const handleSelectExternalPerson = async (
    event,
    selectedPerson,
    companyName
  ) => {
    let activePermission = userData?.profileData?.data?.activePermission;
    let isManagerData = groupmemberdetails?.internalMember?.find(
      (data) => data?.userId === userData?.profileData?.data?.id
    );
    if (
      activePermission == GroupLevelArkchatPermission.ADMIN ||
      activePermission == GroupLevelArkchatPermission.MANAGER ||
      isManagerData?.permissionType === GroupLevelArkchatPermission.MANAGER
    ) {
      if (event.target.checked) {
        if (externalGroupMember[companyName]?.length > 0) {
          const companyUserId = {
            [companyName]: [
              ...externalGroupMember[companyName],
              selectedPerson?.userId,
            ],
          };
          setExternalGroupMember({ ...externalGroupMember, ...companyUserId });
          handleAddRemoveExternalMemberFromGroup(
            selectedPerson,
            GroupLevelArkchatPermission.USER,
            true
          );
        } else {
          const companyUserId = { [companyName]: [selectedPerson?.userId] };
          setExternalGroupMember({ ...externalGroupMember, ...companyUserId });
          handleAddRemoveExternalMemberFromGroup(
            selectedPerson,
            GroupLevelArkchatPermission.USER,
            true
          );
        }
      } else {
        const updatedCompanyUserId = externalGroupMember[companyName]?.filter(
          (item) => item !== selectedPerson?.userId
        );
        const updatedData = { [companyName]: updatedCompanyUserId };
        setExternalGroupMember({ ...externalGroupMember, ...updatedData });
        handleAddRemoveExternalMemberFromGroup(
          selectedPerson,
          GroupLevelArkchatPermission.REMOVE,
          true
        );
      }
    } else {
      setPermissionModal(true);
    }
  };

  const handleSelecteAllExternalCompanyMember = (
    event,
    selectedCompanyPerson,
    companyName
  ) => {
    let activePermission = userData?.profileData?.data?.activePermission;
    let isManagerData = groupmemberdetails?.internalMember?.find(
      (data) => data?.userId === userData?.profileData?.data?.id
    );
    if (
      activePermission == GroupLevelArkchatPermission.ADMIN ||
      activePermission == GroupLevelArkchatPermission.MANAGER ||
      isManagerData?.permissionType === GroupLevelArkchatPermission.MANAGER
    ) {
      if (event.target.checked) {
        const userIdData = selectedCompanyPerson[companyName]?.map(
          (user) => user?.userId
        );
        const companyUserId = { [companyName]: userIdData };
        setExternalGroupMember({ ...externalGroupMember, ...companyUserId });
        handleAddRemoveExternalMemberFromGroup(
          selectedCompanyPerson[companyName],
          GroupLevelArkchatPermission.USER,
          false
        );
      } else {
        const updatedExternalGroupMember = { ...externalGroupMember };
        delete updatedExternalGroupMember[companyName];
        setExternalGroupMember(updatedExternalGroupMember);
        handleAddRemoveExternalMemberFromGroup(
          selectedCompanyPerson[companyName],
          GroupLevelArkchatPermission.REMOVE,
          false
        );
      }
    } else {
      setPermissionModal(true);
    }
  };

  const handleOkayButtonClicked = async () => {
    setGroupMember([...groupmember, parseInt(isownershipright?.key)]);

    setGroupMemeberPermission({
      ...groupMemeberPermission,
      [isownershipright?.key]: isownershipright?.isChecked,
    });

    const response = await dispatch(
      requestAddmemberInGroup(isownershipright?.reqBody)
    );
    if (response) {
      setownershipright({
        ...isownershipright,
        isOpen: false,
      });
    }
  };

  const handleCancelButtonClick = () => {
    setownershipright({
      ...isownershipright,
      isOpen: false,
    });
  };
  return (
    <React.Fragment>
      <AlertPopUp
        title={"Alert."}
        bodyContent={alertContent}
        open={isPermissionModalOpen}
        closeModal={setPermissionModal}
      />
      <Box sx={classes.drawerBody}>
        <Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={classes.header}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography sx={classes.iconDiv}>
                <CloseIcon
                  sx={classes.iconn}
                  onClick={handleCloseAddPeopleDrawer}
                />
              </Typography>
              <Typography color={"black"}>Add group members</Typography>
            </Stack>
            {/* <Typography color={"black"}>Right</Typography> */}
          </Stack>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            padding="12px"
            mt={"0.4rem"}
            mb={"0.4rem"}
          >
            <Stack
              direction="row"
              justifyContent="left"
              alignItems="center"
              spacing={1}
            >
              <Button
                sx={
                  activeButton == "internal"
                    ? classes.activeButton
                    : classes.internalExternalButton
                }
                variant="contained"
                onClick={handleClickInternal}
              >
                Internal
              </Button>
              <Button
                sx={
                  activeButton == "external"
                    ? classes.activeButton
                    : classes.internalExternalButton
                }
                variant="contained"
                onClick={handleClickExternal}
              >
                External
              </Button>
            </Stack>
            {!selectedGroup?.isExternal && (
              <Box position={"relative"}>
                <Button
                  onClick={handleOpenMenu}
                  sx={{
                    background: "#DB0011",
                    color: "white",
                    borderRadius: "2px",
                    fontSize: "0.75rem",
                    textTransform: "none",
                    "&:hover": {
                      background: "#DB0011",
                      color: "white",
                    },
                  }}
                  variant="contained"
                >
                  Invite
                </Button>
              </Box>
            )}
          </Stack>
          <Box
            overflow={"scroll"}
            height={
              activeButton == "internal"
                ? "calc(100vh - 13rem)"
                : "calc(100vh - 16rem)"
            }
            sx={{
              "&::-webkit-scrollbar": {
                width: "1px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#F9F8F8",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "grey",
              },
            }}
          >
            {activeButton == "internal" && (
              <Box>
                {groupmemberdetails?.internalMember?.map((item) => (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    padding="0rem 1.18rem 0rem 0.75rem"
                    key={item?.userId + "internalInvitegrpmember"}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 30,
                              color: "#183243",
                            },
                          }}
                          checked={groupmember?.includes(item?.userId)}
                          onChange={(event) => handleSelectPerson(event, item)}
                          icon={
                            <NameBadge
                              sx={{ color: "#183243" }}
                              size="small"
                              variant="contained"
                            >
                              <Typography
                                color={"#183243"}
                                fontSize={"0.75rem"}
                              >
                                {item?.userInfo?.firstName &&
                                  item?.userInfo?.firstName[0]}
                              </Typography>
                            </NameBadge>
                          }
                        />
                      }
                      label={`${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`}
                    />
                    <>
                      {item?.inviteType ===
                        InviteType?.internalOwnerInviteType &&
                        (item?.permission ===
                          AccountLevelArkchatPermission?.ADMIN ||
                          userData?.profileData?.data?.accountOwner) && (
                          <Box>
                            <Typography>Owner</Typography>
                          </Box>
                        )}
                      {item?.inviteType === InviteType?.internal &&
                        item?.permission ===
                          AccountLevelArkchatPermission?.ADMIN && (
                          <Box>
                            <Typography>Admin</Typography>
                          </Box>
                        )}
                      {(item?.permission ===
                        AccountLevelArkchatPermission?.USER ||
                        item?.permission ===
                          AccountLevelArkchatPermission?.MANAGER) && (
                        // don't remove it, this can be enabled in future
                        // <FormControlLabel
                        //   control={
                        //     <Switchs
                        //       switchLabel="Manager"
                        //       checked={groupMemeberPermission?.includes(
                        //         item?.userId
                        //       )}
                        //       handleChange={(event) =>
                        //         handleChangeInternalMemberGrpPermission(
                        //           event,
                        //           item
                        //         )
                        //       }
                        //     />
                        //   }
                        // />
                        <Box>
                          <Typography>User</Typography>
                        </Box>
                      )}
                    </>
                  </Stack>
                ))}
              </Box>
            )}

            {activeButton == "external" && (
              <Box>
                {externalMemberData.map((company, index) => (
                  <Box padding="0px 19px 6px 12px">
                    <FormControlLabel
                      sx={{
                        ".MuiFormControlLabel-label": {
                          fontWeight: 500,
                          color: "#000000",
                        },
                      }}
                      control={
                        <Checkbox
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: 30,
                              color: "#183243",
                            },
                          }}
                          checked={
                            Object.values(company)[0]?.length ===
                            externalGroupMember[Object.keys(company)[0]]?.length
                          }
                          onChange={(event) =>
                            handleSelecteAllExternalCompanyMember(
                              event,
                              company,
                              Object.keys(company)[0]
                            )
                          }
                          icon={
                            <NameBadge
                              sx={{ color: "#183243" }}
                              size="small"
                              variant="contained"
                            >
                              <Typography
                                color={"#183243"}
                                fontSize={"0.75rem"}
                                fontWeight={600}
                              >
                                {Object.keys(company)[0] &&
                                  Object.keys(company)[0][0]}
                              </Typography>
                            </NameBadge>
                          }
                        />
                      }
                      label={
                        <Typography fontWeight={600} fontSize={"0.88rem"}>
                          {Object.keys(company)[0]}
                        </Typography>
                      }
                    />
                    {Object.values(company)[0]?.map((item, i) => (
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={1}
                        key={item?.userId + "externalInvitegrpmember"}
                      >
                        <Box width={"62%"} padding={"0rem 0rem 0rem 1.2rem"}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    fontSize: 25,
                                    color: "#183243",
                                  },
                                }}
                                checked={
                                  externalGroupMember[Object.keys(company)[0]]
                                    ?.length > 0 &&
                                  externalGroupMember[
                                    Object.keys(company)[0]
                                  ]?.includes(item?.userId)
                                }
                                onChange={(event) =>
                                  handleSelectExternalPerson(
                                    event,
                                    item,
                                    Object.keys(company)[0]
                                  )
                                }
                                icon={
                                  <NameBadge
                                    sx={{
                                      color: "#183243",
                                      width: "1.55rem",
                                      height: "1.55rem",
                                    }}
                                    size="small"
                                    variant="contained"
                                  >
                                    <Typography
                                      color={"#183243"}
                                      fontSize={"0.75rem"}
                                    >
                                      {item?.userInfo?.firstName &&
                                        item?.userInfo?.firstName[0]}
                                    </Typography>
                                  </NameBadge>
                                }
                              />
                            }
                            label={`${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`}
                          />
                        </Box>
                        {/* <FormControlLabel
                        control={
                          <Switchs
                            switchLabel="Owner"
                            name={item?.userId}
                            checked={groupMemeberPermission?.[item?.userId]}
                            handleChange={(event) =>
                              handleChangeInternalMemberGrpPermission(
                                event,
                                item
                              )
                            }
                          />
                        }
                      /> */}
                      </Stack>
                    ))}
                  </Box>
                ))}
              </Box>
            )}

            {groupmemberdetails?.externalMember?.length == 0 &&
              activeButton == "external" && (
                <Stack alignItems="center" sx={classes.addgmember}>
                  <img src={no_member_icon} alt="no_member" />
                  <Typography
                    fontSize={14}
                    width={"20rem"}
                    marginTop={"0.93rem"}
                  >
                    You don’t have any External team members. Invite your
                    vendors, partners and customers to collaborate with you.
                  </Typography>
                </Stack>
              )}
          </Box>
        </Stack>

        <Stack
          alignItems="center"
          spacing={1}
          padding="0.75rem 0rem"
          margin={"0 auto"}
          width={"22.5rem"}
        >
          {activeButton == "external" ? (
            <Typography fontSize={12} textAlign={"center"}>
              <Typography fontSize={12} fontWeight={600} display={"inline"}>
                Invite only one person from a firm.{" "}
              </Typography>
              Their firm joins your account and adds its team to collaborate
              with you. Invite freelancers individually.
            </Typography>
          ) : (
            <></>
          )}
        </Stack>
        {isownershipright?.isOpen && (
          <InputDialogComponent
            title="Ownership rights"
            isType="ownershipright"
            open={isownershipright?.isOpen}
            handleClose={() => {
              setownershipright({
                ...isownershipright,
                isOpen: false,
              });
            }}
            btnLabel="Cancel"
            alternateBtnLabel="Yes"
            handleAlternateBtnClick={handleOkayButtonClicked}
            onClick={handleCancelButtonClick}
          />
        )}
      </Box>
    </React.Fragment>
  );
};

export default InviteGroupMember;
