import React from "react";
import { useSelector } from "react-redux";
import {
  Tabs,
  Tab,
  Box,
  Button,
  Typography,
  Stack,
  Drawer,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import ScreenSearchSection from "../../../common/chatComponents/ScreenSearchSection";
import { styled } from "@mui/system";
import ChatInputControl from "../ChatInput";
import "../index.css";
import { StyledChatContainer } from "../../../Styled";
import CreateGroup from "../Drawer/CreateGroup";
import GroupMenuDrawer from "../Drawer/ChatGroupDrawer";
import InputDialogComponent from "../../../components/InputDialog";
import GroupMemberDrawer from "../Drawer/GroupMemberDrawer";
import LanguageList from "../Drawer/LanguageList";
import AddMemberGroupDrawer from "../Drawer/AddMemberGroupDrawer";
import Split from "@uiw/react-split";
import { GroupLevelArkchatPermission } from "../../../utils/constant";
import YouTubeAttachmentDrawer from "../Drawer/YouTubeAttachmentdrawer";
import GoogleAttachmentDrawer from "../Drawer/GoogleAttachmentDrawer";
import MediaFiles from "../Drawer/MediaFiles";
import selectGroupicon from "../../../assets/images/chats/select_group.svg";
import GrouMembeMessageReadStatus from "../Drawer/GroupMemberReadStatus";
import groupVector from "../../../assets/images/chats/groupVector.svg";
import TaskListTab from "../Task/TaskListTab";
import TaskGroupList from "../Task/Drawer/TaskGroupList";
import TaskTeamList from "../Task/Drawer/TaskTeamList";
import ApprovalListTab from "../Approval/ApprovalListTab";
import MarketPlaceViewList from "../../MarketPlace/Index";
import AIgetStarted from "../AI/AIgetStarted";
import AIQueryPage from "../AI/AIQueryPage";
import AIQueryInputBox from "../AI/AIQueryInputBox";
import useTabState from "../../../customHooks/useTabState";
import MessageList from "../MessageComponent/MessageList";
import TaskUpdate from "../MessageComponent/TaskUpdate";
import Drawers from "../Drawer/Drawers";
import MessageThreadList from "../MessageComponent/MessageThreadList";
import LoadingSpinner from "../../../common/LoadingSpinner/LoadingSpinner";
import Filtericon from "../../../assets/images/task/Filter_icon.svg";
import {
  createStyledIndicatorButton,
  FilterIcon,
} from "../../../common/StyledComponents/SideBarComponents";
const classes = {
  Tabs: {
    minHeight: "38px",
    width: "100%",
    "& .MuiTabs-flexContainer": {
      gap: "6%",
      paddingLeft: "0.8rem",
    },
  },
  tabs: {
    color: "black",
    padding: "0px 0px",
    textTransform: "none",
    minWidth: "unset",
    minHeight: "2.2rem",
    // marginRight: "13.5%",
    "&.Mui-selected": {
      color: "black",
    },
    "@media (max-width: 64em)": {
      // marginRight: "2.5rem",
    },
  },
};

const TabPanel = ({ children, value, index }) => (
  <div hidden={value !== index}>{value === index && <Box>{children}</Box>}</div>
);

export const StyledSubHeadingBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  backgroundColor: "#efefef",
  marginBottom: "0.2rem",
});

export const StyledSubHeadingButton = styled(Button)({
  backgroundColor: "#DB0011",
  // padding: "0.3rem 0.5rem",
  padding: "4px 5px",
  color: "#fff",
  fontSize: "0.8rem",
  minWidth: "unset",
  minHeight: "unset",
  borderRadius: "0.15rem",
  boxShadow: "none",
  textTransform: "none",
  display: "flex",
  alignItems: "center",
  // gap: "2px",
  gap: "4px",
  "&:hover": {
    backgroundColor: "#DB0011",
    color: "#fff",
  },
});

const MyTabs = ({
  isSyncing,
  setSellerTabLoading,
  isSellerTabLoading,
  isCreategroupOpen,
  handleClosegroupdrawer,
  setPricingDrawerOpen,
  handlesavearkchatgroup,
  isRequired,
  groupname,
  setGroupname,
  handleNameChange,
  setisTopicOpen,
  dmUserDeatils,
  isTopicOpen,
  setMoveTopicTriggered,
  setMoveTopicMsgId,
  newMessages,
  setNewMessages,
  handleCloseAddPeopleDrawer,
  pubnub,
  setShowSidebar,
  setFilterChecked,
  filterChecked,
  isAiQueryHistory,
  setAiQueryHistory,
  isShareAiText,
  setShareAiText,
  setAddpeopledarwer,
  isAddPeopledarwer,
  newGroupName,
  setNewGroupName,
  selectedTaskGroup,
  setSelectedTaskGroup,
  selectedTaskmember,
  setSelectedTaskMember,
  taskApprovalViewValue,
  setTaskApprovalViewValue,
  taskTabValue,
  setTaskTabValue,
  approvalTabValue,
  setApprovalTabValue,
  setSideBarOpen,
  isSideBarOpen,
  handleApplyApprovalFilter,
  handleApplyTaskFilter,
}) => {
  const { t } = useTranslation();
  const {
    isGroupmenuOpen,
    setLanguageMenuOpen,
    isLanguageMenuOpen,
    isGroupmembermenu,
    delrequired,
    setdeleteInputVal,
    delInputval,
    selectedGroup,
    setDeletegroupmenu,
    isDeleteGroupmenu,
    setEditGroupmenu,
    isEditGroupmenu,
    setNotificationpreferenceModal,
    isNotificationpreferenceModalOpen,
    set24Hour,
    is24Hour,
    setDDMMYY,
    isDDMMYY,
    setMMDDYY,
    isMMDDYY,
    setYYMMDD,
    isYYMMDD,
    setDateFormate,
    setMediaFilesOpen,
    isMediaFilesOpen,
    setDatAndTimeModal,
    isdateAndTimeModalOpen,
    setReadstatusdrawer,
    readStatusdrawer,
    setPrivateDisableChat,
    isPrivateDisableChat,
    setdisableprivatechat,
    isdisableprivatchat,
    setReplyParentMsg,
    replyParentMsg,
    setFileAttachment,
    fileAttachment,
    attachmentdrawer,
    setSelectedAnswer,
    selecteAnswer,
    setShowStaredAiHistory,
    showStaredAiHistory,
    setShowTempQuery,
    showTempQuery,
    setTempAiQuery,
    tempAiQuery,
    setAiQueryValue,
    aiQueryValue,
    taskButtonDrawer,
    selectRecipientsDrawerOpen,
    setSelectRecipientsDrawerOpen,
    setAttachment,
    attachment,
    setMessageType,
    isEmailChecked,
    isMobileChecked,
    setEmailChecked,
    setMobileChecked,
    selectedRecipients,
    setSelectedRecipients,
    externalGroupMember,
    setExternalGroupMemebr,
    taskAssignedUser,
    setTaskAssignedUser,
    dueTime,
    setDueTime,
    dueDate,
    setDueDate,
    priority,
    setPriority,
    isRepeat,
    setRepeat,
    repeatType,
    setRepeatType,
    selecteRepeatEvery,
    setSelectedRepeatEvery,
    setStepper,
    stepper,
    setTaskData,
    taskData,
    isTaskUpdateOpen,
    setTaskUpdateOpen,
    msgType,
    setUpdateTaskApprovalMsg,
    updateTaskApprovalMsg,
    checkedType,
    setCheckedType,
    progress,
    groupList,
    currentUserData,
    messageList,
    handleClickTaskUpdate,
    handleLanguageCloseMenu,
    handleCloseGroupMenu,
    handleOpenGroupDrawer,
    handleclickAttherate,
    handleChange,
    handleCloseMemberDrawer,
    handleSelectedGroupmenu,
    handleMessageSend,
    handleUpdateTaskApproval,
    handleViewThreadClickTaskApprovalTab,
    handleSendTempMsg,
    handleNotificationPreference,
    handleDateTimeUpdate,
    handleAddgroupMember,
    handleUpdateGroupname,
    handleDeleteGroup,
    handleOnDeleteInput,
    handleOnInputChange,
    handleEditGroup,
    handleOnChangeNewMessage,
    handleSelectTaskMemberCheck,
    handleTaskgroupItemclick,
    handlecloseTeamlist,
    handleClicktabButton,
    handleReadStatusDrawer,
    handleDisablePrivateChat,
    handleBacktoMessage,
    handlegetThreadMessages,
    handleSendGoogleAttachment,
    handleCloseGoogleDrawer,
    handleClickGoogle,
    handleSendYouTubeAttachment,
    handleCloseYoutubeDrawer,
    handleClickYouTube,
  } = useTabState({
    pubnub,
    setFilterChecked,
    setNewMessages,
    setisTopicOpen,
    newMessages,
    dmUserDeatils,
    setAddpeopledarwer,
    groupname,
    setGroupname,
    setShowSidebar,
    setShareAiText,
    isShareAiText,
    selectedTaskGroup,
    setSelectedTaskGroup,
    selectedTaskmember,
    setSelectedTaskMember,
    taskApprovalViewValue,
    setTaskApprovalViewValue,
    setTaskTabValue,
    setApprovalTabValue,
  });
  const appContent = t("AppContent", { returnObjects: true });
  const SmallActionButton = createStyledIndicatorButton("#DB0011");
  const currentHomeIndexTab = useSelector(
    (state) => state?.localAppReducer?.currentHomeIndexTab
  );
  const isAiScreenActive = useSelector(
    (state) => state?.appStateData?.isAiScreenActive
  );
  const isAccountSwitchLoading = useSelector(
    (state) => state?.localAppReducer?.isAccountSwitchLoading
  );
  const isThreadScreen = useSelector(
    (state) => state?.localAppReducer?.isThreadScreen
  );
  const showStarMessage = useSelector(
    (state) => state?.appStateData?.showStarMessage
  );
  return (
    <>
      <Box position={"relative"}>
        <StyledSubHeadingBox>
          <Box display={"flex"} alignItems={"center"} width={"100%"}>
            {!isSideBarOpen &&
              currentHomeIndexTab !== 0 &&
              taskApprovalViewValue !== 0 && (
                <SmallActionButton
                  onClick={() => setSideBarOpen(!isSideBarOpen)}
                  sx={{ ml: "0.2rem" }}
                  size="small"
                  variant="contained"
                >
                  <FilterIcon src={Filtericon} />
                </SmallActionButton>
              )}

            <Tabs
              value={currentHomeIndexTab}
              onChange={(event, newValue) => {
                handleChange(event, newValue);
                setSideBarOpen(false);
              }}
              TabIndicatorProps={{
                style: { backgroundColor: "#183243" },
              }}
              sx={classes.Tabs}
            >
              <Tab disableRipple sx={classes.tabs} label={appContent?.chats} />
              <Tab disableRipple sx={classes.tabs} label={appContent?.tasks} />
              <Tab
                disableRipple
                sx={classes.tabs}
                label={appContent?.approvals}
              />
              <Tab
                disableRipple
                sx={classes.tabs}
                label={appContent?.sellers}
              />
            </Tabs>
          </Box>

          {currentHomeIndexTab == 0 && (
            <Box>
              <Button
                disabled={!selectedGroup}
                onClick={handleOpenGroupDrawer}
                sx={{
                  bgcolor: "#DB0011",
                  textTransform: "none",
                  color: "#fff",
                  "&:hover": {
                    bgcolor: "#DB0011",
                    textTransform: "none",
                    color: "#fff",
                  },
                }}
              >
                <Box display={"flex"} alignItems={"center"} mr={"0.2rem"}>
                  <img src={groupVector} alt="icon" />
                </Box>{" "}
                <Box>
                  <Typography>More</Typography>
                </Box>
              </Button>
            </Box>
          )}
        </StyledSubHeadingBox>

        <TabPanel value={currentHomeIndexTab} index={0}>
          <StyledChatContainer>
            {selectedGroup?.isGroup && (
              <ScreenSearchSection
                setisTopicOpen={setisTopicOpen}
                setMediaFilesOpen={setMediaFilesOpen}
                isTopicOpen={isTopicOpen}
                selectedGroup={selectedGroup}
                handleAddgroupMember={handleAddgroupMember}
                setAiQueryHistory={setAiQueryHistory}
                setTempAiQuery={setTempAiQuery}
                setShareAiText={setShareAiText}
                setShowStaredAiHistory={setShowStaredAiHistory}
                showStaredAiHistory={showStaredAiHistory}
                setSelectedAnswer={setSelectedAnswer}
                isAiQueryHistory={isAiQueryHistory}
              />
            )}

            <>
              {isSyncing && !isAccountSwitchLoading ? (
                <LoadingSpinner />
              ) : (
                <Split
                  mode="vertical"
                  visiable={false}
                  style={{
                    height:
                      isThreadScreen || !selectedGroup?.isGroup
                        ? "calc(100% + 10px)"
                        : "calc(100% - 29px)",
                    // border:"1px solid blue"
                  }}
                >
                  {selectedGroup ? (
                    <>
                      {isAiScreenActive ? (
                        <>
                          {!currentUserData?.isAiJourneyCompleted ? (
                            <AIgetStarted />
                          ) : (
                            <>
                              <AIQueryPage
                                isAiQueryHistory={isAiQueryHistory}
                                setAiQueryHistory={setAiQueryHistory}
                                tempAiQuery={tempAiQuery}
                                setTempAiQuery={setTempAiQuery}
                                showTempQuery={showTempQuery}
                                setShowTempQuery={setShowTempQuery}
                                isShareAiText={isShareAiText}
                                setShareAiText={setShareAiText}
                                setNewMessages={setNewMessages}
                                setShowStaredAiHistory={setShowStaredAiHistory}
                                showStaredAiHistory={showStaredAiHistory}
                                setSelectedAnswer={setSelectedAnswer}
                                selecteAnswer={selecteAnswer}
                              />
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          {!isThreadScreen ||
                          showStarMessage ||
                          (isThreadScreen && showStarMessage) ? (
                            <MessageList
                              handleSendTempMsg={handleSendTempMsg}
                              messageList={selectedGroup ? messageList : []}
                              setMediaFilesOpen={setMediaFilesOpen}
                              setMoveTopicTriggered={setMoveTopicTriggered}
                              setMoveTopicMsgId={setMoveTopicMsgId}
                              handlegetThreadMessages={handlegetThreadMessages}
                              isThreadScreen={isThreadScreen}
                              handleBacktoMessage={handleBacktoMessage}
                              setisTopicOpen={setisTopicOpen}
                              handleReadStatusDrawer={handleReadStatusDrawer}
                              setTaskData={setTaskData}
                              setTaskUpdateOpen={setTaskUpdateOpen}
                              handleClickTaskUpdate={handleClickTaskUpdate}
                              setReplyParentMsg={setReplyParentMsg}
                              progress={progress}
                            />
                          ) : (
                            <MessageThreadList
                              handleSendTempMsg={handleSendTempMsg}
                              messageList={selectedGroup ? messageList : []}
                              setMediaFilesOpen={setMediaFilesOpen}
                              setMoveTopicTriggered={setMoveTopicTriggered}
                              setMoveTopicMsgId={setMoveTopicMsgId}
                              handlegetThreadMessages={handlegetThreadMessages}
                              isThreadScreen={isThreadScreen}
                              handleBacktoMessage={handleBacktoMessage}
                              setisTopicOpen={setisTopicOpen}
                              handleReadStatusDrawer={handleReadStatusDrawer}
                              setTaskData={setTaskData}
                              setTaskUpdateOpen={setTaskUpdateOpen}
                              handleClickTaskUpdate={handleClickTaskUpdate}
                              setReplyParentMsg={setReplyParentMsg}
                              progress={progress}
                            />
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <Stack
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          height: "100%",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={selectGroupicon}
                          style={{ height: "100px" }}
                          alt=""
                        />
                      </Stack>
                    </>
                  )}

                  {isAiScreenActive &&
                    !isAiQueryHistory &&
                    !isShareAiText &&
                    currentUserData?.isAiJourneyCompleted && (
                      <AIQueryInputBox
                        setAiQueryValue={setAiQueryValue}
                        aiQueryValue={aiQueryValue}
                        tempAiQuery={tempAiQuery}
                        setTempAiQuery={setTempAiQuery}
                        showTempQuery={showTempQuery}
                        setShowTempQuery={setShowTempQuery}
                      />
                    )}

                  {((isAiScreenActive && !isAiQueryHistory && isShareAiText) ||
                    (selectedGroup && !isAiScreenActive)) && (
                    <ChatInputControl
                      handleclickAttherate={handleclickAttherate}
                      handleMessageSend={handleMessageSend}
                      handleOnChangeNewMessage={handleOnChangeNewMessage}
                      newMessages={newMessages}
                      handleClickYouTube={handleClickYouTube}
                      setFileAttachment={setFileAttachment}
                      handleClickGoogle={handleClickGoogle}
                      fileAttachment={fileAttachment}
                      attachment={attachment}
                      setisTopicOpen={setisTopicOpen}
                      setMoveTopicTriggered={setMoveTopicTriggered}
                      isTopicOpen={isTopicOpen}
                      setAttachment={setAttachment}
                      replyParentMsg={replyParentMsg}
                      setReplyParentMsg={setReplyParentMsg}
                      selectedGroup={selectedGroup}
                      isPrivateChatOn={
                        groupList?.find(
                          (item) => item?.pnId == selectedGroup?.pnId
                        )?.isPrivateChatOn
                      }
                      isTaskUpdateOpen={isTaskUpdateOpen}
                      isThreadScreen={isThreadScreen}
                      setPricingDrawerOpen={setPricingDrawerOpen}
                    />
                  )}
                </Split>
              )}
            </>
            <Drawer
              sx={{ zIndex: 1202 }}
              open={isGroupmenuOpen}
              anchor={"right"}
            >
              <GroupMenuDrawer
                handleCloseGroupMenu={handleCloseGroupMenu}
                selectedGroup={selectedGroup}
                handleEditGroup={handleEditGroup}
                // selectedgroupmenu={selectedgroupmenu}
                handleSelectedGroupmenu={handleSelectedGroupmenu}
                setLanguageMenuOpen={setLanguageMenuOpen}
                setDatAndTimeModal={setDatAndTimeModal}
                setNotificationpreferenceModal={setNotificationpreferenceModal}
                setMediaFilesOpen={setMediaFilesOpen}
                setdisableprivatechat={setdisableprivatechat}
              />
            </Drawer>
            <Drawer
              sx={{ zIndex: 1202 }}
              open={isCreategroupOpen}
              anchor={"right"}
            >
              <CreateGroup
                handleClosegroupdrawer={handleClosegroupdrawer}
                handlesavearkchatgroup={handlesavearkchatgroup}
                handleNameChange={handleNameChange}
                newGroupName={newGroupName}
                isRequired={isRequired}
              />
            </Drawer>

            <Drawer
              sx={{ zIndex: 1202 }}
              open={attachmentdrawer?.isYoutube}
              anchor={"right"}
            >
              <YouTubeAttachmentDrawer
                handleCloseYoutubeDrawer={handleCloseYoutubeDrawer}
                handleSendYouTubeAttachment={handleSendYouTubeAttachment}
                fileAttachment={fileAttachment}
                attachment={attachment}
              />
            </Drawer>

            <Drawer
              sx={{ zIndex: 1202 }}
              open={attachmentdrawer?.isGoogle}
              anchor={"right"}
            >
              <GoogleAttachmentDrawer
                handleCloseGoogleDrawer={handleCloseGoogleDrawer}
                handleSendGoogleAttachment={handleSendGoogleAttachment}
                fileAttachment={fileAttachment}
                attachment={attachment}
              />
            </Drawer>

            <Drawer
              sx={{ zIndex: 1202 }}
              open={isLanguageMenuOpen}
              anchor={"right"}
            >
              <LanguageList
                handleLanguageCloseMenu={handleLanguageCloseMenu}
                setLanguageMenuOpen={setLanguageMenuOpen}
                selectedGroup={selectedGroup}
              />
            </Drawer>

            <Drawer
              sx={{ zIndex: 1202 }}
              open={isMediaFilesOpen}
              anchor={"right"}
            >
              <MediaFiles
                setMediaFilesOpen={setMediaFilesOpen}
                isThreadScreen={isThreadScreen}
              />
            </Drawer>

            <AddMemberGroupDrawer
              isaddmemberOpen={isAddPeopledarwer}
              handleCloseAddPeopleDrawer={handleCloseAddPeopleDrawer}
              selectedGroup={selectedGroup}
            />

            <GroupMemberDrawer
              handleCloseMemberDrawer={handleCloseMemberDrawer}
              isGroupmembermenu={isGroupmembermenu}
            />
          </StyledChatContainer>
        </TabPanel>
        <TabPanel value={currentHomeIndexTab} index={1}>
          <TaskListTab
            setShowSidebar={setShowSidebar}
            handleClicktabButton={handleClicktabButton}
            taskApprovalViewValue={taskApprovalViewValue}
            setTaskApprovalViewValue={setTaskApprovalViewValue}
            handleClickTaskUpdate={handleClickTaskUpdate}
            taskTabValue={taskTabValue}
            setTaskTabValue={setTaskTabValue}
            selectedTaskmember={selectedTaskmember}
            setSelectedTaskMember={setSelectedTaskMember}
            selectedTaskGroup={selectedTaskGroup}
            setSelectedTaskGroup={setSelectedTaskGroup}
            filterChecked={filterChecked}
            setFilterChecked={setFilterChecked}
            handleApplyTaskFilter={handleApplyTaskFilter}
            handleViewThreadClick={handleViewThreadClickTaskApprovalTab}
          />
        </TabPanel>
        <TabPanel value={currentHomeIndexTab} index={2}>
          <ApprovalListTab
            setShowSidebar={setShowSidebar}
            handleClicktabButton={handleClicktabButton}
            taskApprovalViewValue={taskApprovalViewValue}
            setTaskApprovalViewValue={setTaskApprovalViewValue}
            handleClickTaskUpdate={handleClickTaskUpdate}
            approvalTabValue={approvalTabValue}
            setApprovalTabValue={setApprovalTabValue}
            selectedTaskmember={selectedTaskmember}
            setSelectedTaskMember={setSelectedTaskMember}
            selectedTaskGroup={selectedTaskGroup}
            setSelectedTaskGroup={setSelectedTaskGroup}
            filterChecked={filterChecked}
            setFilterChecked={setFilterChecked}
            handleApplyApprovalFilter={handleApplyApprovalFilter}
            handleViewThreadClick={handleViewThreadClickTaskApprovalTab}
          />
        </TabPanel>
        <TabPanel value={currentHomeIndexTab} index={3}>
          <MarketPlaceViewList
            setSellerTabLoading={setSellerTabLoading}
            isSellerTabLoading={isSellerTabLoading}
          />
        </TabPanel>

        {/* common drawers */}
        <>
          {isEditGroupmenu && (
            <InputDialogComponent
              title="Edit Group"
              open={isEditGroupmenu}
              handleClose={() => setEditGroupmenu(false)}
              onChange={handleOnInputChange}
              value={groupname}
              btnLabel="Update"
              inputLable="group Name"
              isType="edit"
              onClick={handleUpdateGroupname}
            />
          )}

          {isDeleteGroupmenu && (
            <InputDialogComponent
              title="Delete/ Exit Group"
              isType="delete"
              open={isDeleteGroupmenu}
              handleClose={() => {
                setDeletegroupmenu(false);
                setdeleteInputVal("");
              }}
              onChange={handleOnDeleteInput}
              value={delInputval}
              btnLabel="Delete"
              required={delrequired}
              onClick={handleDeleteGroup}
            />
          )}

          {isdisableprivatchat && (
            <InputDialogComponent
              title="Deactivate private chats & To Dos"
              isType="disableprivatechat"
              open={isdisableprivatchat}
              handleClose={() => {
                setdisableprivatechat(false);
              }}
              onChange={(event) => setdisableprivatechat(event.target.checked)}
              value={isPrivateDisableChat}
              btnLabel="save"
              disabled={
                currentUserData?.activePermission ==
                GroupLevelArkchatPermission?.USER
              }
              required={false}
              onClick={handleDisablePrivateChat}
              setPrivateDisableChat={setPrivateDisableChat}
              handleDisableEnablepvtChat={(event) =>
                setPrivateDisableChat(event.target.checked)
              }
            />
          )}

          {isdateAndTimeModalOpen && (
            <InputDialogComponent
              title="Date & time format"
              isType="date&time"
              open={isdateAndTimeModalOpen}
              handleClose={() => {
                setDatAndTimeModal(false);
                setDateFormate("");
              }}
              btnLabel="Save"
              set24Hour={set24Hour}
              setDDMMYY={setDDMMYY}
              isDDMMYY={isDDMMYY}
              setMMDDYY={setMMDDYY}
              isMMDDYY={isMMDDYY}
              setYYMMDD={setYYMMDD}
              isYYMMDD={isYYMMDD}
              is24Hour={is24Hour}
              setDateFormate={setDateFormate}
              onClick={handleDateTimeUpdate}
            />
          )}

          {selectedGroup && (
            <Drawer
              disableEnforceFocus
              hideBackdrop={true}
              open={selectRecipientsDrawerOpen}
              anchor={"right"}
            >
              <Drawers
                selectedGroup={selectedGroup}
                setSelectRecipientsDrawerOpen={setSelectRecipientsDrawerOpen}
                setMessageType={setMessageType}
                msgType={msgType}
                handleMessageSend={handleMessageSend}
                handleOnChangeNewMessage={handleOnChangeNewMessage}
                newMessages={newMessages}
                selectedRecipients={selectedRecipients}
                setSelectedRecipients={setSelectedRecipients}
                taskAssignedUser={taskAssignedUser}
                setTaskAssignedUser={setTaskAssignedUser}
                dueTime={dueTime}
                setDueTime={setDueTime}
                dueDate={dueDate}
                setDueDate={setDueDate}
                priority={priority}
                setPriority={setPriority}
                isRepeat={isRepeat}
                setRepeat={setRepeat}
                repeatType={repeatType}
                setRepeatType={setRepeatType}
                selecteRepeatEvery={selecteRepeatEvery}
                setSelectedRepeatEvery={setSelectedRepeatEvery}
                stepper={stepper}
                setStepper={setStepper}
                checkedType={checkedType}
                setCheckedType={setCheckedType}
                updateTaskApprovalMsg={updateTaskApprovalMsg}
                setUpdateTaskApprovalMsg={setUpdateTaskApprovalMsg}
                handleUpdateTaskApproval={handleUpdateTaskApproval}
                isTaskUpdateOpen={isTaskUpdateOpen}
                taskData={taskData}
                externalGroupMember={externalGroupMember}
                setExternalGroupMemebr={setExternalGroupMemebr}
                setTaskUpdateOpen={setTaskUpdateOpen}
                setTaskData={setTaskData}
                selectRecipientsDrawerOpen={selectRecipientsDrawerOpen}
                handleClickYouTube={handleClickYouTube}
                setFileAttachment={setFileAttachment}
                handleClickGoogle={handleClickGoogle}
                fileAttachment={fileAttachment}
                attachment={attachment}
                setAttachment={setAttachment}
              />
            </Drawer>
          )}

          <Drawer
            disableEnforceFocus
            // hideBackdrop={true}
            anchor={"right"}
            open={isTaskUpdateOpen}
          >
            <TaskUpdate
              selectedRecipients={selectedRecipients}
              handleUpdateTaskApproval={handleUpdateTaskApproval}
              setTaskUpdateOpen={setTaskUpdateOpen}
              setSelectRecipientsDrawerOpen={setSelectRecipientsDrawerOpen}
              setStepper={setStepper}
              taskData={taskData}
              setTaskData={setTaskData}
              setMessageType={setMessageType}
              msgType={msgType}
              selectRecipientsDrawerOpen={selectRecipientsDrawerOpen}
              handleMessageSend={handleMessageSend}
              newMessages={newMessages}
              handleClickYouTube={handleClickYouTube}
              setFileAttachment={setFileAttachment}
              handleClickGoogle={handleClickGoogle}
              fileAttachment={fileAttachment}
              attachment={attachment}
              setAttachment={setAttachment}
              stepper={stepper}
              setDueDate={setDueDate}
              setDueTime={setDueTime}
              checkedType={checkedType}
              setCheckedType={setCheckedType}
              setReplyParentMsg={setReplyParentMsg}
              updateTaskApprovalMsg={updateTaskApprovalMsg}
              setUpdateTaskApprovalMsg={setUpdateTaskApprovalMsg}
            />
          </Drawer>

          <Drawer
            sx={{ zIndex: 1202 }}
            open={readStatusdrawer}
            anchor={"right"}
          >
            <GrouMembeMessageReadStatus
              handleCloseMemberDrawer={() => setReadstatusdrawer(false)}
            />
          </Drawer>

          <Drawer
            sx={{ zIndex: 1202 }}
            open={taskButtonDrawer?.isOpen}
            anchor="right"
          >
            {taskButtonDrawer?.header == "Group" && (
              <TaskGroupList
                handlecloseGroupList={handlecloseTeamlist}
                handlegroupItemclick={handleTaskgroupItemclick}
                selectedTaskGroup={selectedTaskGroup}
              />
            )}
            {taskButtonDrawer?.header == "Team" && (
              <TaskTeamList
                handlecloseTeamlist={handlecloseTeamlist}
                handleSelectTaskMemberCheck={handleSelectTaskMemberCheck}
                selectedTaskmember={selectedTaskmember}
              />
            )}
          </Drawer>

          {isNotificationpreferenceModalOpen && (
            <InputDialogComponent
              title="Notification preference"
              isType="notification"
              open={isNotificationpreferenceModalOpen}
              handleClose={() => {
                setNotificationpreferenceModal(false);
                setDateFormate("");
              }}
              btnLabel="Save"
              onClick={handleNotificationPreference}
              setMobileChecked={setMobileChecked}
              setEmailChecked={setEmailChecked}
              isMobileChecked={isMobileChecked}
              isEmailChecked={isEmailChecked}
            />
          )}
        </>
      </Box>
    </>
  );
};

export default MyTabs;
