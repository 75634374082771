import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Stack,
  Button,
  TextField,
  FormHelperText,
  InputAdornment,
  Drawer,
  Switch,
  CircularProgress,
} from "@mui/material";
import { ArrowForwardIosOutlined } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import CountryDrawer from "../../../page/post/Drawer/CountryDrawer";
import StateDrawer from "../../../page/post/Drawer/StateDrawer";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/system";
import {
  billingFormData,
  handleShowLoading,
  submitBillingInfo,
  updateBillingAddress,
  updateSubscription,
} from "../../../appStore/actions/Subscription/action";
import { accountType } from "../../../utils/constant";
import { showToastMsg } from "../../../appStore/actions/global/action";
import { useTranslation } from "react-i18next";
import { PAYMENT_INTENT_DATA } from "../../../appStore/actions/Subscription/type";

const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    height: "100%",
    position: "relative",
  },
  header: {
    backgroundColor: "#F9F8F8",
    color: "#0000",
    padding: "0.43rem 0.5rem",
    mb: "0.5rem",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "1.87rem",
    width: "1.87rem",
    cursor: "pointer",
    padding: "0.25rem",
    borderRadius: "0.12rem",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
};
const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 45,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#183243",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 24,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const BillingInformationForm = ({
  setBillingFormDrawerOpen,
  setQuantityDarwerOpen,
}) => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const currentuserdetails = useSelector(
    (state) => state?.profile?.profileData?.data
  );
  const billingData = useSelector((state) => state?.subscription?.billingData);
  const billingAddress = useSelector(
    (state) => state?.subscription?.billingAddress
  );
  const countryMasterData = useSelector((state) => state?.country);
  const [isLoading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState({
    name: currentuserdetails?.activeSubscriber?.subscriberFirmName,
    address_line1: "",
    address_line2: "",
    city: "",
    postal_code: "",
    tax_number: "",
  });
  const [isRequiredEmpty, setIsRequiredEmpty] = useState({
    name: false,
    address_line1: false,
    address_line2: false,
    city: false,
    postal_code: false,
    tax_number: false,
    countryData: false,
    stateData: false,
  });
  const [isCountryDrawer, setCountryDrawer] = useState(false);
  const [isStateDrawerOpen, setStateDrawerOpen] = useState(false);
  const [countryData, setCountryData] = useState({});
  const [stateData, setStateData] = useState({});
  const [isTaxRequired, setTaxRequired] = useState(true);
  const dispatch = useDispatch();
  const validateAndSetUserProfileData = (
    name,
    value,
    setProfileData,
    profileData,
    setIsRequiredEmpty,
    isRequiredEmpty
  ) => {
    let isValid = true;
    switch (name) {
      case "name":
      case "city":
        isValid = /^(?:[a-zA-Z][a-zA-Z\s]*)?$/.test(value);
        break;
      case "address_line1":
      case "address_line2":
        isValid = /^(?:[a-zA-Z0-9,][a-zA-Z0-9,\s]*)?$/.test(value);
        break;
      case "postal_code":
        isValid = /^(?:[a-zA-Z0-9-][a-zA-Z0-9-\s]*)?$/.test(value);
        break;
      case "tax_number":
        isValid = /^(?:[a-zA-Z0-9][a-zA-Z0-9]*)?$/.test(value);
        break;
      default:
        break;
    }
    if (isValid) {
      setProfileData({
        ...profileData,
        [name]: value,
      });
      setIsRequiredEmpty({
        ...isRequiredEmpty,
        [name]: value.length === 0,
      });
    } else {
      setIsRequiredEmpty({
        ...isRequiredEmpty,
        [name]: true,
      });
    }
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    validateAndSetUserProfileData(
      name,
      value,
      setProfileData,
      profileData,
      setIsRequiredEmpty,
      isRequiredEmpty
    );
  };

  const handleCountryInputChange = (country, allData) => {
    setCountryData(allData);
    setStateData({});
    const isRequiredValue = {
      ...isRequiredEmpty,
      countryData: !allData?.name ? true : false,
    };
    setIsRequiredEmpty(isRequiredValue);
    setCountryDrawer(false);
  };
  const handleStateInputChange = (stateData) => {
    setStateData(stateData);
    const isRequiredValue = {
      ...isRequiredEmpty,
      stateData: !stateData?.name ? true : false,
    };
    setIsRequiredEmpty(isRequiredValue);
    setStateDrawerOpen(false);
  };
  const handleSubmit = () => {
    let billingReqBody = {
      billing_name: profileData?.name,
      address_line1: profileData?.address_line1,
      address_line2: profileData?.address_line2,
      city: profileData?.city,
      state: stateData?.name,
      state_code: stateData?.stateCode,
      country: countryData?.name,
      country_code: countryData?.iso2,
      postal_code: profileData?.postal_code,
    };
    let reqBody = {
      ...billingData,
      plan_id: billingData?.plan_id,
      quantity: billingData?.quantity,
      total: billingData?.total,
      tax: billingData?.tax,
      tax_amount: billingData?.tax_amount,
      sub_total: billingData?.sub_total,
      currency: billingData?.currency,
      gateway: billingData?.gateway,
      payment_type: billingData?.payment_type,
    };
    if (
      currentuserdetails?.activeSubscriber?.subscriberPriority ===
      accountType?.Organisation
    ) {
      if (
        profileData?.name &&
        profileData?.address_line1 &&
        profileData?.city &&
        profileData?.postal_code &&
        (isTaxRequired ? profileData?.tax_number : true) &&
        countryData?.name &&
        stateData?.name
      ) {
        billingReqBody = {
          ...billingReqBody,
          tax_number: profileData?.tax_number,
        };
        setLoading(true);
        dispatch(updateBillingAddress(billingReqBody)).then((res) => {
          if (res?.status) {
            dispatch(updateSubscription({ users: billingData?.users })).then(
              () => {
                dispatch(submitBillingInfo(reqBody)).then((res) => {
                  if (res?.status) {
                    dispatch({ type: PAYMENT_INTENT_DATA, payload: res?.data });
                    dispatch(
                      billingFormData({ ...reqBody, id: res?.data?.id })
                    );
                    setLoading(false);
                    setQuantityDarwerOpen(true);
                  } else {
                    dispatch(showToastMsg(appContent.wrongError));
                    setLoading(false);
                  }
                });
              }
            );
          } else {
            dispatch(showToastMsg(appContent.wrongError));
            setLoading(false);
          }
        });
      } else {
        const isRequiredValue = {
          ...isRequiredEmpty,
          name: !profileData?.name ? true : false,
          address_line1: !profileData?.address_line1 ? true : false,
          city: !profileData?.city ? true : false,
          postal_code: !profileData?.postal_code ? true : false,
          tax_number: !profileData?.tax_number && isTaxRequired ? true : false,
          countryData: !countryData?.name ? true : false,
          stateData: !stateData?.name ? true : false,
        };
        setIsRequiredEmpty(isRequiredValue);
      }
    } else {
      if (
        profileData?.name &&
        profileData?.address_line1 &&
        profileData?.city &&
        profileData?.postal_code &&
        countryData?.name &&
        stateData?.name
      ) {
        dispatch(updateBillingAddress(billingReqBody)).then((res) => {
          setLoading(true);
          if (res?.status) {
            dispatch(updateSubscription({ users: billingData?.users })).then(
              () => {
                dispatch(submitBillingInfo(reqBody)).then((res) => {
                  if (res?.status) {
                    dispatch({ type: PAYMENT_INTENT_DATA, payload: res?.data });
                    dispatch(
                      billingFormData({ ...reqBody, id: res?.data?.id })
                    );
                    setLoading(false);
                    setQuantityDarwerOpen(true);
                  } else {
                    dispatch(showToastMsg(appContent.wrongError));
                    setLoading(false);
                  }
                });
              }
            );
          } else {
            dispatch(showToastMsg(appContent.wrongError));
            setLoading(false);
          }
        });
      } else {
        const isRequiredValue = {
          ...isRequiredEmpty,
          name: !profileData?.name ? true : false,
          address_line1: !profileData?.address_line1 ? true : false,
          city: !profileData?.city ? true : false,
          postal_code: !profileData?.postal_code ? true : false,
          countryData: !countryData?.name ? true : false,
          stateData: !stateData?.name ? true : false,
        };
        setIsRequiredEmpty(isRequiredValue);
      }
    }
  };

  useEffect(() => {
    const billingBasicData = {
      name: currentuserdetails?.activeSubscriber?.subscriberFirmName,
      address_line1: billingAddress?.addressLine1 || "",
      address_line2: billingAddress?.addressLine2 || "",
      city: billingAddress?.city || "",
      postal_code: billingAddress?.postalCode || "",
      tax_number: billingAddress?.taxNumber || "",
    };

    const savedCountry =
      countryMasterData?.countrylist?.data?.find(
        (item) => item?.iso2 === billingAddress?.countryCode
      ) || {};
    const savedState = {
      name: billingAddress?.state,
      stateCode: billingAddress?.stateCode,
    };
    setProfileData(billingBasicData);
    setCountryData(savedCountry);
    setStateData(savedState);
  }, []);

  return (
    <Box sx={classes.drawerBody}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={classes.header}
      >
        <Typography sx={classes.iconDiv}>
          <CloseIcon
            sx={classes.iconn}
            onClick={() => setBillingFormDrawerOpen(false)}
          />
        </Typography>
        <Typography color={"black"}>Billing Information</Typography>
      </Stack>
      <Drawer sx={{ zIndex: 1202 }} open={isCountryDrawer} anchor={"right"}>
        <CountryDrawer
          handleCountryInputChange={handleCountryInputChange}
          setCountryDrawer={setCountryDrawer}
        />
      </Drawer>
      <Drawer sx={{ zIndex: 1202 }} open={isStateDrawerOpen} anchor={"right"}>
        <StateDrawer
          stateData={countryData?.states}
          setStateDrawerOpen={setStateDrawerOpen}
          handleStateInputChange={handleStateInputChange}
        />
      </Drawer>
      <Box
        width={"90%"}
        margin={"auto"}
        overflow={"scroll"}
        height={"calc(100vh - 8rem)"}
        sx={{
          "&::-webkit-scrollbar": {
            width: "1px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#F9F8F8",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "grey",
          },
        }}
      >
        <form
          // onSubmit={handleSave}
          style={{ width: "100%" }}
        >
          <Box mb={"0.5rem"}>
            <Box>
              <TextField
                error={!isRequiredEmpty?.name ? false : true}
                size="Large"
                type="text"
                label={"Name"}
                onChange={handleInput}
                variant="standard"
                value={profileData?.name}
                name="name"
                required
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
              <FormHelperText error={!isRequiredEmpty?.name ? false : true}>
                Required
              </FormHelperText>
            </Box>
          </Box>

          <Box mb={"0.5rem"}>
            <Box>
              <TextField
                error={!isRequiredEmpty?.address_line1 ? false : true}
                size="Large"
                type="text"
                label={"Address Line 1"}
                onChange={handleInput}
                variant="standard"
                name="address_line1"
                value={profileData?.address_line1}
                required
                fullWidth
                InputProps={{
                  readOnly: false,
                }}
              />
              <FormHelperText
                error={!isRequiredEmpty?.address_line1 ? false : true}
              >
                Required
              </FormHelperText>
            </Box>
          </Box>

          <Box mb={"0.5rem"}>
            <Box>
              <TextField
                error={!isRequiredEmpty?.address_line2 ? false : true}
                size="Large"
                type="text"
                label={"Address Line 2"}
                onChange={handleInput}
                variant="standard"
                value={profileData?.address_line2}
                name="address_line2"
                fullWidth
                InputProps={{
                  readOnly: false,
                }}
              />
              <FormHelperText
                error={!isRequiredEmpty?.address_line2 ? false : true}
              >
                Optional
              </FormHelperText>
            </Box>
          </Box>

          <Box mb={"0.5rem"}>
            <Box>
              <TextField
                error={!isRequiredEmpty?.countryData ? false : true}
                size="small"
                type="text"
                label={"Country"}
                variant="standard"
                name="country"
                onClick={() => setCountryDrawer(true)}
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <ArrowForwardIosOutlined />
                    </InputAdornment>
                  ),
                }}
                required
                fullWidth
                value={countryData?.name || ""}
              />
              <FormHelperText
                error={!isRequiredEmpty?.countryData ? false : true}
              >
                Required
              </FormHelperText>
            </Box>
          </Box>

          {countryData?.states?.length > 0 && (
            <Box mb={"0.5rem"}>
              <Box>
                <TextField
                  error={!isRequiredEmpty?.stateData ? false : true}
                  size="small"
                  type="text"
                  label={"State"}
                  variant="standard"
                  name="state"
                  disabled={countryData?.states?.length === 0}
                  onClick={() => setStateDrawerOpen(true)}
                  InputProps={{
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <ArrowForwardIosOutlined />
                      </InputAdornment>
                    ),
                  }}
                  required={countryData?.states?.length !== 0}
                  fullWidth
                  value={stateData?.name || ""}
                />
                <FormHelperText
                  error={!isRequiredEmpty?.stateData ? false : true}
                >
                  Required
                </FormHelperText>
              </Box>
            </Box>
          )}

          <Box mb={"0.5rem"}>
            <Box>
              <TextField
                error={!isRequiredEmpty?.city ? false : true}
                size="Large"
                type="text"
                label={"City"}
                onChange={handleInput}
                variant="standard"
                value={profileData?.city}
                name="city"
                required
                fullWidth
                InputProps={{
                  readOnly: false,
                }}
              />
              <FormHelperText error={!isRequiredEmpty?.city ? false : true}>
                Required
              </FormHelperText>
            </Box>
          </Box>

          <Box mb={"0.5rem"}>
            <Box>
              <TextField
                error={!isRequiredEmpty?.postal_code ? false : true}
                size="Large"
                type="text"
                label={"Postal Code"}
                onChange={handleInput}
                variant="standard"
                value={profileData?.postal_code}
                name="postal_code"
                required
                fullWidth
                InputProps={{
                  readOnly: false,
                }}
              />
              <FormHelperText>Required</FormHelperText>
            </Box>
          </Box>

          {currentuserdetails?.activeSubscriber?.subscriberPriority ===
            accountType?.Organisation && (
            <>
              {" "}
              <Box mb={"0.5rem"}>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  borderBottom={"1px solid grey"}
                >
                  <Typography color={"rgba(0, 0, 0, 0.6)"} fontSize={"1rem"}>
                    TAX registered
                  </Typography>
                  <IOSSwitch
                    checked={isTaxRequired}
                    onChange={(e) => setTaxRequired(e.target.checked)}
                    sx={{ m: 1 }}
                  />
                </Box>
                {/* <FormHelperText>Required</FormHelperText> */}
              </Box>
              <Box mb={"0.5rem"}>
                <Box>
                  <TextField
                    error={!isRequiredEmpty?.tax_number ? false : true}
                    size="Large"
                    type="text"
                    label={"TAX number"}
                    onChange={handleInput}
                    variant="standard"
                    value={profileData?.tax_number}
                    name="tax_number"
                    required={isTaxRequired}
                    fullWidth
                    InputProps={{
                      readOnly: false,
                    }}
                  />
                  {isTaxRequired && (
                    <FormHelperText
                      error={!isRequiredEmpty?.tax_number ? false : true}
                    >
                      Required
                    </FormHelperText>
                  )}
                </Box>
              </Box>
            </>
          )}
        </form>
      </Box>

      <Box
        position={"absolute"}
        bottom={"1rem"}
        width={"100%"}
        textAlign={"center"}
        mt={"2rem"}
      >
        <Button
          onClick={handleSubmit}
          disabled={isLoading}
          sx={{
            backgroundColor: "#DB0011",
            color: "#fff",
            padding: "0.4rem 2.4rem",
            textTransform: "none",
            borderRadius: "0.15rem",
            ":hover": {
              backgroundColor: "#DB0011",
              color: "#fff",
            },
          }}
          variant="contained"
        >
          {isLoading ? <CircularProgress size={24} color="inherit" /> : "Next"}
        </Button>
      </Box>
    </Box>
  );
};

export default BillingInformationForm;
