import React, { useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import SideBar from "./SideBar";
import MyTabs from "./Tabs";
import OnboardingScreen from "../../common/OnboardingScreen/OnboardingScreen";
import Topics from "./Topics/Topics";
import { approvalsidebarmenu, tasksidebarmenu } from "../../utils/constant";
import AlertPopUp from "../../common/AlertPopUp";
import Sidebar from "../task/Sidebar";
import MarketPlaceSideBar from "../MarketPlace/MarketPlaceSideBar";
import useChatState from "../../customHooks/useChatState";
import { useTranslation } from "react-i18next";
import InternalInvitieTooltip from "../../common/ToolTip/InternalInvitieTooltip";
import ExternalAccountGroupInviteTooltip from "../../common/ToolTip/ExternalAccountGroupInviteTooltip";
import { fetchToken } from "../../config/firebase.config";

const classes = {
  chatComponent: {
    height: "calc(100vh - 4rem)",
    "@media (max-width: 1194px)": {
      height: "69%",
    },
  },
  chatBox1: {
    minWidth: "26%",
    maxWidth: "26%",
    backgroundColor: "#F9F8F8",
    height: "calc(100vh - 4rem)",
    "@media (max-width: 1194px)": {
      height: "69%",
    },
  },
  taskApprovalSidebar: {
    minWidth: "26%",
    maxWidth: "26%",
    backgroundColor: "#F9F8F8",
    height: "calc(100vh - 4rem)",
    "@media (max-width: 1194px)": {
      height: "calc(100vh - 6rem)",
    },
  },
  chatBox2: {
    backgroundColor: "#F9F8F8",
    height: "calc(100vh - 64px)",
    minWidth: "19%",
    maxWidth: "19%",
    "@media (max-width: 1194px)": {
      height: "69%",
    },
  },
};

const Chat = ({
  setisTopicOpen,
  isTopicOpen,
  handleCloseAddPeopleDrawer,
  isAddPeopledarwer,
  setAddpeopledarwer,
  setPricingDrawerOpen,
  groupname,
  setGroupname,
  selectedTaskGroup,
  setSelectedTaskGroup,
  selectedTaskmember,
  setSelectedTaskMember,
  taskApprovalViewValue,
  setTaskApprovalViewValue,
  taskTabValue,
  setTaskTabValue,
  approvalTabValue,
  setApprovalTabValue,
  pubnub,
}) => {
  const {
    isCreategroupOpen,
    setCreategroupopen,
    isRequired,
    currentUserData,
    dmUserDeatils,
    isMoveTopicTriggered,
    setMoveTopicTriggered,
    moveTopicMsgId,
    setMoveTopicMsgId,
    isPermissionModalOpen,
    setPermissionModal,
    newMessages,
    setNewMessages,
    filterChecked,
    setFilterChecked,
    currentHomeIndexTab,
    showSidebar,
    setShowSidebar,
    setAiQueryHistory,
    setShareAiText,
    isShareAiText,
    isAiQueryHistory,
    handlegroupItemclick,
    handlecreategroup,
    handlecloseCreategroup,
    handleApplyApprovalFilter,
    handleApplyTaskFilter,
    handleClearFilter,
    handleCheckedFilter,
    handleNameChange,
    handlesavearkchatgroup,
    handleSelectedTopic,
    newGroupName,
    setNewGroupName,
    isSyncing,
  } = useChatState({
    setisTopicOpen,
    isTopicOpen,
    setGroupname,
    groupname,
    selectedTaskGroup,
    setSelectedTaskGroup,
    selectedTaskmember,
    setSelectedTaskMember,
    taskTabValue,
    approvalTabValue,
  });
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const [isSellerTabLoading, setSellerTabLoading] = useState(false);
  const [isSideBarOpen, setSideBarOpen] = useState(false);
  const endpoint = window.location.pathname;
  useEffect(() => {
    if (currentUserData?.id) {
      fetchToken(currentUserData?.id);
    }
  }, [currentUserData?.id]);
  return (
    <>
      <AlertPopUp
        title={"Access Denied ."}
        bodyContent={appContent?.createGroupAlert}
        open={isPermissionModalOpen}
        closeModal={setPermissionModal}
      />
      {currentUserData &&
        !currentUserData?.tooltipWelcomeJourneyCompletedWeb &&
        currentUserData?.activeSubscriber?.accountOwner !==
          currentUserData?.id &&
        currentUserData?.tooltipFirstInviteScreenWeb === 1 && (
          <>
            <OnboardingScreen />
            <InternalInvitieTooltip />
          </>
        )}

      {currentUserData &&
        !currentUserData?.tooltipWelcomeJourneyCompletedWeb &&
        currentUserData?.tooltipFirstInviteScreenWeb === 0 && (
          <>
            <OnboardingScreen />
          </>
        )}

      {currentUserData &&
        !currentUserData?.tooltipWelcomeJourneyCompletedWeb &&
        currentUserData?.tooltipFirstInviteScreenWeb === 2 &&
        !currentUserData?.isFirstGroupCreated && (
          <>
            <OnboardingScreen />
            <ExternalAccountGroupInviteTooltip />
          </>
        )}
      <Box height={"100%"}>
        <Stack height={"100%"} direction="row" spacing={1}>
          {currentHomeIndexTab === 0 && (
            <>
              <Box sx={classes.chatBox1}>
                <SideBar
                  handlecreategroup={handlecreategroup}
                  handlegroupItemclick={handlegroupItemclick}
                />
              </Box>
              {isTopicOpen && (
                <Box sx={classes.chatBox2}>
                  <Topics
                    setisTopicOpen={setisTopicOpen}
                    handleSelectedTopic={handleSelectedTopic}
                    setMoveTopicTriggered={setMoveTopicTriggered}
                    isMoveTopicTriggered={isMoveTopicTriggered}
                    moveTopicMsgId={moveTopicMsgId}
                    setMoveTopicMsgId={setMoveTopicMsgId}
                  />
                </Box>
              )}
            </>
          )}

          {currentHomeIndexTab === 1 && showSidebar && isSideBarOpen && (
            <>
              <Box sx={classes.taskApprovalSidebar}>
                <Sidebar
                  setSideBarOpen={setSideBarOpen}
                  handleCheckedFilter={handleCheckedFilter}
                  filterChecked={filterChecked}
                  sidebarmenudata={tasksidebarmenu}
                  handleClearFilter={handleClearFilter}
                  handleApplyFilter={handleApplyTaskFilter}
                  currentHomeIndexTab={currentHomeIndexTab}
                />
              </Box>
            </>
          )}

          {currentHomeIndexTab === 2 && showSidebar && isSideBarOpen && (
            <>
              <Box sx={classes.taskApprovalSidebar}>
                <Sidebar
                  setSideBarOpen={setSideBarOpen}
                  handleCheckedFilter={handleCheckedFilter}
                  filterChecked={filterChecked}
                  sidebarmenudata={approvalsidebarmenu}
                  handleClearFilter={handleClearFilter}
                  handleApplyFilter={handleApplyApprovalFilter}
                  currentHomeIndexTab={currentHomeIndexTab}
                />
              </Box>
            </>
          )}

          {currentHomeIndexTab === 3 && isSideBarOpen && (
            <>
              <Box sx={classes.chatBox1}>
                <MarketPlaceSideBar
                  setSideBarOpen={setSideBarOpen}
                  setSellerTabLoading={setSellerTabLoading}
                  isSellerTabLoading={isSellerTabLoading}
                />
              </Box>
            </>
          )}

          <Box
            sx={classes?.chatComponent}
            // height={"100%"}
            maxWidth={
              endpoint === "/chat" && currentHomeIndexTab === 0 && !isTopicOpen
                ? "77.4%"
                : endpoint === "/chat" && currentHomeIndexTab !== 0
                ? "100%"
                : "57.8%"
            }
            flex={5.2}
          >
            <MyTabs
              setSellerTabLoading={setSellerTabLoading}
              isSellerTabLoading={isSellerTabLoading}
              setisTopicOpen={setisTopicOpen}
              isTopicOpen={isTopicOpen}
              isCreategroupOpen={isCreategroupOpen}
              handleClosegroupdrawer={handlecloseCreategroup}
              handlesavearkchatgroup={handlesavearkchatgroup}
              groupname={groupname}
              setGroupname={setGroupname}
              newGroupName={newGroupName}
              setNewGroupName={setNewGroupName}
              isRequired={isRequired}
              handleNameChange={handleNameChange}
              dmUserDeatils={dmUserDeatils}
              isMoveTopicTriggered={isMoveTopicTriggered}
              setMoveTopicTriggered={setMoveTopicTriggered}
              setMoveTopicMsgId={setMoveTopicMsgId}
              setNewMessages={setNewMessages}
              newMessages={newMessages}
              isSyncing={isSyncing}
              pubnub={pubnub}
              setShowSidebar={setShowSidebar}
              filterChecked={filterChecked}
              setFilterChecked={setFilterChecked}
              isAiQueryHistory={isAiQueryHistory}
              setAiQueryHistory={setAiQueryHistory}
              setShareAiText={setShareAiText}
              isShareAiText={isShareAiText}
              setcreategroupopen={setCreategroupopen}
              setAddpeopledarwer={setAddpeopledarwer}
              setPricingDrawerOpen={setPricingDrawerOpen}
              isAddPeopledarwer={isAddPeopledarwer}
              handleCloseAddPeopleDrawer={handleCloseAddPeopleDrawer}
              selectedTaskGroup={selectedTaskGroup}
              setSelectedTaskGroup={setSelectedTaskGroup}
              selectedTaskmember={selectedTaskmember}
              setSelectedTaskMember={setSelectedTaskMember}
              taskApprovalViewValue={taskApprovalViewValue}
              setTaskApprovalViewValue={setTaskApprovalViewValue}
              taskTabValue={taskTabValue}
              setTaskTabValue={setTaskTabValue}
              approvalTabValue={approvalTabValue}
              setApprovalTabValue={setApprovalTabValue}
              isSideBarOpen={isSideBarOpen}
              setSideBarOpen={setSideBarOpen}
              handleApplyApprovalFilter={handleApplyApprovalFilter}
              handleApplyTaskFilter={handleApplyTaskFilter}
            />
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default Chat;
