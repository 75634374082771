import defaultFile_icon from "../assets/images/mediafiles/default_icon.svg";
import imageFile_logo from "../assets/images/mediafiles/image.svg";
import youtubeFile_logo from "../assets/images/mediafiles/youtubeFile_logo.svg";
import powerpointFile_logo from "../assets/images/mediafiles/powerpointFile_logo.svg";
import pdfFile_logo from "../assets/images/mediafiles/pdf_icon.svg";
import mswordFile_logo from "../assets/images/mediafiles/mswordFile_logo.svg";
import excelFile_logo from "../assets/images/mediafiles/msExcel_.svg";
import googleFile_logo from "../assets/images/mediafiles/googleFile_logo.svg";

export function getFileIcons(extension) {
  switch (extension) {
    case "pdf":
      return pdfFile_logo;
    case "doc":
    case "docx":
      return mswordFile_logo;
    case "txt":
      return defaultFile_icon;
    case "xls":
    case "xlsx":
      return excelFile_logo;
    case "ppt":
    case "pptx":
      return powerpointFile_logo;
    case "zip":
    case "rar":
    case "7z":
    case "tar":
    case "gz":
    case "eps":
    case "ai":
    case "psd":
    case "ico":
    case "svg":
    case "webp":
    case "gif":
    case "tiff":
    case "jpg":
    case "jpeg":
    case "png":
    case "dart":
      return imageFile_logo;
    default:
      return "unknown"; // You can define a default icon for unknown file types
  }
}

function identifyGoogleOrYouTube(url) {
  // Regular expressions to match YouTube URLs
  const youtubeRegex = /https:\/\/(www\.)?youtube\.com/;

  // Check for YouTube link
  if (youtubeRegex.test(url)) {
    return youtubeFile_logo;
  }
  // Check if it's a .com link (including any .com like Google, oscommerce, etc.)
  else if (url.includes(".com")) {
    return googleFile_logo; // Treat any .com as Google-like
  }
  // Default case for non .com links
  else {
    return defaultFile_icon;
  }
}

export function getFileIconUrl(fileUrl) {
  if (fileUrl) {
    const fileExtension = fileTypeCheck(fileUrl);
    if (fileExtension) {
      // Check if the file is an audio file
      if (
        fileExtension === "mp3" ||
        fileExtension === "m4a" ||
        fileExtension === "wav" ||
        fileExtension === "ogg"
      ) {
        return "audio"; // Return 'audio' as an identifier for audio files
      }
      const icon = getFileIcons(fileExtension);
      if (icon === "unknown") {
        const updatedIcon = identifyGoogleOrYouTube(fileUrl);
        return updatedIcon;
      }

      return icon;
    }
  }
  return defaultFile_icon;
}

function fileTypeCheck(fileUrl) {
  const fileExtension = fileUrl.split(".").pop();
  return fileExtension;
}

export function isGoogleYoutubeUrl(fileUrl) {
  const fileExtension = fileUrl.split(".").pop();
  if (fileExtension.includes("com")) {
    return true;
  }

  return false;
}


