export const GET_ALL_PLANS = "GET_ALL_PLANS";
export const GET_ALL_SUBSCRIBED_USERS = "GET_ALL_SUBSCRIBED_USERS";
export const SAVE_PLAN_DATA = "SAVE_PLAN_DATA";
export const SAVE_BILLING_FORM_DATA = "SAVE_BILLING_FORM_DATA";
export const CONFIRM_COUPON_VALIDITY = "CONFIRM_COUPON_VALIDITY";
export const PAYMENT_INTENT_DATA = "PAYMENT_INTENT_DATA";
export const BILLING_RESPONSE_DATA = "BILLING_RESPONSE_DATA";
export const SHOW_LOADING = "SHOW_LOADING";
export const BILLING_ADDRESS = "BILLING_ADDRESS";
export const SUBSCRIPTION_DETAILS="SUBSCRIPTION_DETAILS";
export const MANAGE_SUBSCRIPTION_DATA="MANAGE_SUBSCRIPTION_DATA";
