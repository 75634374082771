import React, { useEffect, useState } from "react";
import { Box, Button, Drawer, Typography } from "@mui/material";
import right_arrow_icon from "../../../assets/images/subscription/right_arrow_icon.svg";
import UpdateBillingAddress from "./UpdateBillingAddress";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllSubscribedUsers,
  getBillingAddress,
  getSubscriptionDetail,
} from "../../../appStore/actions/Subscription/action";
import { MANAGE_SUBSCRIPTION_DATA } from "../../../appStore/actions/Subscription/type";
import CancelSubscription from "./CancelSubscription";
import AddManageSinglePlanMember from "../Subscription/AddManageSinglePlanMember";
import useAppSelectors from "../../../customHooks/useAppSelectors";

// GET https://apptest.arkchat.com/v2/billing/subscribed-users

// POST: https://apptest.arkchat.com/v2/billing/update-subscription
// {
//     "users": [{ "user_id": 53, "plan_type": 1 }, { "user_id": 54, "plan_type": 2 }]
// }
const ManageSubscriptionHome = ({ setToggelManageMemberDrawerOpen }) => {
  const [isManageBillingAddressOpen, setBillingAddressOpen] = useState(false);
  const [isCheckboxManageMemberDrawerOpen, setCheckboxManageMemberDrawerOpen] =
    useState(false);
  const [isCancleSubscriptionOpen, setCancleSubscriptionOpen] = useState(false);
  const dispatch = useDispatch();
  const { currentUserData, subscriptionDetails, allPlansList } =
    useAppSelectors();

  const handleOpenManageMemeberDrawer = () => {
    const purchasedPlans = allPlansList?.plans?.filter(
      (plan) => plan.purchased === true
    );
    if (purchasedPlans.length > 1) {
      setToggelManageMemberDrawerOpen(true);
    } else {
      setCheckboxManageMemberDrawerOpen(true);
    }
  };

  // const handleManageClick = (data) => {
  //   dispatch({
  //     type: MANAGE_SUBSCRIPTION_DATA,
  //     payload: data,
  //   });
  //   setCheckboxManageMemberDrawerOpen(true);
  // };
  const handleCancleClick = (data) => {
    dispatch({
      type: MANAGE_SUBSCRIPTION_DATA,
      payload: data,
    });
    setCancleSubscriptionOpen(true);
  };

  useEffect(() => {
    dispatch(getSubscriptionDetail(currentUserData?.activeSubscriber?.id));
    dispatch(getBillingAddress());
    // dispatch(getAllSubscribedUsers());
  }, []);
 
  return (
    <>
      <Drawer
        sx={{ zIndex: 1202 }}
        open={isManageBillingAddressOpen}
        anchor={"right"}
      >
        <UpdateBillingAddress setBillingAddressOpen={setBillingAddressOpen} />
      </Drawer>
      <Drawer
        sx={{ zIndex: 1202 }}
        open={isCancleSubscriptionOpen}
        anchor={"right"}
      >
        <CancelSubscription
          setCancleSubscriptionOpen={setCancleSubscriptionOpen}
        />
      </Drawer>

      <Drawer
        sx={{ zIndex: 1202 }}
        open={isCheckboxManageMemberDrawerOpen}
        anchor={"right"}
      >
        <AddManageSinglePlanMember
          setCheckboxManageMemberDrawerOpen={setCheckboxManageMemberDrawerOpen}
          drawerType={"manage"}
        />
      </Drawer>
      <Box
        overflow={"scroll"}
        height={"calc(100vh - 8rem)"}
        sx={{
          "&::-webkit-scrollbar": {
            width: "1px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#F9F8F8",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "grey",
          },
          // "@media (max-width: 1194px)": {
          //   height: "calc(100vh - 20rem)",
          // },
        }}
      >
        <Box width={"95%"}>
          {subscriptionDetails?.length > 0 &&
            subscriptionDetails?.map((item, i) => (
              <Box
                borderRadius={"2px"}
                bgcolor={"#F3F3F3"}
                padding={"0.5rem 0rem"}
                boxShadow="0px 1px 4px 0px #00000040"
                mb={"0.8rem"}
                key={i + "subscription"}
              >
                <Box width={"90%"} margin={"auto"} textAlign={"center"}>
                  <Typography
                    variant="h6"
                    fontSize={"1rem"}
                    color={"#183243"}
                    fontWeight={600}
                  >
                    {item?.name}
                  </Typography>
                  <Box mt={"0.5rem"}>
                    <Typography
                      fontWeight={600}
                      variant="caption"
                      fontSize={"1rem"}
                    >
                      {item?.symbol} {item?.price}
                    </Typography>
                    <Typography variant="caption" display={"block"}>
                      {/* for {item?.quantity} person/month */}
                    </Typography>
                    <Typography
                      fontWeight={600}
                      variant="caption"
                      fontSize={"0.8rem"}
                      display={"block"}
                    >
                      Billing cycle : {item?.billingCycle}
                    </Typography>
                  </Box>
                </Box>
                {/* planType = 0 freeTrails, 1 monthly , 2 yearly  */}
                {item?.planType !== 0 && (
                  <Box
                    width={"90%"}
                    margin={"auto"}
                    textAlign={"center"}
                    mt={"0.5rem"}
                  >
                    <Box
                      display={"flex"}
                      gap={"0.4rem"}
                      justifyContent={"center"}
                    >
                      <Button
                        onClick={() => handleCancleClick(item)}
                        sx={{
                          backgroundColor: "transparent",
                          color: "#183243",
                          padding: "0.2rem 0.4rem",
                          textTransform: "none",
                          borderRadius: "0.15rem",
                          border: "1px solid #DB0011",
                          ":hover": {
                            backgroundColor: "transparent",
                            color: "#183243",
                          },
                        }}
                        variant="contained"
                      >
                        Cancel
                      </Button>
                      <Button
                        disabled
                        sx={{
                          backgroundColor: "#DB0011",
                          color: "#fff",
                          padding: "0.2rem 0.4rem",
                          textTransform: "none",
                          borderRadius: "0.15rem",
                          ":hover": {
                            backgroundColor: "#DB0011",
                            color: "#fff",
                          },
                        }}
                        variant="contained"
                      >
                        Renew
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
            ))}
        </Box>
        {subscriptionDetails?.length > 0 &&
          subscriptionDetails[0]?.planType !== 0 && (
            <Box width={"95%"}>
              <Box
                onClick={() => handleOpenManageMemeberDrawer()}
                sx={{ cursor: "pointer" }}
                padding={"0.8rem 0.8rem 0.8rem 0.5rem"}
                bgcolor={"#F3F3F3"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Typography>Manage users</Typography>
                <Box>
                  <img src={right_arrow_icon} alt="icon" />
                </Box>
              </Box>

              {/* <Box
            sx={{ cursor: "pointer" }}
            padding={"0.8rem 0.8rem 0.8rem 0.5rem"}
            bgcolor={"#F3F3F3"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Typography>Payment History</Typography>
            <Box>
              <img src={right_arrow_icon} alt="icon" />
            </Box>
          </Box> */}

              <Box
                sx={{ cursor: "pointer" }}
                mt={"0.6rem"}
                mb={"0.6rem"}
                padding={"0.8rem 0.8rem 0.8rem 0.5rem"}
                bgcolor={"#F3F3F3"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
                onClick={() => setBillingAddressOpen(true)}
              >
                <Typography>Manage billing information</Typography>
                <Box>
                  <img src={right_arrow_icon} alt="icon" />
                </Box>
              </Box>
            </Box>
          )}
      </Box>
    </>
  );
};

export default ManageSubscriptionHome;
