import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleFetchPostList } from "../../../appStore/actions/ArkchatGroup/action";
import { Box } from "@mui/material";
import ExploreView from "../ExploreView";
import PostCard from "../../post/commonComponent/PostCard/PostCard";
import { serviceTypeName, serviceTypeValue } from "../../../utils/constant";
import LoadingSpinner from "../../../common/LoadingSpinner/LoadingSpinner";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
const PhotosPost = ({
  handleBookmarkClick,
  handleLikeClick,
  isBookMarkedPost,
  filterSwitchLoading,
  handleEditClick,
  isSideBarOpen,
}) => {
  const exploreDataList = useSelector(
    (state) => state?.arkchatgroup?.exploreDataList
  );
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const FilterSwitch = localStorage.getItem("FilterSwitch");
    // if (!exploreDataList?.posts?.length > 0) {
    //   setLoading(true);
    // }
    setLoading(true);
    if (!FilterSwitch) {
      dispatch(
        handleFetchPostList({
          page: 1, 
          type: serviceTypeName?.Photo,
        })
      ).then((res) => {
        setLoading(false);
      });
    }
    if (FilterSwitch) {
      setLoading(false);
    }
  }, [dispatch]);
  return (
    <Box>
      {isLoading || filterSwitchLoading ? (
        <Box>
          <LoadingSpinner />
        </Box>
      ) : (
        <>
          {exploreDataList?.posts?.length > 0 ? (
            <>
              <Box>
                <ResponsiveMasonry
                  columnsCountBreakPoints={{
                    1194: !isSideBarOpen ? 3 : 2,
                    1195: !isSideBarOpen ? 4 : 3,
                  }}
                >
                  <Masonry gutter="0.5rem">
                    {exploreDataList?.posts?.length > 0 &&
                      exploreDataList?.posts
                        ?.filter(
                          (data) =>
                            !isBookMarkedPost || data?.isBookmarked === true
                        )
                        .map((data, i) => (
                          <Box width={"100%"} key={i + "photosPost"}>
                            <PostCard
                              type={serviceTypeValue[data?.type]}
                              postPreviewData={data}
                              showBtn={false}
                              handleBookmarkClick={handleBookmarkClick}
                              handleLikeClick={handleLikeClick}
                              handleEditClick={handleEditClick}
                              showConnect={true}
                            />
                          </Box>
                        ))}
                  </Masonry>
                </ResponsiveMasonry>
              </Box>
            </>
          ) : (
            <ExploreView type={serviceTypeName?.Photo} />
          )}
          <>
            {isBookMarkedPost && (
              <>
                {!exploreDataList?.posts?.some(
                  (data) => !isBookMarkedPost || data?.isBookmarked === true
                ) && <ExploreView type={serviceTypeName?.Photo} />}
              </>
            )}
          </>
        </>
      )}
    </Box>
  );
};

export default PhotosPost;
