import { createSelector } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

// Base selectors
const subscriberSelector = (state) => state.subscriber;
const profileSelector = (state) => state.profile;
const appStateDataSelector = (state) => state.appStateData;
const localAppReducerSelector = (state) => state.localAppReducer;
const arkchatgroupSelector = (state) => state.arkchatgroup;
const subscriptionSelector = (state) => state.subscription;
const countrySelector = (state) => state.country;
const globalSelector = (state) => state.global;
const languagesSelector = (state) => state.languages;
const topicSelector = (state) => state.topic;
const notificationSelector = (state) => state.notification;
const serviceCategorySelector = (state) => state.serviceCategory;
const industrySelector = (state) => state.industry;

// Profile selectors
export const shouldRedirectSelector = createSelector(
  profileSelector,
  (profile) => profile?.shouldRedirect
);

export const currentUserDataSelector = createSelector(
  profileSelector,
  (profile) => profile?.profileData?.data
);

// export const currentUserDataSelector = createSelector(
//   profileSelector,
//   (profile) => profile
// );

export const userEmailSelector = createSelector(
  profileSelector,
  (profile) => profile?.emailStore?.data
);

// AppStateData selectors
export const selectedGroupSelector = createSelector(
  appStateDataSelector,
  (appStateData) => appStateData?.selectedGroupData
);

export const serviceTypeSelector = createSelector(
  appStateDataSelector,
  (appStateData) => appStateData?.serviceType
);

export const showStarMessageSelector = createSelector(
  appStateDataSelector,
  (appStateData) => appStateData?.showStarMessage
);

export const isAiScreenActiveSelector = createSelector(
  appStateDataSelector,
  (appStateData) => appStateData?.isAiScreenActive
);

export const isThreadScrollToBottomSelector = createSelector(
  appStateDataSelector,
  (appStateData) => appStateData?.isThreadScrollToBottom
);

export const selectedTopicSelector = createSelector(
  appStateDataSelector,
  (appStateData) => appStateData?.selectedTopicData
);

export const switchAccountRefreshSelector = createSelector(
  appStateDataSelector,
  (appStateData) => appStateData?.isSwitchAccountRefresh
);

export const toggleSetPubTokenStateSelector = createSelector(
  appStateDataSelector,
  (appStateData) => appStateData?.toggleSetPubTokenState
);

export const currentHomeIndexTabSelector = createSelector(
  appStateDataSelector,
  (appStateData) => appStateData?.currentHomeIndexTab
);

export const selectedTopicToSaveMsgSelector = createSelector(
  appStateDataSelector,
  (appStateData) => appStateData?.selectedTopicToSaveMsg
);

export const isChangeTopicOpenSelector = createSelector(
  appStateDataSelector,
  (appStateData) => appStateData?.isChangeTopicOpen
);

export const postEditorPreviewDataSelector = createSelector(
  appStateDataSelector,
  (appStateData) => appStateData?.postEditPreviewData
);

// LocalAppReducer selectors
export const isThreadScreenSelector = createSelector(
  localAppReducerSelector,
  (localAppReducer) => localAppReducer?.isThreadScreen
);

export const isLoadingSelector = createSelector(
  localAppReducerSelector,
  (localAppReducer) => localAppReducer?.isLoading
);

export const shouldCallPubnubGetTokenSelector = createSelector(
  localAppReducerSelector,
  (localAppReducer) => localAppReducer?.shouldCallPubnubGetToken
);

// Subscriber selectors
export const invitationRetrivedDataSelector = createSelector(
  subscriberSelector,
  (subscriber) => subscriber?.invitationRetrivedData
);

export const inviteDataSelector = createSelector(
  subscriberSelector,
  (subscriber) => subscriber
);

export const allPendingInvitationDataSelector = createSelector(
  subscriberSelector,
  (subscriber) => subscriber?.allPendingInvitationData
);

// Arkchatgroup selectors
export const firstGroupDataSelector = createSelector(
  arkchatgroupSelector,
  (arkchatgroup) => arkchatgroup?.firstGroup
);

export const accountLevelPeopleSelector = createSelector(
  arkchatgroupSelector,
  (arkchatgroup) => arkchatgroup?.accountLevelPeople
);

export const groupListSelector = createSelector(
  arkchatgroupSelector,
  (arkchatgroup) => arkchatgroup?.groupList
);

export const messageListSelector = createSelector(
  arkchatgroupSelector,
  (arkchatgroup) => arkchatgroup?.messageList
);

export const groupmemberdetailsSelector = createSelector(
  arkchatgroupSelector,
  (arkchatgroup) => arkchatgroup?.groupmember
);

export const groupRecipientDetailsSelector = createSelector(
  arkchatgroupSelector,
  (arkchatgroup) => arkchatgroup?.groupRecipient
);

export const aiHistoryDataSelector = createSelector(
  arkchatgroupSelector,
  (arkchatgroup) => arkchatgroup?.aiHistoryData
);

export const aiQuerySessionSelector = createSelector(
  arkchatgroupSelector,
  (arkchatgroup) => arkchatgroup?.aiQuerySession
);

export const approvalAnalyticsDataSelector = createSelector(
  arkchatgroupSelector,
  (arkchatgroup) => arkchatgroup?.approvalAnalyticsData
);

export const storeApprovallistSelector = createSelector(
  arkchatgroupSelector,
  (arkchatgroup) => arkchatgroup?.approvalList
);

export const cloneGroupListSelector = createSelector(
  arkchatgroupSelector,
  (arkchatgroup) => arkchatgroup?.cloneGroupList
);

export const storeTasklistSelector = createSelector(
  arkchatgroupSelector,
  (arkchatgroup) => arkchatgroup?.taskList
);

export const taskAnalyticsDataSelector = createSelector(
  arkchatgroupSelector,
  (arkchatgroup) => arkchatgroup?.taskAnalyticsData
);

export const exploreDataListSelector = createSelector(
  arkchatgroupSelector,
  (arkchatgroup) => arkchatgroup?.exploreDataList
);

export const likeUserListSelector = createSelector(
  arkchatgroupSelector,
  (arkchatgroup) => arkchatgroup?.likeUserList
);

export const artistListSelector = createSelector(
  arkchatgroupSelector,
  (arkchatgroup) => arkchatgroup?.artistDataList?.data
);

export const brandListSelector = createSelector(
  arkchatgroupSelector,
  (arkchatgroup) => arkchatgroup?.brandDataList?.data
);

export const postDataListSelector = createSelector(
  arkchatgroupSelector,
  (arkchatgroup) => arkchatgroup?.postDataList
);

export const postPreviewDataSelector = createSelector(
  arkchatgroupSelector,
  (arkchatgroup) => arkchatgroup?.postPreviewData
);

export const sellerPostListSelector = createSelector(
  arkchatgroupSelector,
  (arkchatgroup) => arkchatgroup?.sellerPostList
);

export const tempMsgListSelector = createSelector(
  arkchatgroupSelector,
  (arkchatgroup) => arkchatgroup?.tempMessageList
);

export const starMsgDataSelector = createSelector(
  arkchatgroupSelector,
  (arkchatgroup) => arkchatgroup?.starMsgData
);

export const threadMsgListSelector = createSelector(
  arkchatgroupSelector,
  (arkchatgroup) => arkchatgroup?.threadMsgList
);

export const groupLevelPeopleSelector = createSelector(
  arkchatgroupSelector,
  (arkchatgroup) => arkchatgroup?.groupmemberWithPermission
);

// Subscription selectors
export const paymentIntentDataSelector = createSelector(
  subscriptionSelector,
  (subscription) => subscription?.paymentIntentData
);

export const selectedManageSubscriptionDataSelector = createSelector(
  subscriptionSelector,
  (subscription) => subscription?.selectedManageSubscriptionData
);

export const subscriptionDetailsSelector = createSelector(
  subscriptionSelector,
  (subscription) => subscription?.subscriptionDetails
);

export const billingAddressSelector = createSelector(
  subscriptionSelector,
  (subscription) => subscription?.billingAddress
);

export const allPlansListSelector = createSelector(
  subscriptionSelector,
  (subscription) => subscription?.allPlans
);

export const billingDataSelector = createSelector(
  subscriptionSelector,
  (subscription) => subscription?.billingData
);

export const selectedPlanDataSelector = createSelector(
  subscriptionSelector,
  (subscription) => subscription?.selectedPlanData
);

export const allSubscribedUsersSelector = createSelector(
  subscriptionSelector,
  (subscription) => subscription?.allSubscribedUsers
);

export const billingResponseDataSelector = createSelector(
  subscriptionSelector,
  (subscription) => subscription?.billingResponseData
);

export const couponDataSelector = createSelector(
  subscriptionSelector,
  (subscription) => subscription?.couponData
);

// Country selectors
export const countryMasterDataSelector = createSelector(
  countrySelector,
  (country) => country
);

// Global selectors
export const tostmsgDataSelector = createSelector(
  globalSelector,
  (global) => global?.tostmsgData
);

export const isShowInternetToastSelector = createSelector(
  globalSelector,
  (global) => global?.isShowInternetToast
);

export const showToastSelector = createSelector(
  globalSelector,
  (global) => global?.isToastOpen
);

export const indicatorDataSelector = createSelector(
  globalSelector,
  (global) => global?.indicatorData
);

// Languages selectors
export const languageListDataSelector = createSelector(
  languagesSelector,
  (languages) => languages
);

// Topic selectors
export const groupTopicDataSelector = createSelector(
  topicSelector,
  (topic) => topic?.groupTopicData
);

// Notification selectors
export const allNotificationDataSelector = createSelector(
  notificationSelector,
  (notification) => notification?.allNotificationData
);

// Service Category selectors
export const artServiceCategoryDataSelector = createSelector(
  serviceCategorySelector,
  (serviceCategory) => serviceCategory?.artServiceCategoryData
);

export const photoServiceCategoryDataSelector = createSelector(
  serviceCategorySelector,
  (serviceCategory) => serviceCategory?.photoServiceCategoryData
);

export const productServiceCategoryDataSelector = createSelector(
  serviceCategorySelector,
  (serviceCategory) => serviceCategory?.productServiceCategoryData
);

export const serviceCategoryDataSelector = createSelector(
  serviceCategorySelector,
  (serviceCategory) => serviceCategory?.serviceCategoryData
);

export const videoServiceCategoryDataSelector = createSelector(
  serviceCategorySelector,
  (serviceCategory) => serviceCategory?.videoServiceCategoryData
);

// Industry selectors
export const industryDataSelector = createSelector(
  industrySelector,
  (industry) => industry
);

// Custom hook using memoized selectors
const useAppSelectors = () => {
  // Profile selectors
  const currentUserData = useSelector(currentUserDataSelector);
  const shouldRedirect = useSelector(shouldRedirectSelector);
  const user = useSelector(userEmailSelector);

  // AppStateData selectors
  const selectedGroup = useSelector(selectedGroupSelector);
  const selectedTopicToSaveMsg = useSelector(selectedTopicToSaveMsgSelector);
  const showStarMessage = useSelector(showStarMessageSelector);
  const accountLevelPeople = useSelector(accountLevelPeopleSelector);
  const currentHomeIndexTab = useSelector(currentHomeIndexTabSelector);
  const selectedTopic = useSelector(selectedTopicSelector);
  const isThreadScreen = useSelector(isThreadScreenSelector);
  const threadMsgList = useSelector(threadMsgListSelector);
  const postServiceTypeValue = useSelector(serviceTypeSelector);
  const isAiScreenActive = useSelector(isAiScreenActiveSelector);
  // // Arkchatgroup selectors
  const groupList = useSelector(groupListSelector);
  const groupmemberdetails = useSelector(groupmemberdetailsSelector);
  const groupRecipientDetails = useSelector(groupRecipientDetailsSelector);

  // Subscription Related
  const allPlansList = useSelector(allPlansListSelector);
  const selectedManageSubscriptionData = useSelector(selectedManageSubscriptionDataSelector);
  const selectedPlanData = useSelector(selectedPlanDataSelector);
  const subscriptionDetails = useSelector(subscriptionDetailsSelector);
  const billingData = useSelector(billingDataSelector);
  const allSubscribedUsers = useSelector(allSubscribedUsersSelector) 

  return {
    currentUserData,
    shouldRedirect,
    user,
    accountLevelPeople,
    selectedGroup,
    selectedTopic,
    selectedTopicToSaveMsg,
    isThreadScreen,
    showStarMessage,
    currentHomeIndexTab,
    threadMsgList,
    isAiScreenActive,
    groupList,
    groupmemberdetails,
    groupRecipientDetails,
    postServiceTypeValue,
    allPlansList,
    selectedManageSubscriptionData,
    billingData,
    subscriptionDetails,
    selectedPlanData,
    allSubscribedUsers
  };
};

export default useAppSelectors;

// const invitationRetrivedData = useSelector(
//     (state) => state?.subscriber?.invitationRetrivedData
//   );
//   const userData = useSelector((state) => state?.profile);
//   const shouldRedirect = useSelector((state) => state?.profile?.shouldRedirect);
//   const userData = useSelector((state) => state?.profile?.profileData?.data);
//   const selectedGroup = useSelector(
//     (state) => state?.appStateData?.selectedGroupData
//   );
//   const showStarMessage = useSelector(
//     (state) => state?.appStateData?.showStarMessage
//   );
//   const isAiScreenActive = useSelector(
//     isAiScreenActiveSelector
//   );
// const isThreadScreen = useSelector(
//   (state) => state?.localAppReducer?.isThreadScreen
// );
//   const userData = useSelector((state) => state?.profile?.profileData?.data);
//   const userData = useSelector((state) => state?.profile?.profileData?.data);
//   const inviteData = useSelector((state) => state?.subscriber);
//   const firstGroupData = useSelector(
//     (state) => state?.arkchatgroup?.firstGroup
//   );
//   const allPendingInvitationData = useSelector(
//     (state) => state?.subscriber?.allPendingInvitationData
//   );
//   const accountLevelPeople = useSelector(
//     (state) => state?.arkchatgroup?.accountLevelPeople
//   );
//   const currentUserData = useSelector((state) => state?.profile);
//   const accountLevelPeople = useSelector(
//     (state) => state?.arkchatgroup?.accountLevelPeople
//   );
//   const currentUserData = useSelector((state) => state?.profile);
//   const allPendingInvitationData = useSelector(
//     (state) => state?.subscriber?.allPendingInvitationData
//   );
//   const paymentIntentData = useSelector(
//     (state) => state?.subscription?.paymentIntentData
//   );
//   const selectedManageSubscriptionData = useSelector(
//     (state) => state?.subscription?.selectedManageSubscriptionData
//   );
//   const selectedManageSubscriptionData = useSelector(
//     (state) => state?.subscription?.selectedManageSubscriptionData
//   );
//   const accountLevelPeople = useSelector(
//     (state) => state?.arkchatgroup?.accountLevelPeople
//   );
//   const userData = useSelector((state) => state?.profile?.profileData);
//   const currentuserdetails = useSelector(
//     (state) => state?.profile?.profileData?.data
//   );
//   const subscriptionDetails = useSelector(
//     (state) => state?.subscription?.subscriptionDetails
//   );
//   const currentuserdetails = useSelector(
//     (state) => state?.profile?.profileData?.data
//   );
//   const billingAddress = useSelector(
//     (state) => state?.subscription?.billingAddress
//   );
//   const countryMasterData = useSelector((state) => state?.country);
//   const allPlansList = useSelector((state) => state?.subscription?.allPlans);
//   const currentuserdetails = useSelector(
//     (state) => state?.profile?.profileData?.data
//   );
//   const billingData = useSelector((state) => state?.subscription?.billingData);
//   const billingAddress = useSelector(
//     (state) => state?.subscription?.billingAddress
//   );
//   const countryMasterData = useSelector((state) => state?.country);
//   const selectedPlanData = useSelector(
//     (state) => state?.subscription?.selectedPlanData
//   );
//   const billingData = useSelector((state) => state?.subscription?.billingData);
//   const accountLevelPeople = useSelector(
//     (state) => state?.arkchatgroup?.accountLevelPeople
//   );
//   const userData = useSelector((state) => state?.profile?.profileData);
//   const paymentIntentData = useSelector(
//     (state) => state?.subscription?.paymentIntentData
//   );
//   const allActiveMembers = useSelector(
//     (state) => state?.subscription?.allSubscribedUsers
//   );
//   const selectedPlanData = useSelector(
//     (state) => state?.subscription?.selectedPlanData
//   );
//   const billingResponseData = useSelector(
//     (state) => state?.subscription?.billingResponseData
//   );
//   const billingData = useSelector((state) => state?.subscription?.billingData);
//   const couponData = useSelector((state) => state?.subscription?.couponData);
//   const userData = useSelector((state) => state?.profile?.profileData?.data);
//   const selectedGroup = useSelector(
//     (state) => state?.appStateData?.selectedGroupData
//   );
//   const isThreadScreen = useSelector(
//     (state) => state?.localAppReducer?.isThreadScreen
//   );
//   const isThreadScrollToBottom = useSelector(
//     (state) => state?.appStateData?.isThreadScrollToBottom
//   );
//   const groupList = useSelector((state) => state?.arkchatgroup?.groupList);
//   const accountLevelPeople = useSelector(
//     (state) => state?.arkchatgroup?.accountLevelPeople
//   );
//   const selectedTopic = useSelector(
//     (state) => state?.appStateData?.selectedTopicData
//   );
//   const messageList = useSelector((state) => state?.arkchatgroup?.messageList);
//   const dispatch = useDispatch();
//   const tostmsgData = useSelector((state) => state?.global?.tostmsgData);
//   const isShowInternetToast = useSelector(
//     (state) => state?.global?.isShowInternetToast
//   );
//   const showToast = useSelector((state) => state?.global?.isToastOpen);
//   const switchAccountRefresh = useSelector(
//     (state) => state?.appStateData?.isSwitchAccountRefresh
//   );
//   const shouldCallPubnubGetToken = useSelector(
//     (state) => state?.localAppReducer?.shouldCallPubnubGetToken
//   );
//   const toggleSetPubTokenState = useSelector(
//     (state) => state?.appStateData?.toggleSetPubTokenState
//   );
//   const selectedGroup = useSelector(
//     (state) => state?.appStateData?.selectedGroupData
//   );
//   const switchAccountRefresh = useSelector(
//     (state) => state?.appStateData?.isSwitchAccountRefresh
//   );
//   const userdetails = useSelector((state) => state?.profile?.profileData);
//   const accountLevelPeople = useSelector(
//     (state) => state?.arkchatgroup?.accountLevelPeople
//   );
//   const groupList = useSelector((state) => state?.arkchatgroup?.groupList);
//   const groupTopicData = useSelector((state) => state?.topic?.groupTopicData);
//   const currentHomeIndexTab = useSelector(
//     (state) => state?.appStateData?.currentHomeIndexTab
//   );
//   const userData = useSelector((state) => state?.profile?.profileData);
//   const groupmemberdetails = useSelector(
//     (state) => state?.arkchatgroup?.groupmember
//   );
//   const isAiScreenActive = useSelector(
//     (state) => state?.appStateData?.isAiScreenActive
//   );
//   const accountLevelPeople = useSelector(
//     (state) => state?.arkchatgroup?.accountLevelPeople
//   );
//   const groupTopicData = useSelector((state) => state?.topic?.groupTopicData);
//   const selectedGroup = useSelector(
//     (state) => state?.appStateData?.selectedGroupData
//   );
//   const selectedTopic = useSelector(
//     (state) => state?.appStateData?.selectedTopicData
//   );
//   const selectedTopicToSaveMsg = useSelector(
//     (state) => state?.appStateData?.selectedTopicToSaveMsg
//   );
//   const currentHomeIndexTab = useSelector(
//     (state) => state?.appStateData?.currentHomeIndexTab
//   );
//   const isThreadScreen = useSelector(
//     (state) => state?.localAppReducer?.isThreadScreen
//   );
//   const groupList = useSelector((state) => state?.arkchatgroup?.groupList);
//   const groupRecipientDetails = useSelector(
//     (state) => state?.arkchatgroup?.groupRecipient
//   );
//   const messageList = useSelector((state) => state?.arkchatgroup?.messageList);
//   const threadMsgList = useSelector(
//     (state) => state?.arkchatgroup?.threadMsgList
//   );
//   const user = useSelector((state) => state?.profile?.emailStore?.data);
//   const selectedTopicToSaveMsg = useSelector(
//     (state) => state?.appStateData?.selectedTopicToSaveMsg
//   );
//   const selectedGroup = useSelector(
//     (state) => state?.appStateData?.selectedGroupData
//   );
//   const userData = useSelector((state) => state?.profile);
//   const isThreadScreen = useSelector(
//     (state) => state?.localAppReducer?.isThreadScreen
//   );
//   const aiHistoryData = useSelector(
//     (state) => state?.arkchatgroup?.aiHistoryData
//   );
//   const aiQuerySession = useSelector(
//     (state) => state?.arkchatgroup?.aiQuerySession
//   );
//   const selectedGroup = useSelector(
//     (state) => state?.appStateData?.selectedGroupData
//   );
//   const approvalAnalyticsData = useSelector(
//     (state) => state?.arkchatgroup?.approvalAnalyticsData
//   );
//   const storeApprovallist = useSelector(
//     (state) => state?.arkchatgroup?.approvalList
//   );
//   const isLoading = useSelector((state) => state?.localAppReducer?.isLoading);
//   const userData = useSelector((state) => state?.profile?.profileData?.data);
//   const groupmemberdetails = useSelector(
//     (state) => state?.arkchatgroup?.groupmember
//   );
//   const accountLevelPeople = useSelector(
//     (state) => state?.arkchatgroup?.accountLevelPeople
//   );

//   const userData = useSelector((state) => state?.profile);
//   const groupmemberdetails = useSelector(
//     (state) => state?.arkchatgroup?.groupmember
//   );
//   const groupRecipientDetails = useSelector(
//     (state) => state?.arkchatgroup?.groupRecipient
//   );
//   const groupList = useSelector((state) => state?.arkchatgroup?.groupList);
//   const userData = useSelector((state) => state?.profile?.profileData?.data);

//   const selectedGroup = useSelector(
//     (state) => state?.appStateData?.selectedGroupData
//   );
//   const groupLevelPeople = useSelector(
//     (state) => state?.arkchatgroup?.groupmember
//   );
//   const groupLevelPeople = useSelector(
//     (state) => state?.arkchatgroup?.groupmemberWithPermission
//   );

//   const languageListData = useSelector((state) => state?.languages);
//   const userData = useSelector((state) => state?.profile);
//   const selectedGroup = useSelector(
//     (state) => state?.appStateData?.selectedGroupData
//   );
//   const selectedGroup = useSelector(
//     (state) => state?.appStateData?.selectedGroupData
//   );
//   const selectedTopic = useSelector(
//     (state) => state?.appStateData?.selectedTopicData
//   );
//   const threadMsgList = useSelector(
//     (state) => state?.arkchatgroup?.threadMsgList
//   );
//   const isThreadScreen = useSelector(
//     (state) => state?.localAppReducer?.isThreadScreen
//   );
//   let fileListData = useSelector(
//     (state) => state?.arkchatgroup?.attachmentData
//   );
//   const allNotificationData = useSelector(
//     (state) => state?.notification?.allNotificationData
//   );
//   const currentHomeIndexTab = useSelector(
//     (state) => state?.appStateData?.currentHomeIndexTab
//   );
//   const faqList = useSelector((state) => state?.faq?.faqData);
//   const userData = useSelector((state) => state?.profile);
//   const tempMsgList = useSelector(
//     (state) => state?.arkchatgroup?.tempMessageList
//   );
//   const isThreadScreen = useSelector(
//     (state) => state?.localAppReducer?.isThreadScreen
//   );
//   const selectedTopic = useSelector(
//     (state) => state?.appStateData?.selectedTopicData
//   );
//   const selectedGroup = useSelector(
//     (state) => state?.appStateData?.selectedGroupData
//   );
//   const showStarMessage = useSelector(
//     (state) => state?.appStateData?.showStarMessage
//   );
//   const isScrollToBottom = useSelector(
//     (state) => state?.appStateData?.isScrollToBottom
//   );
//   const isThreadScrollToBottom = useSelector(
//     (state) => state?.appStateData?.isThreadScrollToBottom
//   );
//   const groupmemberdetails = useSelector(
//     (state) => state?.arkchatgroup?.groupmember
//   );
//   const groupRecipientDetails = useSelector(
//     (state) => state?.arkchatgroup?.groupRecipient
//   );
//   const starMsgData = useSelector((state) => state?.arkchatgroup?.starMsgData);
//   const userData = useSelector((state) => state?.profile);
//   const tempMsgList = useSelector(
//     (state) => state?.arkchatgroup?.tempMessageList
//   );
//   const isThreadScreen = useSelector(
//     (state) => state?.localAppReducer?.isThreadScreen
//   );
//   const selectedTopic = useSelector(
//     (state) => state?.appStateData?.selectedTopicData
//   );
//   const selectedGroup = useSelector(
//     (state) => state?.appStateData?.selectedGroupData
//   );
//   const groupmemberdetails = useSelector(
//     (state) => state?.arkchatgroup?.groupmember
//   );
//   const showStarMessage = useSelector(
//     (state) => state?.appStateData?.showStarMessage
//   );
//   const isScrollToBottom = useSelector(
//     (state) => state?.appStateData?.isScrollToBottom
//   );
//   const isThreadScrollToBottom = useSelector(
//     (state) => state?.appStateData?.isThreadScrollToBottom
//   );
//   const starMsgData = useSelector((state) => state?.arkchatgroup?.starMsgData);
//   const threadMsgList = useSelector(
//     (state) => state?.arkchatgroup?.threadMsgList
//   );
//   const groupTopicData = useSelector((state) => state?.topic?.groupTopicData);
//   const isThreadScreen = useSelector(
//     (state) => state?.localAppReducer?.isThreadScreen
//   );
//   const currentuserdetails = useSelector(
//     (state) => state?.profile?.profileData
//   );
//   const allNotificationData = useSelector(
//     (state) => state?.notification?.allNotificationData
//   );
//   const allPendingInvitationData = useSelector(
//     (state) => state?.subscriber?.allPendingInvitationData
//   );
//   const indicatorData = useSelector((state) => state?.global?.indicatorData);
//   const currentuserdetails = useSelector(
//     (state) => state?.profile?.profileData
//   );
//   const groupTopicData = useSelector((state) => state?.topic?.groupTopicData);
//   const groupTopicData = useSelector((state) => state?.topic?.groupTopicData);
//   const selectedGroup = useSelector(
//     (state) => state?.appStateData?.selectedGroupData
//   );
//   const groupList = useSelector((state) => state?.arkchatgroup?.groupList);
//   const cloneGroupList = useSelector(
//     (state) => state?.arkchatgroup?.cloneGroupList
//   );
//   const currentHomeIndexTab = useSelector(
//     (state) => state?.appStateData?.currentHomeIndexTab
//   );
//   const isAiScreenActive = useSelector(
//     (state) => state?.appStateData?.isAiScreenActive
//   );
//   const isThreadScreen = useSelector(
//     (state) => state?.localAppReducer?.isThreadScreen
//   );
//   const showStarMessage = useSelector(
//     (state) => state?.appStateData?.showStarMessage
//   );
//   const storeTasklist = useSelector((state) => state?.arkchatgroup?.taskList);
//   const isLoading = useSelector((state) => state?.localAppReducer?.isLoading);
//   const taskAnalyticsData = useSelector(
//     (state) => state?.arkchatgroup?.taskAnalyticsData
//   );
//   const userData = useSelector((state) => state?.profile?.profileData);
//   const messageList = useSelector((state) => state?.arkchatgroup?.messageList);
//   const selectedGroup = useSelector(
//     (state) => state?.appStateData?.selectedGroupData
//   );
//   const selectedTopic = useSelector(
//     (state) => state?.appStateData?.selectedTopicData
//   );
//   const selectedTopicToSaveMsg = useSelector(
//     (state) => state?.appStateData?.selectedTopicToSaveMsg
//   );
//   const groupTopicData = useSelector((state) => state?.topic?.groupTopicData);
//   const isChangeTopicOpen = useSelector(
//     (state) => state?.appStateData?.isChangeTopicOpen
//   );
//   const inviteData = useSelector((state) => state?.subscriber);
//   const userData = useSelector((state) => state?.profile?.profileData?.data);
//   const artServiceCategoryData = useSelector(
//     (state) => state?.serviceCategory?.artServiceCategoryData
//   );
//   const photoServiceCategoryData = useSelector(
//     (state) => state?.serviceCategory?.photoServiceCategoryData
//   );
//   const productServiceCategoryData = useSelector(
//     (state) => state?.serviceCategory?.productServiceCategoryData
//   );
//   const serviceCategoryData = useSelector(
//     (state) => state?.serviceCategory?.serviceCategoryData
//   );
//   const videoServiceCategoryData = useSelector(
//     (state) => state?.serviceCategory?.videoServiceCategoryData
//   );
//   const exploreDataList = useSelector(
//     (state) => state?.arkchatgroup?.exploreDataList
//   );
//   const exploreDataList = useSelector(
//     (state) => state?.arkchatgroup?.exploreDataList
//   );
//   const exploreDataList = useSelector(
//     (state) => state?.arkchatgroup?.exploreDataList
//   );
//   const exploreDataList = useSelector(
//     (state) => state?.arkchatgroup?.exploreDataList
//   );
//   const likeUserList = useSelector(
//     (state) => state?.arkchatgroup?.likeUserList
//   );
//   const artistList = useSelector(
//     (state) => state?.arkchatgroup?.artistDataList?.data
//   );
//   const brandList = useSelector(
//     (state) => state?.arkchatgroup?.brandDataList?.data
//   );
//   const countryData = useSelector((state) => state?.country);
//   const postDataList = useSelector(
//     (state) => state?.arkchatgroup?.postDataList
//   );
//   const userData = useSelector((state) => state?.profile?.profileData);
//   const userData = useSelector((state) => state?.profile?.profileData);
//   const artServiceCategoryData = useSelector(
//     (state) => state?.serviceCategory?.artServiceCategoryData
//   );
//   const countryData = useSelector((state) => state?.country);
//   const postPreviewData = useSelector(
//     (state) => state?.arkchatgroup?.postPreviewData
//   );
//   const postEditorPreviewData = useSelector(
//     (state) => state?.appStateData?.postEditPreviewData
//   );
//   const userData = useSelector((state) => state?.profile?.profileData);
//   const photoServiceCategoryData = useSelector(
//     (state) => state?.serviceCategory?.photoServiceCategoryData
//   );
//   const countryData = useSelector((state) => state?.country);
//   const postPreviewData = useSelector(
//     (state) => state?.arkchatgroup?.postPreviewData
//   );
//   const postEditorPreviewData = useSelector(
//     (state) => state?.appStateData?.postEditPreviewData
//   );
//   const userData = useSelector((state) => state?.profile?.profileData);
//   const productServiceCategoryData = useSelector(
//     (state) => state?.serviceCategory?.productServiceCategoryData
//   );

//   const countryData = useSelector((state) => state?.country);
//   const postPreviewData = useSelector(
//     (state) => state?.arkchatgroup?.postPreviewData
//   );
//   const postEditorPreviewData = useSelector(
//     (state) => state?.appStateData?.postEditPreviewData
//   );
//   const userData = useSelector((state) => state?.profile?.profileData);
//   const serviceCategoryData = useSelector(
//     (state) => state?.serviceCategory?.serviceCategoryData
//   );
//   const countryData = useSelector((state) => state?.country);
//   const postPreviewData = useSelector(
//     (state) => state?.arkchatgroup?.postPreviewData
//   );
//   const postEditorPreviewData = useSelector(
//     (state) => state?.appStateData?.postEditPreviewData
//   );
//   const userData = useSelector((state) => state?.profile?.profileData);
//   const videoServiceCategoryData = useSelector(
//     (state) => state?.serviceCategory?.videoServiceCategoryData
//   );
//   const countryData = useSelector((state) => state?.country);
//   const postPreviewData = useSelector(
//     (state) => state?.arkchatgroup?.postPreviewData
//   );
//   const postEditorPreviewData = useSelector(
//     (state) => state?.appStateData?.postEditPreviewData
//   );
//   const countryData = useSelector((state) => state?.country);
//   const industryData = useSelector((state) => state?.indusrty);
//   const profileData = useSelector((state) => state?.profile?.profileData);
//   const switchAccountRefresh = useSelector(
//     (state) => state?.appStateData?.isSwitchAccountRefresh
//   );
//   const artServiceCategoryData = useSelector(
//     (state) => state?.serviceCategory?.artServiceCategoryData
//   );
//   const sellerPostList = useSelector(
//     (state) => state?.arkchatgroup?.sellerPostList
//   );
//   const userData = useSelector((state) => state?.profile?.profileData?.data);
//   const productServiceCategoryData = useSelector(
//     (state) => state?.serviceCategory?.productServiceCategoryData
//   );
//   const serviceCategoryData = useSelector(
//     (state) => state?.serviceCategory?.serviceCategoryData
//   );
//   const userData = useSelector((state) => state?.profile?.profileData);
//   const indicatorData = useSelector((state) => state?.global?.indicatorData);
//   const switchAccountRefresh = useSelector(
//     (state) => state?.appStateData?.isSwitchAccountRefresh
//   );
