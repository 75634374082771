import { Route, Routes } from "react-router-dom";
import "./App.css";
import Profile from "./page/Profille/Index";
import ForgotPassword from "./page/auth/forgotpassword";
import SignIn from "./page/auth/login";
import Signup from "./page/auth/signup";
import Chat from "./page/chat";
import Explore from "./page/explore";
import Post from "./page/post";
import Contact from "./page/contact";
import OtpPage from "./page/auth/Otp";
import VerifyEmail from "./page/auth/VerifyEmail";
import PrivateRoute from "./PrivateRoute";
import InviteComponent from "./components/ChatStartComponent/InviteComponent";
import CreateFirstGroupComponent from "./components/ChatStartComponent/CreateFirstGroupComponent";
import Invitation from "./Invitation";
import InvitationPage from "./InvitationPage";
import IndividualProfile from "./page/Profille/IndividualProfile";
import OrganisationProfile from "./page/Profille/OrganisationProfile";
import PostCreateIndex from "./page/post/PostCreateIndex";
import ChangeEmail from "./page/More/ChangeEmail";
import ManagePost from "./page/post/Tabs/ManagePost";
import useWindowDimensions from "./customHooks/useWindowDimensions";
import useAppState from "./customHooks/useAppState";
import Header from "./page/chat/Header/Header";
import ToastMsg from "./common/Toastbar/ToastMsg";
import NotFound from "./page/NotFound";
import NewPassword from "./page/auth/CreateNewPassword/NewPassword";
import PaymentStatus from "./components/Subscription/PaymentStatus";
import PaymentEnvironment from "./components/Subscription/Subscription/PaymentEnvironment";
import { Box } from "@mui/material";
import AccountSwitchLoading from "./common/LoadingSpinner/AccountSwitchLoading";
import DeleteAccountOpenRoute from "./page/DeleteAccountOpenUrl/DeleteAccountOpenRoute";
import DeleteAccountVerifyOtp from "./page/DeleteAccountOpenUrl/DeleteAccountVerifyOtp";
import MobileView from "./components/MobileView";

const App = () => {
  const {
    isAddPeopledarwer,
    setAddpeopledarwer,
    setisTopicOpen,
    isTopicOpen,
    isTokenRefreshed,
    groupname,
    setGroupname,
    setPricingDrawerOpen,
    isPricingDrawerOpen,
    pubnub,
    tostmsgData,
    showToast,
    taskApprovalViewValue,
    setTaskApprovalViewValue,
    approvalTabValue,
    setApprovalTabValue,
    selectedTaskGroup,
    setSelectedTaskGroup,
    selectedTaskmember,
    setSelectedTaskMember,
    taskTabValue,
    setTaskTabValue,
    handleCloseAddPeopleDrawer,
  } = useAppState();
  const endpoint = window.location.pathname;
  const { width } = useWindowDimensions();

  if (!isTokenRefreshed) {
    return null;
  }

  return (
    <>
      <AccountSwitchLoading />
      {endpoint !== "/invitation" &&
        endpoint !== "/invitations" &&
        endpoint !== "/createnewpassword" &&
        endpoint !== "/profile" &&
        endpoint !== "/individualprofile" &&
        endpoint !== "/organisationprofile" &&
        endpoint !== "/email-verify" &&
        endpoint !== "/otp-verify" &&
        endpoint !== "/forgetpassword" &&
        endpoint !== "/signin" &&
        endpoint !== "/delete-account" &&
        endpoint !== "/delete-account-otp-verify" &&
        endpoint !== "/" &&
        width > 1001 && (
          <Header
            setPricingDrawerOpen={setPricingDrawerOpen}
            isPricingDrawerOpen={isPricingDrawerOpen}
            pubnub={pubnub}
          />
        )}
      {showToast && <ToastMsg showToastMsg={showToast} message={tostmsgData} />}
      {width < 1001 ? (
        <div style={{ height: "100vh", width: "100%" }}>
          <MobileView />
        </div>
      ) : (
        <Box>
          <Routes>
            <Route
              path="*"
              element={
                <PrivateRoute>
                  <NotFound />
                </PrivateRoute>
              }
            />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/" element={<Signup />} />
            <Route path="/invitation" element={<Invitation />} />
            <Route path="/forgetpassword" element={<ForgotPassword />} />
            <Route path="/otp-verify" element={<OtpPage />} />
            <Route path="/createnewpassword" element={<NewPassword />} />
            <Route
              path="/delete-account"
              element={<DeleteAccountOpenRoute />}
            />
            <Route
              path="/delete-account-otp-verify"
              element={<DeleteAccountVerifyOtp />}
            />

            {/* Private Routes */}
            <Route
              path="/invite"
              element={
                <PrivateRoute>
                  <InviteComponent />
                </PrivateRoute>
              }
            />
            <Route
              path="/createfirstgroup"
              element={
                <PrivateRoute>
                  <CreateFirstGroupComponent />
                </PrivateRoute>
              }
            />
            <Route
              path="/payment-status"
              element={
                <PrivateRoute>
                  <PaymentEnvironment>
                    <PaymentStatus />
                  </PaymentEnvironment>
                </PrivateRoute>
              }
            />
            <Route
              path="/chat"
              element={
                <PrivateRoute>
                  <Chat
                    pubnub={pubnub}
                    setisTopicOpen={setisTopicOpen}
                    isTopicOpen={isTopicOpen}
                    setAddpeopledarwer={setAddpeopledarwer}
                    isAddPeopledarwer={isAddPeopledarwer}
                    setPricingDrawerOpen={setPricingDrawerOpen}
                    handleCloseAddPeopleDrawer={handleCloseAddPeopleDrawer}
                    setGroupname={setGroupname}
                    groupname={groupname}
                    taskTabValue={taskTabValue}
                    setTaskTabValue={setTaskTabValue}
                    taskApprovalViewValue={taskApprovalViewValue}
                    setTaskApprovalViewValue={setTaskApprovalViewValue}
                    approvalTabValue={approvalTabValue}
                    setApprovalTabValue={setApprovalTabValue}
                    selectedTaskGroup={selectedTaskGroup}
                    setSelectedTaskGroup={setSelectedTaskGroup}
                    selectedTaskmember={selectedTaskmember}
                    setSelectedTaskMember={setSelectedTaskMember}
                  />
                </PrivateRoute>
              }
            />

            <Route
              path="/explore"
              element={
                <PrivateRoute>
                  <Explore />
                </PrivateRoute>
              }
            />
            <Route
              path="/post"
              element={
                <PrivateRoute>
                  <Post />
                </PrivateRoute>
              }
            />
            <Route
              path="/post/post-create"
              element={
                <PrivateRoute>
                  <PostCreateIndex />
                </PrivateRoute>
              }
            />
            <Route
              path="/post/manage-mypost"
              element={
                <PrivateRoute>
                  <ManagePost />
                </PrivateRoute>
              }
            />
            {/* Private Routes */}
            <Route
              path="/contact"
              element={
                <PrivateRoute>
                  <Contact />
                </PrivateRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <PrivateRoute>
                  <Profile />
                </PrivateRoute>
              }
            />
            <Route
              path="/individualprofile"
              element={
                <PrivateRoute>
                  <IndividualProfile />
                </PrivateRoute>
              }
            />
            <Route
              path="/organisationprofile"
              element={
                <PrivateRoute>
                  <OrganisationProfile />
                </PrivateRoute>
              }
            />

            <Route path="/email-verify" element={<VerifyEmail />} />

            {/* Private Routes */}
            <Route
              path="/invitations"
              element={
                <PrivateRoute>
                  <InvitationPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/change-email"
              element={
                <PrivateRoute>
                  <ChangeEmail />
                </PrivateRoute>
              }
            />
          </Routes>
        </Box>
      )}
    </>
  );
};

export default App;
