import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Typography,
  Stack,
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { createStyledIndicatorButton } from "../../../common/StyledComponents/SideBarComponents";
import {
  billingFormData,
  // initatePayment,
  submitBillingInfo,
} from "../../../appStore/actions/Subscription/action";
import {
  CONFIRM_COUPON_VALIDITY,
  PAYMENT_INTENT_DATA,
} from "../../../appStore/actions/Subscription/type";
// import { showToastMsg } from "../../../appStore/actions/global/action";
import { useTranslation } from "react-i18next";

const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    height: "100%",
    position: "relative",
  },
  header: {
    backgroundColor: "#F9F8F8",
    color: "#0000",
    padding: "7px 8px",
    mb: "1rem",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "4px",
    borderRadius: "2px",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },

  cellParentBox: {
    display: "flex",
    gap: "1%",
  },
  cellChildBox: {
    width: "29%",
    textAlign: "center",
  },
  cellChildFirstBox: {
    width: "40%",
    textAlign: "start",
  },
  headerText: {
    fontSize: "0.87rem",
    fontWeight: 600,
    marginBottom: "1rem",
  },
  bodyText: {
    fontSize: "0.75rem",
    fontWeight: 400,
    marginBottom: "1rem",
  },
  paymentTypeBox: {
    backgroundColor: "rgba(243, 243, 243, 1)",
    borderRadius: "1px",
    padding: "0.5rem 1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
};

const SubscriptionPlan = ({
  setQuantityDarwerOpen,
  setCheckoutDrawerOpen,
}) => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  // const [isCouponValid, setCouponValid] = useState(false);
  const dispatch = useDispatch();
  const allActiveMembers = useSelector(
    (state) => state?.subscription?.allSubscribedUsers
  );
  const selectedPlanData = useSelector(
    (state) => state?.subscription?.selectedPlanData
  );
  const billingResponseData = useSelector(
    (state) => state?.subscription?.billingResponseData
  );
  const billingData = useSelector((state) => state?.subscription?.billingData);
  const couponData = useSelector((state) => state?.subscription?.couponData);
  const [termConditionChecked, setTermConditionChecked] = useState(false);
  const [packQuantity, setPackQuantity] = useState(allActiveMembers?.length);
  const [couponCode, setCouponCode] = useState("");
  const [isCouponBtnLoading, setCouponBtnLoading] = useState(false);
  const [isNextBtnLoading, setNextBtnLoading] = useState(false);
  const SmallActionButton = createStyledIndicatorButton("#E3E1E1");

  const addQtyHandler = () => {
    setPackQuantity(packQuantity + 1);
  };

  const subQtyHandler = () => {
    if (packQuantity > allActiveMembers?.length)
      setPackQuantity(packQuantity - 1);
  };

  const handleClickNext = () => {
    setNextBtnLoading(true);
    setNextBtnLoading(false);
    setCheckoutDrawerOpen(true);
    // dispatch(initatePayment(billingResponseData?.id)).then((res) => {
    //   if (res?.secret) {
    //     dispatch({ type: PAYMENT_INTENT_DATA, payload: res });
    //     setNextBtnLoading(false);
    //     setCheckoutDrawerOpen(true);
    //   } else {
    //     dispatch(showToastMsg(appContent?.wrongError));
    //   }
    // });
  };
  const handleClose = () => {
    const { id, ...restData } = billingData;
    setQuantityDarwerOpen(false);
    dispatch({
      type: CONFIRM_COUPON_VALIDITY,
      payload: {},
    });
    dispatch({ type: PAYMENT_INTENT_DATA, payload: {} });
    dispatch(billingFormData(restData));
  };

  const verifyCouponCode = () => {
    setCouponBtnLoading(true);
    const reqBody = {
      ...billingData,
      coupon_id: couponData?.prodId,
      coupon_code: couponCode,
    };
    if (couponCode) {
      dispatch(submitBillingInfo(reqBody)).then((res) => {
        dispatch({ type: PAYMENT_INTENT_DATA, payload: res?.data });
        setCouponBtnLoading(false);
      });
    }
  };
  return (
    <Box sx={classes.drawerBody}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={classes.header}
      >
        <Typography sx={classes.iconDiv}>
          <CloseIcon sx={classes.iconn} onClick={handleClose} />
        </Typography>
        <Typography color={"black"}>Pricing</Typography>
      </Stack>

      <Box width={"90%"} margin={"auto"}>
        <Box sx={classes?.cellParentBox}>
          <Box sx={classes?.cellChildFirstBox}>
            <Typography sx={classes?.headerText}>
              {appContent?.subscription}
            </Typography>
          </Box>
          <Box sx={classes?.cellChildBox}>
            <Typography sx={classes?.headerText}>{appContent?.qty}</Typography>
          </Box>
          <Box sx={classes?.cellChildBox}>
            <Typography textAlign={"right"} sx={classes?.headerText}>
              {appContent?.amount}
            </Typography>
          </Box>
        </Box>

        <Box sx={classes?.cellParentBox}>
          <Box sx={classes?.cellChildFirstBox}>
            <Typography sx={classes?.bodyText}>
              {selectedPlanData?.description}
            </Typography>
          </Box>
          <Box
            sx={{
              ...classes?.cellChildBox,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <SmallActionButton
              size="small"
              variant="contained"
              sx={{ height: "1.4rem", width: "1.4rem", color: "black" }}
              onClick={subQtyHandler}
              disabled
            >
              -
            </SmallActionButton>{" "}
            <Typography mr={"0.2rem"} ml={"0.2rem"}>
              {billingResponseData?.quantity}
            </Typography>{" "}
            <SmallActionButton
              size="small"
              variant="contained"
              sx={{ height: "1.4rem", width: "1.4rem", color: "black" }}
              onClick={addQtyHandler}
              disabled
            >
              +
            </SmallActionButton>
          </Box>
          <Box sx={classes?.cellChildBox}>
            {" "}
            <Typography textAlign={"right"}>
              {billingResponseData?.symbol}{" "}
              {billingResponseData?.price?.toFixed(2)}
            </Typography>
          </Box>
        </Box>
        <Box sx={classes?.cellParentBox}>
          <Box sx={{ ...classes?.cellChildFirstBox, width: "90%" }}>
            <Typography sx={{ ...classes?.bodyText, fontWeight: 600 }}>
              {appContent?.billingCycle} : {billingResponseData?.billingCycle}
            </Typography>
          </Box>
        </Box>

        <Box mb={"0.5rem"} sx={classes?.cellParentBox}>
          <Box sx={classes?.cellChildFirstBox}>
            <Typography sx={classes?.bodyText}>
              {appContent?.lessPromocode}
            </Typography>
            <Box display={"flex"} gap={"1rem"}>
              <input
                onChange={(e) => setCouponCode(e.target.value.trim())}
                style={{ outline: "none", padding: "0.2rem" }}
                placeholder={appContent?.havePromoCode}
              />
              <Button
                disabled={couponCode ? false : true}
                onClick={verifyCouponCode}
                variant="outlined"
                sx={{
                  borderColor: "#3EC823",
                  padding: "0.2rem 0.4rem",
                  color: "black",
                  borderRadius: "0",
                  "&:hover": { borderColor: "#3EC823" },
                }}
              >
                {isCouponBtnLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  appContent?.apply
                )}
              </Button>
            </Box>
          </Box>
          <Box sx={classes?.cellChildBox}>
            <Typography>-</Typography>
          </Box>
          <Box sx={classes?.cellChildBox}>
            <Typography textAlign={"right"}>
              {billingResponseData?.symbol}{" "}
              {billingResponseData?.discount?.toFixed(2)}
            </Typography>
          </Box>
        </Box>
        {/* promo code will be minus in total */}
        <Box mt={"1rem"} mb={"1rem"} border={"1px solid #d5d5d5"}></Box>
        <Box sx={classes?.cellParentBox}>
          <Box sx={classes?.cellChildFirstBox}>
            <Typography sx={classes?.bodyText}>{appContent?.total}</Typography>
          </Box>
          <Box sx={classes?.cellChildBox}></Box>
          <Box sx={classes?.cellChildBox}>
            <Typography textAlign={"right"}>
              {billingResponseData?.symbol}{" "}
              {billingResponseData?.total?.toFixed(2)}
            </Typography>
          </Box>
        </Box>
        {billingResponseData?.taxAmount > 0 && (
          <Box sx={classes?.cellParentBox}>
            <Box sx={classes?.cellChildFirstBox}>
              <Typography sx={classes?.bodyText}>{appContent?.tax}</Typography>
            </Box>
            <Box sx={classes?.cellChildBox}></Box>
            <Box sx={classes?.cellChildBox}>
              <Typography textAlign={"right"}>
                {billingResponseData?.symbol}{" "}
                {billingResponseData?.taxAmount?.toFixed(2)}
              </Typography>
            </Box>
          </Box>
        )}

        <Box mt={"1rem"} mb={"1rem"} border={"1px solid #d5d5d5"}></Box>
        <Box sx={classes?.cellParentBox}>
          <Box sx={classes?.cellChildFirstBox}>
            <Typography sx={classes?.bodyText}>
              {appContent?.totalPayableAmount}
            </Typography>
          </Box>
          <Box sx={classes?.cellChildBox}></Box>
          <Box sx={classes?.cellChildBox}>
            <Typography textAlign={"right"}>
              {billingResponseData?.symbol}{" "}
              {billingResponseData?.subTotal?.toFixed(2)}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        alignItems="flex-start"
        width={"90%"}
        margin={"auto"}
        mt={"1.5rem"}
        display={"flex"}
      >
        <FormControlLabel
          sx={{
            ".MuiFormControlLabel-label": {
              fontSize: "0.85rem",
            },
          }}
          control={
            <Checkbox
              onChange={(e) => setTermConditionChecked(e.target.checked)}
              size="medium"
              sx={{ "& .MuiSvgIcon-root": { color: "#183243" } }}
            />
          }
          labelPlacement="end"
        />
        <Typography variant="caption">
          {appContent?.pricingTermAndCondition.split(/\n/).map((line, indx) => (
            <span
              key={line + indx + "xyz009"}
              style={{
                marginBottom: "0.5rem",
                display: "block",
                border: line === "" ? "1px solid transparent" : "none",
              }}
            >
              {line}
            </span>
          ))}
        </Typography>
      </Box>

      <Box width={"100%"} margin={"auto"} textAlign={"center"} mt={"1rem"}>
        <Button
          disabled={!termConditionChecked || isNextBtnLoading}
          onClick={handleClickNext}
          sx={{
            backgroundColor: "#DB0011",
            color: "#fff",
            padding: "0.4rem 2.4rem",
            textTransform: "none",
            borderRadius: "0.15rem",
            ":hover": {
              backgroundColor: "#DB0011",
              color: "#fff",
            },
          }}
          variant="contained"
        >
          {isNextBtnLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            appContent?.buy
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default SubscriptionPlan;
