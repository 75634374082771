import React from "react";
import ReactLinkify from "react-linkify";

const HandleLink = ({ children }) => {
  return (
    <ReactLinkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <a
          href={decoratedHref}
          target="_blank"
          rel="noopener noreferrer"
          key={key}
        >
          Click Link
        </a>
      )}
    >
      {children}
    </ReactLinkify>
  );
};

export default HandleLink;
