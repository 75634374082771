import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { createStyledIndicatorButton } from "../../../../common/StyledComponents/SideBarComponents";
import useAppSelectors from "../../../../customHooks/useAppSelectors";
import { groupExternalMember } from "../../../../utils/externalMemberGroping";
import { useTranslation } from "react-i18next";
const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%",
  },
  header: {
    backgroundColor: "#E1E1E1",
    color: "black",
    padding: "7px 8px",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "30px",
    width: "30px",
    cursor: "pointer",
    padding: "4px",
    borderRadius: "2px",
  },

  internalExternalButton: {
    background: "white",
    border: "1px solid #183243",
    color: "#183243",
    borderRadius: "0px",
    "&:hover": {
      background: "white",
    },
  },

  activeButton: {
    "&:hover": {
      background: "#183243",
    },
    background: "#183243",
    borderRadius: "0px",
    color: "white",
  },

  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
  radiogp: {
    display: "block",
  },
  groupMember: {
    backgroundColor: "#F3F3F3",
    padding: "20px 10px",
  },

  // add group member
  addgmember: {
    transform: "translate(0, 70%)",
    textAlign: "center",
  },
  // add group member
};

const TaskTeamList = ({
  handlecloseTeamlist,
  handleSelectTaskMemberCheck,
  selectedTaskmember,
}) => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const { accountLevelPeople } = useAppSelectors();
  const NameBadge = createStyledIndicatorButton("#E3E1E1");
  const [externalMemberData, setExternalMemberData] = useState([]);

  useEffect(() => {
    const groupedExternalData = groupExternalMember(
      accountLevelPeople?.externalMember,
      false, // isRecipientList
      appContent?.individual
    );
    setExternalMemberData(groupedExternalData);
  }, []);

  return (
    <React.Fragment>
      <Box sx={classes.drawerBody}>
        <Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={classes.header}
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography sx={classes.iconDiv}>
                <CloseIcon sx={classes.iconn} onClick={handlecloseTeamlist} />
              </Typography>
              <Typography variant="subtitle2">Team</Typography>
            </Stack>
          </Stack>
          <Stack
            mt={"0.5rem"}
            overflow={"scroll"}
            overflowY={"hidden"}
            height={"calc(100vh - 7rem)"}
            sx={{
              "&::-webkit-scrollbar": {
                width: "2px",
                padding: "0px 2px",
              },
              "&::-webkit-scrollbar-track": {
                background: "#F9F8F8",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "grey",
              },
            }}
            padding={" 10px 25px"}
          >
            <Typography
              sx={{ margin: "10px", fontWeight: "bold" }}
              variant="subtitle2"
            >
              Internal Members
            </Typography>
            {accountLevelPeople?.internalMember?.map((item) => {
              return (
                <FormControlLabel
                  key={item?.userId + "internalTaskTeamList"}
                  sx={{ marginBottom: "10px" }}
                  checked={
                    selectedTaskmember?.length > 0 &&
                    selectedTaskmember[0]?.userId === item?.userId
                  }
                  control={
                    <Checkbox
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 30,
                          color: "#183243",
                        },
                      }}
                      onChange={(e) => handleSelectTaskMemberCheck(e, item)}
                      icon={
                        <NameBadge
                          sx={{ color: "#183243" }}
                          size="small"
                          variant="contained"
                        >
                          <Typography
                            textTransform={"uppercase"}
                            variant="h6"
                            fontSize={"0.75rem"}
                            color={"#183243"}
                          >
                            {item?.userInfo?.firstName &&
                              item?.userInfo?.firstName[0]}
                          </Typography>
                        </NameBadge>
                      }
                    />
                  }
                  label={`${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`}
                />
              );
            })}
            <Typography
              sx={{ margin: "10px", fontWeight: "bold" }}
              variant="subtitle2"
            >
              External Members
            </Typography>
            {externalMemberData.map((company, index) => (
              <Box padding="0px 19px 6px 12px">
                <FormControlLabel
                  sx={{
                    ".MuiFormControlLabel-label": {
                      fontWeight: 500,
                      color: "#000000",
                      
                    },
                  }}
                  control={
                    <Checkbox
                      checked={false} // Always unchecked
                      onChange={(e) => e.preventDefault()} // Prevent toggling
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 30,
                          color: "#183243",
                        },
                      }}
                      icon={
                        <NameBadge
                          sx={{ color: "#183243"}}
                          size="small"
                          variant="contained"
                        >
                          <Typography
                            color={"#183243"}
                            fontSize={"0.75rem"}
                            fontWeight={600}
                          >
                            {Object.keys(company)[0] &&
                              Object.keys(company)[0][0]}
                          </Typography>
                        </NameBadge>
                      }
                    />
                  }
                  label={
                    <Typography fontWeight={600} fontSize={"0.88rem"}>
                      {Object.keys(company)[0]}
                    </Typography>
                  }
                />
                {Object.values(company)[0]?.map((item, i) => (
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={1}
                    key={item?.userId + "externalInvitegrpmember"}
                  >
                    <Box width={"62%"} padding={"0rem 0rem 0rem 1.2rem"}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: 25,
                                color: "#183243",
                              },
                            }}
                            checked={
                              selectedTaskmember?.length > 0 &&
                              selectedTaskmember[0]?.userId === item?.userId
                            }
                            onChange={(e) =>
                              handleSelectTaskMemberCheck(e, item)
                            }
                            icon={
                              <NameBadge
                                sx={{
                                  color: "#183243",
                                  width: "1.55rem",
                                  height: "1.55rem",
                                }}
                                size="small"
                                variant="contained"
                              >
                                <Typography
                                  color={"#183243"}
                                  fontSize={"0.75rem"}
                                >
                                  {item?.userInfo?.firstName &&
                                    item?.userInfo?.firstName[0]}
                                </Typography>
                              </NameBadge>
                            }
                          />
                        }
                        label={`${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`}
                      />
                    </Box>
                    {/* <FormControlLabel
                        control={
                          <Switchs
                            switchLabel="Owner"
                            name={item?.userId}
                            checked={groupMemeberPermission?.[item?.userId]}
                            handleChange={(event) =>
                              handleChangeInternalMemberGrpPermission(
                                event,
                                item
                              )
                            }
                          />
                        }
                      /> */}
                  </Stack>
                ))}
              </Box>
            ))}
            {/* {accountLevelPeople?.externalMember?.map((item) => {
              return (
                <FormControlLabel
                  key={item?.userId + "externalTaskTeamList"}
                  sx={{ marginBottom: "10px" }}
                  checked={
                    selectedTaskmember?.length > 0 &&
                    selectedTaskmember[0]?.userId === item?.userId
                  }
                  control={
                    <Checkbox
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 30,
                          color: "#183243",
                        },
                      }}
                      onChange={(e) => handleSelectTaskMemberCheck(e, item)}
                      icon={
                        <NameBadge
                          sx={{ color: "#183243" }}
                          size="small"
                          variant="contained"
                        >
                          <Typography
                            textTransform={"uppercase"}
                            variant="h6"
                            fontSize={"0.75rem"}
                            color={"#183243"}
                          >
                            {item?.userInfo?.firstName &&
                              item?.userInfo?.firstName[0]}
                          </Typography>
                        </NameBadge>
                      }
                    />
                  }
                  label={`${item?.userInfo?.firstName} ${item?.userInfo?.lastName}`}
                />
              );
            })} */}
          </Stack>
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default TaskTeamList;
