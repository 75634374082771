import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Typography, Drawer, Stack } from "@mui/material";
import ProfileUpdateForm from "./ProfileUpdateForm";
import profileimg from "../../assets/images/auth/profileimg.svg";
import profileBanner from "../../assets/images/Network.svg";
import companyLogo from "../../assets/images/auth/companyLogo.svg";
import CloseIcon from "@mui/icons-material/Close";
import CountryDrawer from "../post/Drawer/CountryDrawer";
import { getCountryList } from "../../appStore/actions/masterData/masterDataAction";
import {
  editSubscriberProfile,
  updateFirmPic,
} from "../../appStore/actions/profile/action";

const classes = {
  drawerBody: {
    minWidth: "31.25rem",
    maxWidth: "31.25rem",
    height: "100%",
  },
  header: {
    backgroundColor: "#F9F8F8",
    color: "#0000",
    padding: "7px 8px",
    mb: "0.4rem",
  },
  iconDiv: {
    backgroundColor: "#DB0011",
    position: "relative",
    height: "1.87rem",
    width: "1.87rem",
    cursor: "pointer",
    padding: "0.25rem",
    borderRadius: "0.12rem",
  },
  iconn: {
    color: "#fff",
    height: "100%",
    width: "100%",
  },
};
const FirmProfile = ({ setFirmProfileDrawerOpen }) => {
  const userData = useSelector((state) => state?.profile?.profileData);
  const coverPicRef = useRef(null);
  const profilePicRef = useRef(null);
  const [isEditable, setEditable] = useState(true);
  const [isCountryDrawer, setCountryDrawer] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [coverImage, setCoverImage] = useState({
    src: profileBanner,
    isCoverSelected: false,
    files: "",
  });
  const [profileImage, setProfileImage] = useState({
    src: companyLogo,
    isProfileSelected: false,
    files: "",
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCountryList());
    setProfileData({
      city: userData?.data?.activeSubscriber?.city || "",
      country: userData?.data?.activeSubscriber?.country || "",
      country_code: userData?.data?.activeSubscriber?.countryCode || "",
      subscriber_firm_name:
        userData?.data?.activeSubscriber?.subscriberFirmName || "",
      website: userData?.data?.activeSubscriber?.website || "",
      profile_brief: userData?.data?.activeSubscriber?.profileBrief || "",
      linkedin: userData?.data?.activeSubscriber?.linkedin || "",
    });
    setCoverImage({
      ...coverImage,
      src: userData?.data?.activeSubscriber?.coverImgUrl
        ? userData?.data?.activeSubscriber?.coverImgUrl
        : profileBanner,
    });
    setProfileImage({
      ...profileImage,
      src: userData?.data?.activeSubscriber?.profileImgUrl
        ? userData?.data?.activeSubscriber?.profileImgUrl
        : companyLogo,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleCameraButtonClick = (type) => {
    if (type === "cover") {
      coverPicRef.current.click();
    } else {
      profilePicRef.current.click();
    }
  };

  const handleRemovePic = (type) => {
    if (type === "cover") {
      setCoverImage({
        src: userData?.data?.coverImgUrl
          ? userData?.data?.coverImgUrl
          : profileBanner,
        isCoverSelected: false,
        files: "",
      });
    } else {
      setProfileImage({
        src: userData?.data?.profileImgUrl
          ? userData?.data?.profileImgUrl
          : profileimg,
        isProfileSelected: false,
        files: "",
      });
    }
  };

  const handleProfileImgInputChange = (event) => {
    const file = event.target.files[0];
    setEditable(false);
    if (event.target.name === "cover") {
      setCoverImage({
        ...coverImage,
        src: URL.createObjectURL(file),
        isCoverSelected: true,
        files: file,
      });
    } else {
      setProfileImage({
        ...profileImage,
        src: URL.createObjectURL(file),
        isProfileSelected: true,
        files: file,
      });
    }
  };
  const handleCountryInputChange = (country) => {
    setProfileData({
      ...profileData,
      country: country?.name,
      country_code: country?.iso2,
    });
    setCountryDrawer(false);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // if (name === "url") {
    //   setRequiredInput("url");
    // } else if (name === "website") {
    //   setRequiredInput("website");
    // }
    if (name === "profile_brief") {
      // Limiting the input character
      if (value.length <= 160) {
        setProfileData({
          ...profileData,
          [name]: value,
        });
      }
    }
    if (name !== "profile_brief") {
      setProfileData({
        ...profileData,
        [name]: value,
      });
    }
  };
  const handleUpdateSave = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("data", JSON.stringify(profileData));
    dispatch(
      editSubscriberProfile(formData, userData?.data?.activeSubscriber?.id,true)
    ).then(()=>setFirmProfileDrawerOpen(false));
    if (coverImage?.isCoverSelected) {
      const formData = new FormData();
      formData.append("files", coverImage?.files);
      formData.append("data", JSON.stringify({ is_cover_image: true }));
      dispatch(updateFirmPic(formData, userData?.data?.activeSubscriber?.id));
    }

    if (profileImage?.isProfileSelected) {
      const formData = new FormData();
      formData.append("files", profileImage?.files);
      formData.append("data", JSON.stringify({ is_cover_image: false }));
      dispatch(updateFirmPic(formData, userData?.data?.activeSubscriber?.id));
    }
  };
  return (
    <Box
      overflow={"scroll"}
      height={"calc(100vh - 16rem)"}
      sx={classes.drawerBody}
    >
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        sx={classes.header}
      >
        <Typography sx={classes.iconDiv}>
          <CloseIcon
            sx={classes.iconn}
            onClick={() => setFirmProfileDrawerOpen(false)}
          />
        </Typography>
        <Typography color={"black"}>Organisation Profile</Typography>
      </Stack>
      <Box
        width={"90%"}
        margin={"auto"}
        overflow={"scroll"}
        height={"calc(100vh - 5rem)"}
        sx={{
          "&::-webkit-scrollbar": {
            width: "1px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#F9F8F8",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "grey",
          },
        }}
      >
        <Drawer sx={{ zIndex: 1202 }} open={isCountryDrawer} anchor={"right"}>
          <CountryDrawer
            handleCountryInputChange={handleCountryInputChange}
            setCountryDrawer={setCountryDrawer}
          />
        </Drawer>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          bgcolor={"#F3F3F3"}
          padding={"0.25rem"}
        >
          <Box>
            <Typography
              textAlign={"center"}
              variant="h5"
              fontSize={"1rem"}
              fontWeight={600}
            >
              {userData?.data?.activeSubscriber?.subscriberFirmName}
            </Typography>
          </Box>
        </Box>
        <ProfileUpdateForm
          handleInputChange={handleInputChange}
          handleRemovePic={handleRemovePic}
          isEditable={isEditable}
          profileData={profileData}
          handleProfileImgInputChange={handleProfileImgInputChange}
          handleCameraButtonClick={handleCameraButtonClick}
          profileImage={profileImage}
          coverImage={coverImage}
          profilePicRef={profilePicRef}
          coverPicRef={coverPicRef}
          countryDrawer={setCountryDrawer}
          handleSave={handleUpdateSave}
          type={"Firm"}
          setEditable={setEditable}
        />
      </Box>
    </Box>
  );
};

export default FirmProfile;
