import React, { useEffect, useState } from "react";
import { AppBar, Typography, Badge, Drawer } from "@mui/material";
import { Box, styled } from "@mui/system";
import arkChatLogo from "../../../assets/images/arkChatLogo.svg";
import { useTranslation } from "react-i18next";
import {
  StyledHeaderLogo,
  StyledNavLink,
  StyledNavigationBox,
  StyledProfileBox,
  StyledToolBar,
} from "../../../common/StyledComponents/HeaderComponents";
import { useDispatch, useSelector } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { handleLogout } from "../../../appStore/actions/auth/login/loginaction";
import { useNavigate } from "react-router-dom";
import { createStyledIndicatorButton } from "../../../common/StyledComponents/SideBarComponents";
import headerNotificationIcon from "../../../assets/images/headerNotificationIcon.svg";
import Notification from "../Drawer/Notification";
import { getAllNotification } from "../../../appStore/actions/notification/action";
import LanguageList from "../Drawer/LanguageList";
import AppLanguage from "../Drawer/AppLanguage";
import dropdownarrow from "../../../assets/images/dropdownarrow.svg";
import { getActivityIndicatorData } from "../../../appStore/actions/global/action";
import SwitchAccount from "../../Switch/SwitchAccount";
import PopupModal from "../../../common/PopupModal";
import Settings from "../../More/Settings";
import Faq from "./Faq";
import MenuDrawer from "./MenuDrawer";
import { getAllPendingInvitation } from "../../../appStore/actions/subscriber/action";
import MyProfile from "../../More/MyProfile";
import FirmProfile from "../../More/FirmProfile";
import ResetPassword from "../../More/ResetPassword";
import DeleteAccount from "../../More/DeleteAccount";
import Subscription from "../../../components/Subscription/Subscription";
import SubscriptionPlan from "../../../components/Subscription/Subscription/SubscriptionPlan";
import BillingInformationForm from "../../../components/Subscription/Subscription/BillingInformationForm";
import NewOrganisationProfile from "../../Profille/NewOrganisationProfile";
import PaymentEnvironment from "../../../components/Subscription/Subscription/PaymentEnvironment";
import CheckoutForm from "../../../components/Subscription/CheckoutForm";
import useAppSelectors from "../../../customHooks/useAppSelectors";
import LableToolTip from "../../../common/LableToolTip";
import Support from "./Support";
import { handleHomeTabValue } from "../../../appStore/actions/AppState/action";
import AddManageSinglePlanMember from "../../../components/Subscription/Subscription/AddManageSinglePlanMember";
import ToggelManageMemberDrawer from "../../../components/Subscription/ManageSubscription/ToggelManageMemberDrawer";
export const StyledAppBar = styled(AppBar)({
  backgroundColor: "#fff",
  boxShadow: "none",
  padding: "0px",
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#DB0011",
    },
  },
});
const Header = ({ pubnub, setPricingDrawerOpen, isPricingDrawerOpen }) => {
  const endpoint = window.location.pathname;
  const { t } = useTranslation();
  const { currentUserData } = useAppSelectors();
  const appContent = t("AppContent", { returnObjects: true });
  const [isNotificationOpen, setNotification] = useState(false);
  const [isLanguageMenuOpen, setLanguageMenuOpen] = useState(false);
  const [isPopupModalOpen, setPopupModalOpen] = useState(false);
  const [isSwitchAccountDrawerOpen, setSwitchAccountDrawerOpen] =
    useState(false);
  const [isCreateNewOrgAccountDrawerOpen, setCreateNewOrgAccountDrawerOpen] =
    useState(false);
  const [isSettingDrawerOpen, setSettingDrawerOpen] = useState(false);
  const [isMyProfileDrawerOpen, setMyProfileDrawerOpen] = useState(false);
  const [isFirmProfileDrawerOpen, setFirmProfileDrawerOpen] = useState(false);
  const [isResetPasswordDrawerOpen, setResetPasswordDrawerOpen] =
    useState(false);
  const [isDeleteAccountDrawerOpen, setDeleteAccountDrawerOpen] =
    useState(false);
  const [ismenuDrawerOpen, setMenuDrawerOpen] = useState(false);
  const [isFaqDrawerOpen, setFaqDrawerOpen] = useState(false);
  const [isCheckboxManageMemberDrawerOpen, setCheckboxManageMemberDrawerOpen] =
    useState(false);
  const [isToggelManageMemberDrawerOpen, setToggelManageMemberDrawerOpen] =
    useState(false);
  const [isToggelBuyMemberDrawerOpen, setToggelBuyMemberDrawerOpen] =
    useState(false);
  const [isCheckoutDrawerOpen, setCheckoutDrawerOpen] = useState(false);
  const [isBillingFormDrawerOpen, setBillingFormDrawerOpen] = useState(false);
  const [isQuantityDarwerOpen, setQuantityDarwerOpen] = useState(false);
  const [isSupportDrawerOpen, setSupportDrawerOpen] = useState(false);
  const ProfileBadge = createStyledIndicatorButton("#E3E1E1");
  const allNotificationData = useSelector(
    (state) => state?.notification?.allNotificationData
  );
  const allPendingInvitationData = useSelector(
    (state) => state?.subscriber?.allPendingInvitationData
  );
  const indicatorData = useSelector((state) => state?.global?.indicatorData);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClickLogout = () => {
    dispatch(handleLogout()).then((res) => {
      if (res?.status) {
        localStorage.clear();
        pubnub.unsubscribeAll();
        navigate("/");
      }
    });
  };

  const links = [
    {
      path: "/chat",
      title: appContent?.home,
    },
    {
      path: "/explore",
      title: appContent?.explore,
    },
    {
      path: "/post",
      title: appContent?.post,
    },
    {
      path: "/contact",
      title: appContent?.contacts,
    },
    {
      path: "/switch",
      title: "Switch",
    },
  ];

  const handleLinkClick = (e, path) => {
    e.preventDefault();
    if (path === "/chat") {
      dispatch(handleHomeTabValue(0));
    }
    navigate(path);
  };
  useEffect(() => {
    dispatch(getAllNotification());
    dispatch(getActivityIndicatorData(currentUserData?.id));
    dispatch(getAllPendingInvitation());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleLanguageCloseMenu = () => {
    setLanguageMenuOpen(false);
  };
  return (
    <>
      {endpoint !== "/" &&
        endpoint !== "/createfirstgroup" &&
        endpoint !== "/invite" && (
          <>
            <Drawer
              sx={{ zIndex: 1202 }}
              open={isNotificationOpen}
              anchor={"right"}
            >
              <Notification setNotification={setNotification} />
            </Drawer>
            <Drawer
              sx={{ zIndex: 1202 }}
              open={isLanguageMenuOpen}
              anchor={"right"}
            >
              <LanguageList
                handleLanguageCloseMenu={handleLanguageCloseMenu}
                setLanguageMenuOpen={setLanguageMenuOpen}
              />
            </Drawer>
            <Drawer
              sx={{ zIndex: 1202 }}
              open={isSwitchAccountDrawerOpen}
              anchor={"right"}
            >
              <SwitchAccount
                setSwitchAccountDrawerOpen={setSwitchAccountDrawerOpen}
                setCreateNewOrgAccountDrawerOpen={
                  setCreateNewOrgAccountDrawerOpen
                }
              />
            </Drawer>
            <Drawer
              sx={{ zIndex: 1202 }}
              open={isCreateNewOrgAccountDrawerOpen}
              anchor={"right"}
            >
              <NewOrganisationProfile
                setCreateNewOrgAccountDrawerOpen={
                  setCreateNewOrgAccountDrawerOpen
                }
                setSwitchAccountDrawerOpen={setSwitchAccountDrawerOpen}
              />
            </Drawer>
            <Drawer
              sx={{ zIndex: 1202 }}
              open={isSettingDrawerOpen}
              anchor={"right"}
              hideBackdrop={true}
            >
              <Settings
                setSettingDrawerOpen={setSettingDrawerOpen}
                setResetPasswordDrawerOpen={setResetPasswordDrawerOpen}
                setDeleteAccountDrawerOpen={setDeleteAccountDrawerOpen}
              />
            </Drawer>

            <Drawer
              sx={{ zIndex: 1202 }}
              open={isResetPasswordDrawerOpen}
              anchor={"right"}
              hideBackdrop={true}
            >
              <ResetPassword
                setResetPasswordDrawerOpen={setResetPasswordDrawerOpen}
              />
            </Drawer>

            <Drawer
              sx={{ zIndex: 1202 }}
              open={isDeleteAccountDrawerOpen}
              anchor={"right"}
              hideBackdrop={true}
            >
              <DeleteAccount
                setDeleteAccountDrawerOpen={setDeleteAccountDrawerOpen}
              />
            </Drawer>
            <>
              <Drawer
                sx={{ zIndex: 1202 }}
                open={isPricingDrawerOpen}
                anchor={"right"}
                hideBackdrop={true}
              >
                <Subscription
                  setPricingDrawerOpen={setPricingDrawerOpen}
                  setQuantityDarwerOpen={setQuantityDarwerOpen}
                  setBillingFormDrawerOpen={setBillingFormDrawerOpen}
                  setCheckboxManageMemberDrawerOpen={
                    setCheckboxManageMemberDrawerOpen
                  }
                  setToggelManageMemberDrawerOpen={
                    setToggelManageMemberDrawerOpen
                  }
                  setToggelBuyMemberDrawerOpen={setToggelBuyMemberDrawerOpen}
                />
              </Drawer>

              <Drawer
                sx={{ zIndex: 1202 }}
                open={isCheckboxManageMemberDrawerOpen}
                anchor={"right"}
                hideBackdrop={true}
              >
                <AddManageSinglePlanMember
                  setCheckboxManageMemberDrawerOpen={
                    setCheckboxManageMemberDrawerOpen
                  }
                  setBillingFormDrawerOpen={setBillingFormDrawerOpen}
                  drawerType={"buy"}
                />
              </Drawer>
              <Drawer
                sx={{ zIndex: 1202 }}
                open={isToggelManageMemberDrawerOpen}
                anchor={"right"}
                hideBackdrop={true}
              >
                <ToggelManageMemberDrawer
                  setToggelManageMemberDrawerOpen={
                    setToggelManageMemberDrawerOpen
                  }
                  drawerType={"manage"}
                />
              </Drawer>
              <Drawer
                sx={{ zIndex: 1202 }}
                open={isToggelBuyMemberDrawerOpen}
                anchor={"right"}
                hideBackdrop={true}
              >
                <ToggelManageMemberDrawer
                  setToggelManageMemberDrawerOpen={setToggelBuyMemberDrawerOpen}
                  setBillingFormDrawerOpen={setBillingFormDrawerOpen}
                  drawerType={"buy"}
                />
              </Drawer>

              <Drawer
                sx={{ zIndex: 1202 }}
                open={isBillingFormDrawerOpen}
                anchor={"right"}
                hideBackdrop={true}
              >
                <BillingInformationForm
                  setQuantityDarwerOpen={setQuantityDarwerOpen}
                  setBillingFormDrawerOpen={setBillingFormDrawerOpen}
                />
              </Drawer>

              <Drawer
                sx={{ zIndex: 1202 }}
                open={isQuantityDarwerOpen}
                anchor={"right"}
                hideBackdrop={true}
              >
                <SubscriptionPlan
                  setPricingDrawerOpen={setPricingDrawerOpen}
                  setQuantityDarwerOpen={setQuantityDarwerOpen}
                  setCheckoutDrawerOpen={setCheckoutDrawerOpen}
                />
              </Drawer>

              <Drawer
                sx={{ zIndex: 1202 }}
                open={isCheckoutDrawerOpen}
                anchor={"right"}
                hideBackdrop={true}
              >
                <PaymentEnvironment>
                  <CheckoutForm setCheckoutDrawerOpen={setCheckoutDrawerOpen} />
                </PaymentEnvironment>
              </Drawer>
            </>

            <Drawer
              sx={{ zIndex: 1202 }}
              open={isMyProfileDrawerOpen}
              anchor={"right"}
              hideBackdrop={true}
            >
              <MyProfile setMyProfileDrawerOpen={setMyProfileDrawerOpen} />
            </Drawer>
            <Drawer
              sx={{ zIndex: 1202 }}
              open={isFirmProfileDrawerOpen}
              anchor={"right"}
              hideBackdrop={true}
            >
              <FirmProfile
                setFirmProfileDrawerOpen={setFirmProfileDrawerOpen}
              />
            </Drawer>
            <Drawer
              sx={{ zIndex: 1202 }}
              open={ismenuDrawerOpen}
              anchor={"right"}
            >
              <MenuDrawer
                handleClickLogout={handleClickLogout}
                setLanguageMenuOpen={setLanguageMenuOpen}
                setSettingDrawerOpen={setSettingDrawerOpen}
                setMenuDrawerOpen={setMenuDrawerOpen}
                setMyProfileDrawerOpen={setMyProfileDrawerOpen}
                setFirmProfileDrawerOpen={setFirmProfileDrawerOpen}
                setPricingDrawerOpen={setPricingDrawerOpen}
                setSupportDrawerOpen={setSupportDrawerOpen}
                showPricing={true}
              />
            </Drawer>

            <Drawer
              sx={{ zIndex: 1202 }}
              open={isFaqDrawerOpen}
              anchor={"right"}
            >
              <Faq setFaqDrawerOpen={setFaqDrawerOpen} />
            </Drawer>
            <Drawer
              sx={{ zIndex: 1202 }}
              open={isSupportDrawerOpen}
              anchor={"right"}
            >
              <Support setSupportDrawerOpen={setSupportDrawerOpen} />
            </Drawer>

            <StyledAppBar position="sticky">
              <StyledToolBar>
                <Box
                  width={"26%"}
                  maxWidth={"26%"}
                  padding={"0rem 0rem 0rem 0.3rem"}
                >
                  <StyledHeaderLogo src={arkChatLogo} alt="arkChatLogo" />
                </Box>

                <StyledNavigationBox>
                  {links?.map((el) => (
                    <Box maxWidth={"5rem"} key={el.path + "header"}>
                      {" "}
                      {el?.path === "/switch" && indicatorData?.length > 0 ? (
                        <ThemeProvider theme={theme}>
                          <Badge
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                            color="primary"
                            variant="dot"
                            invisible={indicatorData?.length === 0}
                          >
                            <Typography
                              onClick={() => setSwitchAccountDrawerOpen(true)}
                              sx={{ cursor: "pointer" }}
                              fontSize={"0.87rem"}
                              color={"black"}
                              overflow={"hidden"}
                              textOverflow={"ellipsis"}
                              whiteSpace={"nowrap"}
                            >
                              {el.title}
                            </Typography>
                          </Badge>
                        </ThemeProvider>
                      ) : el?.path === "/contact" &&
                        allPendingInvitationData?.invitations?.length > 0 ? (
                        <Badge
                          color="success"
                          badgeContent={
                            allPendingInvitationData?.invitations?.length
                          }
                          sx={{
                            "& .MuiBadge-badge": {
                              backgroundColor: "#DB0011",
                              fontSize: "0.8rem",
                            },
                          }}
                        >
                          <Typography
                            overflow={"hidden"}
                            textOverflow={"ellipsis"}
                            whiteSpace={"nowrap"}
                            color={"inherit"}
                          >
                            <StyledNavLink
                              onClick={(e) => handleLinkClick(e, el.path)}
                              to={el.path}
                            >
                              {" "}
                              {el.title}
                            </StyledNavLink>
                          </Typography>
                        </Badge>
                      ) : (
                        <>
                          {el?.path !== "/switch" && (
                            <Typography
                              overflow={"hidden"}
                              textOverflow={"ellipsis"}
                              whiteSpace={"nowrap"}
                              color={"inherit"}
                            >
                              <StyledNavLink
                                onClick={(e) => handleLinkClick(e, el.path)}
                                to={el.path}
                              >
                                {" "}
                                {el.title}
                              </StyledNavLink>
                            </Typography>
                          )}
                          {el?.path === "/switch" && (
                            <Typography
                              onClick={() => setSwitchAccountDrawerOpen(true)}
                              sx={{ cursor: "pointer" }}
                              fontSize={"0.87rem"}
                              color={"black"}
                              overflow={"hidden"}
                              textOverflow={"ellipsis"}
                              whiteSpace={"nowrap"}
                            >
                              {el.title}
                            </Typography>
                          )}
                        </>
                      )}
                    </Box>
                  ))}

                  <Box
                    onClick={() => setFaqDrawerOpen(true)}
                    sx={{ cursor: "pointer" }}
                    color={"black"}
                  >
                    <Typography fontSize={"0.87rem"}>FAQ</Typography>
                  </Box>
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() => setNotification(true)}
                  >
                    {allNotificationData?.data?.length > 0 &&
                    allNotificationData?.data?.length > 0 ? (
                      <Badge
                        color="success"
                        badgeContent={allNotificationData?.data?.length}
                        sx={{
                          "& .MuiBadge-badge": {
                            backgroundColor: "#DB0011",
                            fontSize: "0.8rem",
                          },
                        }}
                      >
                        <img
                          style={{ width: "90%" }}
                          src={headerNotificationIcon}
                          alt="icon"
                        />
                      </Badge>
                    ) : (
                      <img
                        style={{ width: "90%" }}
                        src={headerNotificationIcon}
                        alt="icon"
                      />
                    )}
                  </Box>

                  <StyledProfileBox sx={{ width: "28%" }}>
                    <LableToolTip title={appContent?.clickToOpenMenu}>
                      {currentUserData?.activeSubscriber?.profileImgUrl ? (
                        <Box
                          onClick={() => setMenuDrawerOpen(true)}
                          width="1.87rem"
                          height="1.87rem"
                          mr="0.2rem"
                        >
                          <img
                            style={{ width: "100%", height: "100%" }}
                            src={
                              currentUserData?.activeSubscriber?.profileImgUrl
                            }
                            alt=""
                          />
                        </Box>
                      ) : (
                        <ProfileBadge
                          sx={{ color: "#183243", mr: "0.2rem" }}
                          size="small"
                          variant="contained"
                          onClick={() => setMenuDrawerOpen(true)}
                        >
                          <Typography color={"#183243"}>
                            {currentUserData?.activeSubscriber
                              ?.subscriberFirmName &&
                              currentUserData?.activeSubscriber
                                ?.subscriberFirmName[0]}
                          </Typography>
                        </ProfileBadge>
                      )}
                    </LableToolTip>

                    <Box
                      sx={{ cursor: "pointer" }}
                      mr="0.2rem"
                      onClick={() => setMenuDrawerOpen(true)}
                    >
                      {currentUserData?.activeSubscriber?.subscriberFirmName
                        ?.length >= 30 && (
                        <Typography
                          color="black"
                          fontSize={"0.875rem"}
                          fontWeight="400"
                          variant="h6"
                          title={
                            currentUserData?.activeSubscriber
                              ?.subscriberFirmName
                          }
                        >
                          {currentUserData?.activeSubscriber?.subscriberFirmName.slice(
                            0,
                            25
                          ) + " ..."}{" "}
                        </Typography>
                      )}
                      {currentUserData?.activeSubscriber?.subscriberFirmName
                        ?.length < 30 && (
                        <Typography
                          color="black"
                          fontSize={"0.875rem"}
                          fontWeight="400"
                          variant="h6"
                          title={
                            currentUserData?.activeSubscriber
                              ?.subscriberFirmName
                          }
                        >
                          {
                            currentUserData?.activeSubscriber
                              ?.subscriberFirmName
                          }{" "}
                        </Typography>
                      )}
                    </Box>
                    <LableToolTip title={appContent?.clickToOpenMenu}>
                      <Box
                        onClick={() => setMenuDrawerOpen(true)}
                        sx={{ cursor: "pointer" }}
                        position={"relative"}
                      >
                        <img
                          style={{ width: "100%" }}
                          src={dropdownarrow}
                          alt=""
                        />
                      </Box>
                    </LableToolTip>
                  </StyledProfileBox>
                </StyledNavigationBox>
              </StyledToolBar>
            </StyledAppBar>
          </>
        )}

      {(endpoint === "/invite" || endpoint === "/createfirstgroup") && (
        <>
          {isPopupModalOpen && (
            <PopupModal
              title={appContent?.alert}
              content={appContent?.onBoardingHeaderAlert}
              setPopupModalOpen={setPopupModalOpen}
            />
          )}
          <Drawer
            sx={{ zIndex: 1202 }}
            open={isNotificationOpen}
            anchor={"right"}
          >
            <Notification setNotification={setNotification} />
          </Drawer>
          <Drawer
            sx={{ zIndex: 1202 }}
            open={isLanguageMenuOpen}
            anchor={"right"}
          >
            <LanguageList
              handleLanguageCloseMenu={handleLanguageCloseMenu}
              setLanguageMenuOpen={setLanguageMenuOpen}
            />
          </Drawer>
          <Drawer
            sx={{ zIndex: 1202 }}
            open={isSwitchAccountDrawerOpen}
            anchor={"right"}
          >
            <SwitchAccount
              setSwitchAccountDrawerOpen={setSwitchAccountDrawerOpen}
              setCreateNewOrgAccountDrawerOpen={
                setCreateNewOrgAccountDrawerOpen
              }
            />
          </Drawer>
          <Drawer
            sx={{ zIndex: 1202 }}
            open={isCreateNewOrgAccountDrawerOpen}
            anchor={"right"}
          >
            <NewOrganisationProfile
              setCreateNewOrgAccountDrawerOpen={
                setCreateNewOrgAccountDrawerOpen
              }
              setSwitchAccountDrawerOpen={setSwitchAccountDrawerOpen}
            />
          </Drawer>
          <Drawer
            sx={{ zIndex: 1202 }}
            open={isSettingDrawerOpen}
            anchor={"right"}
            hideBackdrop={true}
          >
            <Settings
              setSettingDrawerOpen={setSettingDrawerOpen}
              setResetPasswordDrawerOpen={setResetPasswordDrawerOpen}
              setDeleteAccountDrawerOpen={setDeleteAccountDrawerOpen}
            />
          </Drawer>

          <Drawer
            sx={{ zIndex: 1202 }}
            open={isResetPasswordDrawerOpen}
            anchor={"right"}
            hideBackdrop={true}
          >
            <ResetPassword
              setResetPasswordDrawerOpen={setResetPasswordDrawerOpen}
            />
          </Drawer>

          <Drawer
            sx={{ zIndex: 1202 }}
            open={isDeleteAccountDrawerOpen}
            anchor={"right"}
            hideBackdrop={true}
          >
            <DeleteAccount
              setDeleteAccountDrawerOpen={setDeleteAccountDrawerOpen}
            />
          </Drawer>

          <Drawer
            sx={{ zIndex: 1202 }}
            open={isMyProfileDrawerOpen}
            anchor={"right"}
            hideBackdrop={true}
          >
            <MyProfile setMyProfileDrawerOpen={setMyProfileDrawerOpen} />
          </Drawer>

          <Drawer
            sx={{ zIndex: 1202 }}
            open={isFirmProfileDrawerOpen}
            anchor={"right"}
            hideBackdrop={true}
          >
            <FirmProfile setFirmProfileDrawerOpen={setFirmProfileDrawerOpen} />
          </Drawer>
          <Drawer
            sx={{ zIndex: 1202 }}
            open={ismenuDrawerOpen}
            anchor={"right"}
          >
            <MenuDrawer
              handleClickLogout={handleClickLogout}
              setLanguageMenuOpen={setLanguageMenuOpen}
              setSettingDrawerOpen={setSettingDrawerOpen}
              setMenuDrawerOpen={setMenuDrawerOpen}
              setMyProfileDrawerOpen={setMyProfileDrawerOpen}
              setFirmProfileDrawerOpen={setFirmProfileDrawerOpen}
              setPricingDrawerOpen={setPricingDrawerOpen}
            />
          </Drawer>

          <Drawer sx={{ zIndex: 1202 }} open={isFaqDrawerOpen} anchor={"right"}>
            <Faq setFaqDrawerOpen={setFaqDrawerOpen} />
          </Drawer>
          <StyledAppBar position="sticky">
            <StyledToolBar>
              <Box
                width={"22%"}
                maxWidth={"22%"}
                padding={"0rem 0rem 0rem 0.3rem"}
              >
                <StyledHeaderLogo src={arkChatLogo} alt="arkChatLogo" />
              </Box>
              <StyledNavigationBox>
                {links?.map((el, index) => (
                  <Box key={el.title + "tempheader"}>
                    {" "}
                    {index === 0 ? ( // Check if it's the first link
                      <Typography
                        sx={{
                          textDecoration: "none",
                          color: "#000000",
                          fontFamily: "Poppins",
                          fontWeight: "400",
                          fontSize: "0.87rem",
                          "&.active": {
                            color: "#000",
                            fontWeight: "600",
                          },
                        }}
                        overflow={"hidden"}
                        textOverflow={"ellipsis"}
                        whiteSpace={"nowrap"}
                        color={"inherit"}
                      >
                        <StyledNavLink
                          to={"#"}
                          onClick={(e) => {
                            if (!currentUserData?.isFirstGroupCreated) {
                              navigate("/createfirstgroup");
                            } else {
                              navigate("/invite");
                            }
                            e.preventDefault();
                          }}
                        >
                          {el.title}
                        </StyledNavLink>
                      </Typography>
                    ) : (
                      <Typography
                        overflow={"hidden"}
                        textOverflow={"ellipsis"}
                        whiteSpace={"nowrap"}
                        color={"inherit"}
                      >
                        <StyledNavLink
                          style={{ color: "gray" }}
                          to={el.path}
                          onClick={(e) => {
                            setPopupModalOpen(true);
                            e.preventDefault();
                          }}
                        >
                          {el.title}
                        </StyledNavLink>
                      </Typography>
                    )}
                  </Box>
                ))}
                <Box sx={{ cursor: "pointer" }} color={"grey"}>
                  <Typography fontFamily={"Poppins"} fontSize={"0.87rem"}>
                    FAQ
                  </Typography>
                </Box>
                <Box
                  sx={{ cursor: "pointer" }}
                  onClick={() => setNotification(true)}
                >
                  {allNotificationData?.data?.length > 0 &&
                  allNotificationData?.data?.length > 0 ? (
                    <Badge
                      color="success"
                      badgeContent={allNotificationData?.data?.length}
                      sx={{
                        "& .MuiBadge-badge": {
                          backgroundColor: "#DB0011", // Your custom background color
                          fontSize: "0.8rem",
                          zIndex: 2001,
                          border: "2px solid #183243",
                          bgcolor: "transparent",
                          color: "#183243",
                          width: "20px",
                          height: "20px",
                        },
                      }}
                    >
                      <img
                        style={{ width: "90%" }}
                        src={headerNotificationIcon}
                        alt="icon"
                      />
                    </Badge>
                  ) : (
                    <img
                      style={{ width: "90%" }}
                      src={headerNotificationIcon}
                      alt="icon"
                    />
                  )}
                </Box>

                <StyledProfileBox
                  sx={{ width: "28%" }}
                  onClick={() => setMenuDrawerOpen(true)}
                >
                  {currentUserData?.activeSubscriber?.profileImgUrl ? (
                    <Box width="1.87rem" height="1.87rem" mr="0.2rem">
                      <img
                        style={{ width: "100%" }}
                        src={currentUserData?.activeSubscriber?.profileImgUrl}
                        alt=""
                      />
                    </Box>
                  ) : (
                    <ProfileBadge
                      sx={{ color: "#183243", mr: "0.2rem" }}
                      size="small"
                      variant="contained"
                    >
                      <Typography color={"#183243"}>
                        {currentUserData?.activeSubscriber
                          ?.subscriberFirmName &&
                          currentUserData?.activeSubscriber
                            ?.subscriberFirmName[0]}
                      </Typography>
                    </ProfileBadge>
                  )}

                  <Box mr="0.2rem">
                    {currentUserData?.activeSubscriber?.subscriberFirmName
                      ?.length >= 30 && (
                      <Typography
                        color="black"
                        fontSize={"0.875rem"}
                        fontWeight="400"
                        variant="h6"
                        title={
                          currentUserData?.activeSubscriber?.subscriberFirmName
                        }
                      >
                        {currentUserData?.activeSubscriber?.subscriberFirmName.slice(
                          0,
                          25
                        ) + " ..."}{" "}
                      </Typography>
                    )}
                    {currentUserData?.activeSubscriber?.subscriberFirmName
                      ?.length < 30 && (
                      <Typography
                        color="black"
                        fontSize={"0.875rem"}
                        fontWeight="400"
                        variant="h6"
                        title={
                          currentUserData?.activeSubscriber?.subscriberFirmName
                        }
                      >
                        {currentUserData?.activeSubscriber?.subscriberFirmName}{" "}
                      </Typography>
                    )}
                  </Box>

                  <Box sx={{ cursor: "pointer" }} position={"relative"}>
                    <img style={{ width: "100%" }} src={dropdownarrow} alt="" />
                  </Box>
                </StyledProfileBox>
              </StyledNavigationBox>
            </StyledToolBar>
          </StyledAppBar>
        </>
      )}
    </>
  );
};

export default Header;
