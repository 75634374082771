import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCookie } from "../config/cookie";
import { useNavigate } from "react-router-dom";
import {
  handleInstantPubnubPublishMessage,
  handleInstantPubnubPublishThreadMessage,
  handleUpdateGroupSorting,
  handleUpdateMsgToDb,
  requestGetAllAcountLevelMember,
  requestallmemberswithgroups,
  requestgroupMemberDetails,
  handleFetchConversation,
  handleUpdateExistingMessage,
  handleReadAllMsg,
  handleSyncStarMsgData,
  handleDeleteMsgFromDb,
  handlePubnubToken,
  requestGetGroupRecipient,
  requestUpdateFromSignalGroupRecipient,
} from "../appStore/actions/ArkchatGroup/action";
import { getProfile } from "../appStore/actions/profile/action";
import {
  getAllPendingInvitation,
  switchAccount,
} from "../appStore/actions/subscriber/action";
import { getAlltopic } from "../appStore/actions/topics/action";
import { onMessageListener } from "../config/firebase.config";
import { jwtDecode } from "jwt-decode";
import { getRefreshToken } from "../appStore/actions/auth/signup/action";
import { handleLogout } from "../appStore/actions/auth/login/loginaction";
import { getAllNotification } from "../appStore/actions/notification/action";
import { handlePubnubSignal } from "../page/chat/HandlePubnubEvent/pubnubSignal";
import {
  handleAppSelectedTopic,
  handleScrollToBottom,
  handleSwitchAccountRefresh,
  handleThreadScrollToBottom,
  handleTogglePunbunTokenUpdateState,
  saveSelectedAppGroupData,
} from "../appStore/actions/AppState/action";
import Pubnub from "pubnub";
import {
  HANDLE_TOAST_OPEN_CLOSE,
  SHOW_INTERNET_TOAST,
  SHOW_TOAST_MESSAGE,
} from "../appStore/actions/global/type";
import { useTranslation } from "react-i18next";
import {
  UPDATE_EXISTING_APPROVALLIST_DATA,
  UPDATE_EXISTING_MESSAGE_LIST,
  UPDATE_EXISTING_TASKLIST_DATA,
  UPDATE_EXISTING_THREAD_MESSAGE,
} from "../appStore/actions/ArkchatGroup/type";
import { handleDecryptSingleMessageData } from "../utils/messageEncrypt";
import { MessageType } from "../utils/constant";
import { SHOULD_CALL_PUBNUB_GET_TOKEN } from "../appStore/actions/AppState/type";
import useAppSelectors from "./useAppSelectors";
import { logWrapper } from "../utils/helper";

const useAppState = () => {
  const { t } = useTranslation();
  const appContent = t("AppContent", { returnObjects: true });
  const [groupname, setGroupname] = useState("");
  const [isTopicOpen, setisTopicOpen] = useState(false);
  const [isAddPeopledarwer, setAddpeopledarwer] = useState(false);
  const [isPricingDrawerOpen, setPricingDrawerOpen] = useState(false);
  const {
    currentUserData,
    selectedGroup,
    selectedTopic,
    isThreadScreen,
    groupList,
    groupRecipientDetails,
    accountLevelPeople,
  } = useAppSelectors();

  const isThreadScrollToBottom = useSelector(
    (state) => state?.appStateData?.isThreadScrollToBottom
  );
  const dispatch = useDispatch();
  const messageList = useSelector((state) => state?.arkchatgroup?.messageList);
  const tostmsgData = useSelector((state) => state?.global?.tostmsgData);
  const isShowInternetToast = useSelector(
    (state) => state?.global?.isShowInternetToast
  );
  const showToast = useSelector((state) => state?.global?.isToastOpen);
  const switchAccountRefresh = useSelector(
    (state) => state?.appStateData?.isSwitchAccountRefresh
  );
  const shouldCallPubnubGetToken = useSelector(
    (state) => state?.localAppReducer?.shouldCallPubnubGetToken
  );
  const toggleSetPubTokenState = useSelector(
    (state) => state?.appStateData?.toggleSetPubTokenState
  );
  const token = getCookie("token");
  const isRemember = getCookie("isRemember");
  const [isTokenRefreshed, setIsTokenRefreshed] = useState(
    token && isRemember ? false : true
  );
  const [taskTabValue, setTaskTabValue] = useState(0);
  const [approvalTabValue, setApprovalTabValue] = useState(0);
  const [taskApprovalViewValue, setTaskApprovalViewValue] = useState(1);
  const [selectedTaskGroup, setSelectedTaskGroup] = useState(null);
  const [selectedTaskmember, setSelectedTaskMember] = useState([]);
  const [pubnub, setPubnub] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    dispatch({
      type: HANDLE_TOAST_OPEN_CLOSE,
      payload: false,
    });
    dispatch({
      type: SHOW_TOAST_MESSAGE,
      payload: "",
    });
    dispatch({
      type: SHOW_INTERNET_TOAST,
      payload: false,
    });
  }, []);
  useEffect(() => {
    const handleOnlineStatusChange = () => {
      if (navigator.onLine) {
        // The browser is online
        if (isShowInternetToast) {
          dispatch({
            type: HANDLE_TOAST_OPEN_CLOSE,
            payload: true,
          });
          dispatch({
            type: SHOW_TOAST_MESSAGE,
            payload: appContent?.connectionRestored,
          });
          dispatch({
            type: SHOW_INTERNET_TOAST,
            payload: false,
          });
        }
      } else {
        // The browser is offline
        dispatch({
          type: SHOW_INTERNET_TOAST,
          payload: true,
        });
        dispatch({
          type: HANDLE_TOAST_OPEN_CLOSE,
          payload: true,
        });
        dispatch({
          type: SHOW_TOAST_MESSAGE,
          payload: appContent?.noInternet,
        });
      }
    };

    window.addEventListener("online", handleOnlineStatusChange);
    window.addEventListener("offline", handleOnlineStatusChange);
    handleOnlineStatusChange();
    return () => {
      window.removeEventListener("online", handleOnlineStatusChange);
      window.removeEventListener("offline", handleOnlineStatusChange);
    };
  }, [isShowInternetToast]);

  useEffect(() => {
    if (currentUserData) {
      setPubnub(
        new Pubnub({
          publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
          subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY,
          userId: `${currentUserData.id}`,
          restore: true,
          autoNetworkDetection: true,
        })
      );
    }
  }, [getCookie("user_Id")]);

  useEffect(() => {
    // Check if groupList is populated
    if (currentUserData && pubnub) {
      if (groupList?.length > 0 && shouldCallPubnubGetToken) {
        // const channelsToSubscribe = groupList.map((data) => data.pnId);
        const channelsToSubscribe = groupList.flatMap((data) => {
          if (data?.isGroup) {
            return [data.pnId, `${data?.pnId}.user_${currentUserData?.id}`];
          } else {
            return [data.pnId];
          }
        });
        channelsToSubscribe.push(
          `subscriber_${currentUserData?.activeSubscriber?.id}.signal`
        );
        dispatch(handlePubnubToken())
          .then((res) => {
            pubnub.unsubscribeAll();
            pubnub.setToken(res);
            // logWrapper(pubnub.parseToken(res));
            pubnub.subscribe({
              channels: channelsToSubscribe,
              withPresence: false,
            });
            dispatch({
              type: SHOULD_CALL_PUBNUB_GET_TOKEN,
              payload: false,
            });
          })
          .catch((error) => {});
      }
    }
  }, [groupList?.length, pubnub, shouldCallPubnubGetToken]);

  useEffect(() => {
    if (currentUserData && pubnub) {
      const messageListener = {
        message: function (messageEvent) {
          logWrapper(messageEvent, "messageEvent");
          if (messageEvent?.message?.type === "signal") {
            const loggedInUserActiveSubsId =
              currentUserData?.activeSubscriber?.id;
            const loggedInUserId = currentUserData?.id;
            const sidList = currentUserData?.subscribedTo;
            handlePubnubSignal({
              loggedInUserActiveSubsId,
              loggedInUserId,
              sidList,
              messageEvent,
              dispatch,
              requestallmemberswithgroups,
              setisTopicOpen,
              handleCloseAddPeopleDrawer,
              getAlltopic,
              requestgroupMemberDetails,
              requestGetGroupRecipient,
              requestUpdateFromSignalGroupRecipient,
              groupList,
              groupRecipientDetails,
              switchAccount,
              getProfile,
              switchAccountId: currentUserData?.subscribedTo[0]?.id,
              requestGetAllAcountLevelMember,
              setGroupname,
              navigate,
              currentUserData,
              saveSelectedAppGroupData,
              handleAppSelectedTopic,
              selectedGroup,
              handleFetchConversation,
              handleUpdateExistingMessage,
              messageList,
              selectedTopic,
              isThreadScreen,
              getAllPendingInvitation,
              handleScrollToBottom,
              handleSwitchAccountRefresh,
              switchAccountRefresh,
              handleSyncStarMsgData,
              handleDeleteMsgFromDb,
              toggleSetPubTokenState,
              handleTogglePunbunTokenUpdateState,
            });
          } else {
            let newMessageEvent = handleDecryptSingleMessageData(
              messageEvent.message.content.message
            );
            // logWrapper(newMessageEvent, "decrypted");
            let message;
            if (messageEvent.message.content?.type === "conversation-message") {
              message = [
                {
                  ...newMessageEvent,
                  publisher: messageEvent?.publisher,
                  timetoken: +messageEvent?.timetoken,
                },
              ];
              if (
                messageEvent.message.content.message.participants?.includes(
                  currentUserData.id
                )
              ) {
                if (
                  !isThreadScreen ||
                  !messageEvent.message.content.message?.thread_id
                ) {
                  dispatch(handleThreadScrollToBottom(null));
                }
                dispatch(handleScrollToBottom(true));

                dispatch(
                  handleUpdateGroupSorting(
                    messageEvent.message.content.message?.pnId,
                    +messageEvent?.timetoken,
                    selectedGroup?.pnId
                  )
                );
                const readAllmsgBody = {
                  ref_id: messageEvent.message.content.message?.pnId,
                };
                dispatch(
                  handleInstantPubnubPublishMessage(
                    message,
                    selectedGroup?.pnId,
                    selectedTopic?.topic_id,
                    messageEvent.message.content.message?.temp_timetoken
                  )
                );

                //this is for when we update taks/approval it's reply come as a conversation-msg
                if (
                  messageEvent.message.content.message?.thread_id &&
                  isThreadScreen
                ) {
                  dispatch(
                    handleInstantPubnubPublishThreadMessage(
                      message,
                      selectedGroup?.pnId,
                      selectedTopic?.topic_id,
                      messageEvent.message.content.message?.temp_timetoken,
                      false,
                      isThreadScreen,
                      isThreadScrollToBottom
                    )
                  );
                }

                if (
                  selectedGroup?.pnId ===
                  messageEvent.message.content.message?.pnId
                ) {
                  setTimeout(() => {
                    dispatch(handleReadAllMsg(readAllmsgBody));
                  }, 500);
                }
              }
            } else if (
              messageEvent.message.content?.type === "direct-message"
            ) {
              message = [
                {
                  ...newMessageEvent,
                  publisher: messageEvent?.publisher,
                  timetoken: +messageEvent?.timetoken,
                },
              ];
              if (
                messageEvent.message.content.message.participants?.includes(
                  currentUserData.id
                )
              ) {
                const readAllmsgBody = {
                  ref_id: messageEvent.message.content.message?.pnId,
                };
                dispatch(handleScrollToBottom(true));
                dispatch(
                  handleUpdateGroupSorting(
                    messageEvent.message.content.message?.pnId,
                    +messageEvent?.timetoken,
                    selectedGroup?.pnId
                  )
                );
                dispatch(
                  handleInstantPubnubPublishMessage(
                    message,
                    selectedGroup?.pnId,
                    selectedTopic?.topic_id,
                    messageEvent.message.content.message?.temp_timetoken
                  )
                );

                if (
                  selectedGroup?.pnId ===
                  messageEvent.message.content.message?.pnId
                ) {
                  setTimeout(() => {
                    dispatch(handleReadAllMsg(readAllmsgBody));
                  }, 500);
                }
              }
            } else if (
              messageEvent.message.content?.type === "message-thread"
            ) {
              message = [
                {
                  ...newMessageEvent,
                  publisher: messageEvent?.publisher,
                  timetoken: +messageEvent?.timetoken,
                },
              ];
              if (
                messageEvent.message.content.message.participants?.includes(
                  currentUserData.id
                )
              ) {
                const readAllmsgBody = {
                  ref_id: messageEvent.message.content.message?.pnId,
                };
                dispatch(
                  handleUpdateGroupSorting(
                    messageEvent.message.content.message?.pnId,
                    +messageEvent?.timetoken,
                    selectedGroup?.pnId
                  )
                );
                if (
                  isThreadScreen &&
                  isThreadScrollToBottom === message[0]?.thread_id
                ) {
                  dispatch(handleThreadScrollToBottom(message[0]?.thread_id));
                }
                if (isThreadScrollToBottom !== message[0]?.thread_id) {
                  dispatch(handleThreadScrollToBottom(null));
                }
                dispatch(
                  handleInstantPubnubPublishThreadMessage(
                    message,
                    selectedGroup?.pnId,
                    selectedTopic?.topic_id,
                    messageEvent.message.content.message?.temp_timetoken,
                    true,
                    isThreadScreen,
                    isThreadScrollToBottom
                  )
                );
                if (
                  selectedGroup?.pnId ===
                  messageEvent.message.content.message?.pnId
                ) {
                  setTimeout(() => {
                    dispatch(handleReadAllMsg(readAllmsgBody));
                  }, 500);
                }
              }
            } else if (
              messageEvent.message.content?.type === "conversation-update"
            ) {
              if (
                messageEvent.message.content.message.participants?.includes(
                  currentUserData.id
                )
              ) {
                message = [
                  {
                    ...newMessageEvent,
                  },
                ];
                if (isThreadScreen) {
                  dispatch({
                    type: UPDATE_EXISTING_THREAD_MESSAGE,
                    payload: message[0],
                  });
                }
                dispatch({
                  type: UPDATE_EXISTING_MESSAGE_LIST,
                  payload: message[0],
                });
                if (message[0]?.type === MessageType?.Task) {
                  dispatch({
                    type: UPDATE_EXISTING_TASKLIST_DATA,
                    payload: message[0],
                  });
                }
                if (message[0]?.type === MessageType?.Approval) {
                  dispatch({
                    type: UPDATE_EXISTING_APPROVALLIST_DATA,
                    payload: message[0],
                  });
                }
                dispatch(handleUpdateMsgToDb(message[0]));
              }
            }
          }
        },
        signal: function (signalData) {},
      };

      pubnub.addListener(messageListener);

      return () => {
        pubnub.removeListener(messageListener);
      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedGroup,
    messageList,
    isThreadScreen,
    isThreadScrollToBottom,
    toggleSetPubTokenState,
    groupRecipientDetails,
  ]);

  const handleCloseAddPeopleDrawer = () => {
    setAddpeopledarwer(false);
  };

  useEffect(() => {
    localStorage.removeItem("isChangeEmail");
  }, []);

  useEffect(() => {
    if (token && isRemember) {
      function isTokenExpired(token) {
        const currentTimestamp = Math.floor(Date.now() / 1000);
        const oneDayInSeconds = 24 * 60 * 60;

        if (token && token.exp) {
          return token.exp - currentTimestamp <= oneDayInSeconds;
        }

        return true;
      }
      try {
        const token = jwtDecode(getCookie("token"));

        const isExpired = isTokenExpired(token);

        if (isExpired) {
          refreshToken();
        } else {
          setIsTokenRefreshed(true);
        }
      } catch (error) {}
    }
  }, []);

  useEffect(() => {
    let timerId;
    if (showToast) {
      timerId = setTimeout(() => {
        dispatch({
          type: HANDLE_TOAST_OPEN_CLOSE,
          payload: false,
        });
      }, 6000);
    }
    return () => {
      clearInterval(timerId);
    };
  }, [tostmsgData]);

  function refreshToken() {
    dispatch(getRefreshToken())
      .then((res) => {
        if (res?.status) {
          setIsTokenRefreshed(true);
        } else {
          setIsTokenRefreshed(true);
        }
      })
      .catch((err) => {
        dispatch(handleLogout());
      });
  }

  const showNotification = (body) => {
    dispatch(getAllNotification());

    const notificationOptions = {
      ...body?.notification,
      icon: "/arkchat.jpg",
    };

    const notification = new Notification("Arkchat", notificationOptions);
    notification.onclick = (event) => {
      event.preventDefault();
      window.open("https://platform.arkchat.com/", "_blank");
    };
  };
  onMessageListener(showNotification);

  return {
    isAddPeopledarwer,
    setAddpeopledarwer,
    setisTopicOpen,
    isTopicOpen,
    isTokenRefreshed,
    setIsTokenRefreshed,
    setGroupname,
    setPricingDrawerOpen,
    isPricingDrawerOpen,
    showToast,
    taskApprovalViewValue,
    setTaskApprovalViewValue,
    setTaskTabValue,
    taskTabValue,
    approvalTabValue,
    setApprovalTabValue,
    selectedTaskGroup,
    setSelectedTaskGroup,
    selectedTaskmember,
    setSelectedTaskMember,
    groupname,
    pubnub,
    dispatch,
    currentUserData,
    groupList,
    accountLevelPeople,
    tostmsgData,
    handleCloseAddPeopleDrawer,
  };
};

export default useAppState;
