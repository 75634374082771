import React, { useEffect, useRef, useState } from "react";
import { Box, Typography, Stack, Link, Collapse, Drawer } from "@mui/material";
import { classes } from "./MessageList";
import three_dots from "../../../assets/images/chats/three_dots.svg";
import replaym from "../../../assets/images/chats/reply.svg";
import tick_gray from "../../../assets/images/chats/gray_light.svg";
import tick_green from "../../../assets/images/chats/double-tick_green.svg";
import moment from "moment";
import { getFileIconUrl } from "../../../utils/findextension";
import DmMoreMenu from "./DmMoreMenu";
import RecipientList from "./RecipientList";
import InputDialogComponent from "../../../components/InputDialog";
import { AccountLevelArkchatPermission } from "../../../utils/constant";
import HandleLink from "./HandleLink";

const DirectMessageView = ({
  item,
  threeDotRef,
  userData,
  handleSendTempMsg,
  setReplyParentMsg,
  bgcolor,
  scrollToRepliedMessage,
  isDMMoreMenuOpen,
  setDMMoreMenuOpen,
  isDeleteGroupMsgOpenIndicatorId,
  isDeleteGroupMsgOpen,
  setDeleteGrpMsgType,
  setDeleteGroupMsgOpen,
  setDeleteGroupMsgOpenIndicatorId,
  handleDeleteGrpMsg,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [isRecipientListOpen, setRecipientListOpen] = useState(false);
  const [recipientList, setRecipientList] = useState([]);
  const tempMenuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (tempMenuRef.current && !tempMenuRef.current.contains(event.target)) {
      setDMMoreMenuOpen(null);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const toggleItemMenu = (item) => {
    if (isDMMoreMenuOpen?.temp_timetoken === item?.temp_timetoken) {
      setDMMoreMenuOpen(null);
    } else {
      setDMMoreMenuOpen(item);
    }
  };
  const handleSeeRecipientList = (recipientData) => {
    setRecipientList(recipientData);
    setRecipientListOpen(true);
  };
  return (
    <>
      <Drawer
        sx={{
          zIndex: 12011,
        }}
        hideBackdrop={true}
        anchor={"right"}
        open={isRecipientListOpen}
      >
        <RecipientList
          readby={item?.read_by}
          recipientList={recipientList}
          setRecipientListOpen={setRecipientListOpen}
        />
      </Drawer>
      {item?.type === 3 && (
        <>
          <Stack
            direction="row"
            spacing={2}
            width={"100%"}
            padding={"0.8rem 0rem"}
            justifyContent={"space-between"}
            key={item?.temp_timetoken}
            bgcolor={bgcolor}
            id={`replied-message-${item?.temp_timetoken}`}
          >
            <div style={{ width: "100%" }}>
              <Stack width={"100%"} direction="row" spacing={1}>
                {/* ------------profile section--------------- */}
                {item?.from?.pp ? (
                  <Box sx={classes.chatUserprofile}>
                    <img src={item?.from?.pp} alt="profile" height="100%" />
                  </Box>
                ) : (
                  <Box
                    bgcolor={"#E3E1E1"}
                    width={"30px"}
                    height={"30px"}
                    display={"flex"}
                    flex={"none"}
                    alignItems={"center"}
                    flex-wrap={"wrap"}
                    justifyContent={"center"}
                    borderRadius={"0.15rem"}
                  >
                    <Typography variant="h6" fontSize={"0.75rem"}>
                      {item?.from?.name && item?.from?.name[0]}
                    </Typography>
                  </Box>
                )}

                {/* ------------Details section--------------- */}
                <Box width={"88%"}>
                  <Stack direction="row" spacing={1}>
                    <Typography variant="subtitle2" fontWeight={"450"}>
                      {item?.from?.name}
                    </Typography>
                    <Typography sx={classes.duetime}>
                      {/* Time */}
                      {moment(Math.floor(item?.timetoken / 10000)).format(
                        userData?.profileData?.data?.timeFormat === "24"
                          ? userData?.profileData?.data?.userDateTimeFormat
                          : userData?.profileData?.data?.userDateTimeFormat
                      )}
                    </Typography>

                    <Box
                      sx={{ ...classes.tick, cursor: "pointer" }}
                      onClick={() => handleSeeRecipientList(item?.to)}
                    >
                      <img
                        src={
                          item?.to?.length === item?.read_by?.length
                            ? tick_green
                            : tick_gray
                        }
                        alt="Chat Status"
                      />
                    </Box>
                  </Stack>

                  {/* -------------reply---------- */}
                  {item?.thread_original_message &&
                    item?.thread_original_message?.translations && (
                      <Stack
                        width={"100%"}
                        direction="row"
                        spacing={1}
                        sx={{ ...classes.chatRep, cursor: "pointer" }}
                        onClick={() =>
                          scrollToRepliedMessage(
                            item?.thread_original_message?.temp_timetoken
                          )
                        }
                      >
                        <img src={replaym} alt="reply" />
                        {item?.thread_original_message?.translations[
                          item?.to?.find(
                            (data) =>
                              data?.id === userData?.profileData?.data?.id
                          )?.languageCode
                        ] ? (
                          <Typography
                            variant="body2"
                            sx={{ wordWrap: "break-word" }}
                          >
                            {item?.to?.findIndex(
                              (idData) =>
                                idData?.id === userData?.profileData?.data?.id
                            ) !== -1 &&
                              item?.thread_original_message?.translations[
                                item?.to?.find(
                                  (data) =>
                                    data?.id === userData?.profileData?.data?.id
                                )?.languageCode
                              ]?.slice(0, 200) + " ..."}
                          </Typography>
                        ) : (
                          <Typography
                            variant="body2"
                            sx={{ wordWrap: "break-word" }}
                          >
                            {item?.to?.findIndex(
                              (idData) =>
                                idData?.id === userData?.profileData?.data?.id
                            ) !== -1 &&
                              item?.thread_original_message?.message?.slice(
                                0,
                                200
                              ) + " ..."}
                          </Typography>
                        )}
                      </Stack>
                    )}

                  {/* text message */}
                  <Box width={"100%"}>
                    <HandleLink>
                      {item?.translations &&
                      item?.translations[
                        item?.to?.find(
                          (data) => data?.id === userData?.profileData?.data?.id
                        )?.languageCode
                      ] ? (
                        <Typography
                          sx={{ wordWrap: "break-word" }}
                          width={"100%"}
                          variant="body2"
                        >
                          {!expanded &&
                            item?.to?.findIndex(
                              (idData) =>
                                idData?.id === userData?.profileData?.data?.id
                            ) !== -1 &&
                            item?.translations[
                              item?.to?.find(
                                (data) =>
                                  data?.id === userData?.profileData?.data?.id
                              )?.languageCode
                            ]
                              ?.slice(0, 200)
                              ?.split(/\n/)
                              ?.map((line, indx) => (
                                <span
                                  key={line + indx + "xyz007"}
                                  style={{
                                    marginBottom: "0.5rem",
                                    display: "block",
                                    border:
                                      line === ""
                                        ? "1px solid transparent"
                                        : "none",
                                  }}
                                >
                                  <>{line === "" ? " " : line}</>
                                </span>
                              ))}{" "}
                          {!expanded &&
                          item?.translations[
                            item?.to?.find(
                              (data) =>
                                data?.id === userData?.profileData?.data?.id
                            )?.languageCode
                          ]?.length > 200
                            ? " ..."
                            : ""}
                          <Collapse
                            sx={{ wordWrap: "break-word" }}
                            in={expanded}
                            timeout="auto"
                            unmountOnExit
                          >
                            {item?.to?.findIndex(
                              (idData) =>
                                idData?.id === userData?.profileData?.data?.id
                            ) !== -1 &&
                              item?.translations[
                                item?.to?.find(
                                  (data) =>
                                    data?.id === userData?.profileData?.data?.id
                                )?.languageCode
                              ]
                                ?.split(/\n/)
                                ?.map((line, indx) => (
                                  <span
                                    key={line + indx + "xyz008"}
                                    style={{
                                      marginBottom: "0.5rem",
                                      display: "block",
                                      border:
                                        line === ""
                                          ? "1px solid transparent"
                                          : "none",
                                    }}
                                  >
                                    <>{line === "" ? " " : line}</>
                                  </span>
                                ))}
                          </Collapse>
                          {item?.translations[
                            item?.to?.find(
                              (data) =>
                                data?.id === userData?.profileData?.data?.id
                            )?.languageCode
                          ]?.length > 200 && (
                            <Typography
                              width={"100%"}
                              variant="body2"
                              expand={expanded}
                              onClick={() => setExpanded(!expanded)}
                              aria-expanded={expanded}
                              aria-label="show more"
                              sx={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              display={"inline"}
                              color={"#2C86FF"}
                            >
                              {expanded ? "less" : "more"}
                            </Typography>
                          )}
                        </Typography>
                      ) : (
                        <Typography
                          sx={{ wordWrap: "break-word" }}
                          width={"100%"}
                          variant="body2"
                        >
                          {!expanded &&
                            item?.to?.findIndex(
                              (idData) =>
                                idData?.id === userData?.profileData?.data?.id
                            ) !== 1 &&
                            item?.message?.slice(0, 200) +
                              (item?.message?.length > 200 ? " ..." : "")}
                          <Collapse in={expanded} timeout="auto" unmountOnExit>
                            {item?.to?.findIndex(
                              (idData) =>
                                idData?.id === userData?.profileData?.data?.id
                            ) !== 1 &&
                              item?.message?.split(/\n/).map((line, indx) => (
                                <span
                                  key={line + indx + "xyz009"}
                                  style={{
                                    marginBottom: "0.5rem",
                                    display: "block",
                                    border:
                                      line === ""
                                        ? "1px solid transparent"
                                        : "none",
                                  }}
                                >
                                  {line}
                                </span>
                              ))}
                          </Collapse>

                          {item?.message?.length > 200 && (
                            <Typography
                              width={"100%"}
                              variant="body2"
                              expand={expanded}
                              onClick={() => setExpanded(!expanded)}
                              aria-expanded={expanded}
                              aria-label="show more"
                              sx={{
                                textDecoration: "underline",
                                cursor: "pointer",
                              }}
                              display={"inline"}
                              color={"#2C86FF"}
                            >
                              {expanded ? "less" : "more"}
                            </Typography>
                          )}
                        </Typography>
                      )}
                    </HandleLink>
                  </Box>

                  {/* -----------------Attachments------------------ */}
                  <>
                    {item?.attachments?.length > 0 && (
                      <Box mt={"0.5rem"}>
                        {item?.attachments.map((fileData) => {
                          const fileIconOrType = getFileIconUrl(
                            fileData?.location
                          );
                          const isAudio = fileIconOrType === "audio";
                          return (
                            <Box key={fileData?.location} mt={"0.5rem"}>
                              {isAudio ? (
                                <audio
                                  controls
                                  controlsList="nodownload"
                                  src={fileData?.location}
                                ></audio>
                              ) : (
                                <Link
                                  href={fileData?.location}
                                  underline="none"
                                  target="_blank"
                                  rel="noopener"
                                  color={"black"}
                                >
                                  <Box
                                    display={"flex"}
                                    alignItems={"center"}
                                    gap={"0.2rem"}
                                  >
                                    <img
                                      style={{
                                        height: "25px",
                                        width: "25px",
                                      }}
                                      src={fileIconOrType}
                                      alt={fileData?.name}
                                    />
                                    <Typography
                                      variant="subtitle2"
                                      fontWeight={"400"}
                                      fontSize={"0.75rem"}
                                    >
                                      {fileData?.name}
                                    </Typography>
                                  </Box>
                                </Link>
                              )}
                            </Box>
                          );
                        })}
                      </Box>
                    )}
                  </>
                </Box>
                {/* ------------Right section--------------- */}
                <Box>
                  <Stack spacing={2} direction="row">
                    <Box position={"relative"}>
                      <Box
                        ref={threeDotRef}
                        onClick={() => toggleItemMenu(item)}
                        sx={{
                          cursor: "pointer",
                          pointerEvents: isDMMoreMenuOpen ? "none" : "",
                        }}
                      >
                        <img src={three_dots} alt="more" />
                      </Box>
                      {isDMMoreMenuOpen?.temp_timetoken ===
                        item?.temp_timetoken && (
                        <DmMoreMenu
                          setDeleteGroupMsgOpen={setDeleteGroupMsgOpen}
                          setDeleteGroupMsgOpenIndicatorId={
                            setDeleteGroupMsgOpenIndicatorId
                          }
                          setReplyParentMsg={setReplyParentMsg}
                          handleSendTempMsg={handleSendTempMsg}
                          menuRef={tempMenuRef}
                          messageData={item}
                          setMoreMenu={setDMMoreMenuOpen}
                        />
                      )}

                      <>
                        {isDeleteGroupMsgOpen &&
                          isDeleteGroupMsgOpenIndicatorId ===
                            item?.timetoken && (
                            <>
                              <InputDialogComponent
                                title="Delete"
                                isType="deletegroupmessage"
                                open={isDeleteGroupMsgOpen}
                                handleClose={() => {
                                  setDeleteGroupMsgOpen(false);
                                  setDeleteGroupMsgOpenIndicatorId(null);
                                }}
                                btnLabel="Delete"
                                setDeleteGrpMsgType={setDeleteGrpMsgType}
                                onClick={() => handleDeleteGrpMsg(item)}
                                isallowedDeleteGrpMsgForall={
                                  (userData?.profileData?.data?.id ===
                                    item?.from?.id ||
                                    userData?.profileData?.data
                                      ?.activePermission ===
                                      AccountLevelArkchatPermission?.ADMIN) &&
                                  moment().diff(
                                    Math.floor(item?.timetoken / 10000),
                                    "minutes"
                                  ) <= 30
                                    ? false
                                    : true
                                }
                              />
                            </>
                          )}
                      </>
                    </Box>
                  </Stack>
                </Box>
                {/* ------------------------------------------ */}
              </Stack>
            </div>
          </Stack>
          <Box width={"85%"} margin={"auto"} border={"1px solid #ECECEC"}></Box>
        </>
      )}
    </>
  );
};

export default DirectMessageView;
